import {call, put} from "redux-saga/effects";
import {creerAndSendBookingApi} from "../../../common/services/api/creerAndSendBookingApi";
import {creerBookingApi, updateAndSendBooking, updateBooking,} from "../../../common/services/api/creerBookingApi";
import {getArmateurApi} from "../../../common/services/api/getArmateurApi";
import {getClassImoApi} from "../../../common/services/api/getClassImoApi";
import {getCodeIsoApi} from "../../../common/services/api/getCodeIsoApi";
import {getNumConteneurApi} from "../../../common/services/api/getNumConteneurApi";
import {getOperateurNameApi} from "../../../common/services/api/getOperateurNameApi";
import * as actions from "../state/creationBookingAction";
import {getArrivalNoticeSuccess, getBookingSuccess} from "../state/creationBookingAction";
import {cancelBooking, getBooking, sendBooking,} from "../../../common/services/api/bookingApi";
import {getArrivalNotice} from "../../../common/services/api/arrivalNoticeApi";

/*************************************Saga Detail Booking********************************/
export function* getImoClassSaga(action) {
  try {
    const response = yield call(getCodeIsoApi, action.payload);
    yield put(actions.getCodeIsoSuccess(response.data));
  } catch (error) {
    yield put(actions.getCodeIsoFailed(error?.response?.data?.detail));
  }
}

/*************************************Saga Detail Booking********************************/
export function* getNumContainerSaga(action) {
  try {
    const response = yield call(getNumConteneurApi, action.payload);
    yield put(actions.getNumContainerSuccess(response.data));
  } catch (error) {
    yield put(actions.getNumContainerFailed(error?.response?.data?.detail));
  }
}

/*************************************Saga Detail Booking********************************/
export function* getClassImoRealSaga(action) {
  try {
    const response = yield call(getClassImoApi, action.payload);
    yield put(actions.getClassImoSuccess(response.data));
  } catch (error) {
    yield put(actions.getClassImoFailed(error?.response?.data?.detail));
  }
}

/*************************************Saga Detail Booking********************************/

/*************************************Saga Detail Booking********************************/
export function* getOperateurNameSaga(action) {
  try {
    const response = yield call(getOperateurNameApi, action.payload);
    yield put(actions.getOperteurNameSuccess(response.data));
  } catch (error) {
    yield put(actions.getOperteurNameFailed(error?.response?.data?.detail));
  }
}

/*************************************Saga Detail Booking********************************/
export function* getArmateurSaga(action) {
  try {
    const response = yield call(getArmateurApi, action.payload);
    yield put(actions.getArmateurSearchSuccess(response.data));
  } catch (error) {
    yield put(actions.getArmateurSearchFailed(error?.response?.data?.detail));
  }
}

/*************************************Saga Creer Booking********************************/
export function* getCreerBookingSaga({ request }) {
  try {
    debugger;
    const response = yield call(creerBookingApi, request.payload);
    request.successCallBack(response?.data);
    yield put(actions.creerBookingSuccess(response.data));
  } catch ({ response }) {
    request.failCallBack(response?.data?.message);
    yield put(actions.creerBookingFailed(response?.data));
  }
}

/************************************* Update Booking ********************************/
export function* updateBookingSaga({ request }) {
  try {
    const response = yield call(updateBooking, request.payload);
    request.successCallBack(response?.data);
  } catch ({ response }) {
    request.failCallBack(response?.data?.message);
  }
}

/*************************************Saga Creer And Send Booking********************************/
export function* getCreerAndSendBookingSaga({ request }) {
  try {
    const response = yield call(creerAndSendBookingApi, request.payload);
    request.successCallBack(response?.data);
    yield put(actions.creerAndSendBookingSuccess(response.data));
  } catch ({ response }) {
    request.failCallBack(response?.data?.message);
    yield put(actions.creerAndSendBookingFailed(response?.data));
  }
}

/*************************************Get Booking********************************/
export function* getBookingSaga({ request }) {
  //debugger
  const id = request?.id;
  try {

    const response = yield call(getBooking, id);
    yield put(getBookingSuccess(response?.data));
    request.successCallBack();
  } catch (e) {
    request.failCallBack();
    throw e;
  }
}

/*************************************Get Arrival Notice********************************/
export function* getArrivalNoticeSaga({ request }) {
  const id = request?.id;
  try {
    const response = yield call(getArrivalNotice, id);
    yield put(getArrivalNoticeSuccess(response?.data));
    request?.successCallBack();
  } catch (e) {
    request?.failCallBack();
    throw e;
  }
}

/************************************* Cancel Booking ********************************/
export function* cancelBookingSaga({ request }) {
  const id = request.id;
  try {
    const response = yield call(cancelBooking, id);
    request.successCallBack(response?.data?.message);
  } catch ({ response }) {
    request.failCallBack(response?.data?.message);
  }
}

/************************************* Cancel Booking ********************************/
export function* sendBookingSaga({ request }) {
  const id = request.id;
  try {
      debugger;
    const response = yield call(sendBooking, id);
    request.successCallBack(response?.data?.message);
  } catch ({ response }) {
    request.failCallBack(response?.data?.message);
  }
}


/************************************* Update and send Booking ********************************/
export function* updateAndSendBookingSaga({ request }) {
  try {
    const response = yield call(updateAndSendBooking, request.payload);
    request.successCallBack(response?.data);
  } catch ({ response }) {
    request.failCallBack(response?.data?.message);
  }
}
