import { put, call } from "redux-saga/effects";
import {getDetailBookingApi} from "../../../common/services/api/getDetailBookingApi";
import * as actions from "../state/detailBookingAction";



/*************************************Saga Detail Booking********************************/
export function* getDetailBookingSaga(action) {
  try {
    const response = yield call(getDetailBookingApi,action.payload);
    yield put(actions.getDetailBookingSuccess(response.data));
  } catch (error) {
    yield put(actions.getDetailBookingFailed(error?.response?.data?.detail));
  }
}


  