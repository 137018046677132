import { takeLatest } from "redux-saga/effects";
import * as ActionsTypes from "../../../common/state/StateConstants";
import {addEntiteAcceuilSaga,addMandataireSaga,getDetailBookingSaga} from "../saga/detailBookingSaga"

export default function* EntiteAcceuilWatcher() {
    /************************************Get****************************** */
    yield takeLatest(ActionsTypes.GET_DETAILBOOKING,getDetailBookingSaga)
    
  
  
  
    /************************************Delete**************************** */
    
    /************************************Update****************************** */
    
  
  
  }
  