/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { List, ListItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styles from "../../../assets/jss/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <List className={classes.center}>
          <a
            href="https://portnet.ma"
            className={classes.block}
            target="_blank"
          >
            &copy; {1900 + new Date().getYear()} PORTNET S.A. &nbsp;&nbsp;Tous droits
            réservés.
          </a>
        </List>
      </div>
    </footer>
  );
}
Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
