import * as ActionsTypes from '../../../common/state/StateConstants';

/********************************Get (DropDown Port Default) */

export const getPort = (payload) => {
  return {
    type: ActionsTypes.GET_PORT,
    payload,
  };
};

export const getPortSuccess = (payload) => {
  return {
    type: ActionsTypes.GET_PORT_SUCCESS,
    payload,
  };
};

export const getPortLFail = (payload) => {
  return {
    type: ActionsTypes.GET_PORT_FAIL,
    payload,
  };
};

/********************************Get (Search Port Default) */

export const getPortSearch = (payload) => {
  return {
    type: ActionsTypes.GET_PORTSEARCH,
    payload,
  };
};

export const getPortSearchSuccess = (payload) => {
  return {
    type: ActionsTypes.GET_PORTSEARCH_SUCCESS,
    payload,
  };
};

export const getPortSearchFail = (payload) => {
  return {
    type: ActionsTypes.GET_PORTSEARCH_FAIL,
    payload,
  };
};

/********************************Get (Search Operateur Default) */

export const getOperateur = (payload) => {
  return {
    type: ActionsTypes.GET_OPERATEURSEARCH,
    payload,
  };
};

export const getOperateurSuccess = (response) => {
  return {
    type: ActionsTypes.GET_OPERATEURSEARCH_SUCCESS,
    response: response,
  };
};

export const getOperateurFail = (payload) => {
  return {
    type: ActionsTypes.GET_OPERATEURSEARCH_FAIL,
    payload,
  };
};

/********************************Get (Search Booking Default) */

export const getBooking = (payload) => {
  return {
    type: ActionsTypes.GET_BOOKINGDEFAULT,
    payload,
  };
};

export const getBookingSuccess = (payload) => {
  return {
    type: ActionsTypes.GET_BOOKINGDEFAULT_SUCCESS,
    payload,
  };
};

export const getBookingFail = (payload) => {
  return {
    type: ActionsTypes.GET_BOOKINGDEFAULT_FAIL,
    payload,
  };
};

/********************************Get (Search Booking Default) */

export const getPremierPort = (payload) => {
  return {
    type: ActionsTypes.GET_PREMIERPORT,
    payload,
  };
};

export const getPremierPortSuccess = (payload) => {
  return {
    type: ActionsTypes.GET_PREMIERPORT_SUCCESS,
    payload,
  };
};

export const getPremierPortFail = (payload) => {
  return {
    type: ActionsTypes.GET_PREMIERPORT_FAIL,
    payload,
  };
};

/********************************Get (Search Navire Default) */

export const getNavireDefault = (payload) => {
  return {
    type: ActionsTypes.GET_NAVIREDEFAULT,
    payload,
  };
};

export const getNavireDefaultSuccess = (payload) => {
  return {
    type: ActionsTypes.GET_NAVIREDEFAULT_SUCCESS,
    payload,
  };
};

export const getNavireDefaultFail = (payload) => {
  return {
    type: ActionsTypes.GET_NAVIREDEFAULT_FAIL,
    payload,
  };
};

/********************************Get (Search Navire) */

export const getNavireSearch = (payload) => {
  return {
    type: ActionsTypes.GET_NAVIRESEARCH,
    payload,
  };
};

export const getNavireSearchSuccess = (response) => {
  return {
    type: ActionsTypes.GET_NAVIRESEARCH_SUCCESS,
    response: response,
  };
};

export const getNavireSearchFail = (payload) => {
  return {
    type: ActionsTypes.GET_NAVIRESEARCH_FAIL,
    payload,
  };
};

/********************************Get (Search TransitaireDefault) */

export const getTransitaireDefault = (payload) => {
  return {
    type: ActionsTypes.GET_TRANSITAIREDEFAULT,
    payload,
  };
};

export const getTransitaireDefaultSuccess = (payload) => {
  return {
    type: ActionsTypes.GET_TRANSITAIREDEFAULT_SUCCESS,
    payload,
  };
};

export const getTransitaireDefaultFail = (payload) => {
  return {
    type: ActionsTypes.GET_TRANSITAIREDEFAULT_FAIL,
    payload,
  };
};

/********************************Get (Search TransitaireSearch) */

export const getTransitaireSearch = (payload) => {
  return {
    type: ActionsTypes.GET_TRANSITAIRESEARCH,
    payload,
  };
};

export const getTransitaireSearchSuccess = (payload) => {
  return {
    type: ActionsTypes.GET_TRANSITAIRESEARCH_SUCCESS,
    payload,
  };
};

export const getTransitaireSearchFail = (payload) => {
  return {
    type: ActionsTypes.GET_TRANSITAIRESEARCH_FAIL,
    payload,
  };
};

/********************************Get (Search ClientSearch) */

export const getClientSearch = (payload) => {
  return {
    type: ActionsTypes.GET_CLIENTESEARCH,
    payload,
  };
};

export const getClientSearchSuccess = (payload) => {
  return {
    type: ActionsTypes.GET_CLIENTESEARCH_SUCCESS,
    payload,
  };
};

export const getClientSearchFail = (payload) => {
  return {
    type: ActionsTypes.GET_CLIENTESEARCH_FAIL,
    payload,
  };
};

/********************************Get (Search BookingSearch) */

export const getBookingSearch = (payload) => {
  return {
    type: ActionsTypes.GET_BOOKINGSEARCH,
    payload,
  };
};

export const getBookingSearchSuccess = (payload) => {
  return {
    type: ActionsTypes.GET_BOOKINGSEARCH_SUCCESS,
    payload,
  };
};

export const getBookingSearchFail = (payload) => {
  return {
    type: ActionsTypes.GET_BOOKINGSEARCH_FAIL,
    payload,
  };
};

/********************************Get (Search HistoriqueSearch) */

export const getHistoriqueSearch = (payload) => {
  return {
    type: ActionsTypes.GET_HISTORIQUESEARCH,
    payload,
  };
};

export const getHistoriqueSearchSuccess = (payload) => {
  return {
    type: ActionsTypes.GET_HISTORIQUESEARCH_SUCCESS,
    payload,
  };
};

export const getHistoriqueSearchFail = (payload) => {
  return {
    type: ActionsTypes.GET_HISTORIQUESEARCH_FAIL,
    payload,
  };
};
