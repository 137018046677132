import  instance from "../../AxiosInterceptor";
import config from "../../config";

export const getTransitaireDefaultApi = (data) => {
    return instance
      .get(config.url+"/ms-bookingApi/freightForwarder/searchByIdNot?id="+data.id+"&page=0&size=5")
      .then((response) => {
        return response;
      })
      .then((err) => {
        return err;
      });
  };
  