import * as ActionsTypes from "../StatesConstants";


/*--------------------------------------------------TOKEN----------------------------------------------------*/
export const setToken = (payload) => {
  return {
    type: ActionsTypes.GENERAL_SET_TOKEN,
    payload,
  };
};
/*--------------------------------------------------AUTHORITIES----------------------------------------------------*/
export const setAuthorities = (payload) => {
  return {
    type: ActionsTypes.GENERAL_SET_AUTHORITIES,
    payload,
  };
};