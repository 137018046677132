import React, {useEffect, useMemo, useRef, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {v4 as uuid} from "uuid";
import {bookingInitialValues} from "../data/InitialValues";
import {bookingValidationSchema} from "../data/ValidationSchema";
import Conteneurs from "./Conteneurs";
import {
  cancelBooking,
  creerAndSendBooking,
  creerBooking,
  getArrivalNotice,
  getBooking,
  updateAndSendBooking,
  updateBooking,
} from "../state/creationBookingAction.js";
import CustomSnackbar from "../../../common/components/CustomSnackbar/CustomSnackbar";
import EtatBookingConstants from "../../../common/constants/etatBookingConstants";
import {
  PuiButton,
  PuiChip,
  PuiDateTimeField,
  PuiDialog,
  PuiFormikForm,
  PuiGrid,
  PuiIndication,
  PuiLoadingBackdrop,
  PuiMainContainer,
  PuiMainTitle,
  PuiNavigation,
  PuiRadioGroup,
  PuiRadioItem,
  PuiSection,
  PuiSpecificReferentielField,
  PuiTextField,
} from "@portnet/ui";
import {EscaleSearchPopUp} from "../components/EscaleSearchPopUp";
import {getChipColorByEtatCode, getEtatDescriptionByCode,} from "../../gestionBooking/ui/colors.js";
import Primary from "../../../common/components/Typography/Primary.js";
import {Stack} from "@mui/material";

const modes = {
  create: "create",
  update: "update",
  createAndSend: "createAndSend",
  updateAndSend: "updateAndSend",
};
export default function CreationBooking(props) {

  const params = useParams();
  const bookingId = useMemo(() => params.bookingId, [params.bookingId]);
  console.log({params : params});
  const toUpdateBooking = useSelector(
    (state) => state?.creationBooking?.toUpdateBooking
  );
  const isCreationSuccess = useSelector(
    (state) => state?.creationBooking?.isCreationSuccess
  );
  const arrivalNotice = useSelector(
    (state) => state?.creationBooking?.arrivalNotice
  );

  const [mode, setMode] = useState(modes.create);
  const [conteneurs, setConteneurs] = useState([]);
  const [updateConteneurs, setUpdateConteneurs] = useState([]);
  const [isHandleEscaleSelect, setIsHandleEscaleSelect] = useState(false);
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false);
  const [sendBookingDialogOpen, setSendBookingDialogOpen] = useState(false);
  const [cancelBookingDialogOpen, setCancelBookingDialogOpen] = useState(false);
  const [etaDate, setEtaDate] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentAction, setCurrentAction] = useState(null);

  const [portDeChargement, setPortDeChargement] = useState(null);
  const [portDestinataire, setPortDestinataire] = useState(null);
  const [transitaire, setTransitaire] = useState(null);
  const [clientExportateur, setClientExportateur] = useState(null);
  const [typeOperateur, setTypeOperateur] = useState(null);
  const [snackBarState, setSnackBarState] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "right",
    severity: "",
    duration: 3000,
    message: "",
  });
  const { openSnackBar, vertical, horizontal, severity, duration, message } =
    snackBarState;
  const formikRef = useRef();
  const [submitRequested, setSubmitRequested] = useState(false);

  useEffect(() => {
    let uConteneurs = conteneurs.map((conteneur) => {
      if (!conteneur.id) {
        return { ...conteneur, id: uuid() };
      }
      return conteneur;
    });
    setUpdateConteneurs(uConteneurs);
  }, [conteneurs]);

  useEffect(() => {
    if (bookingId) {
      setIsLoadingBackDrop(true);
      setMode(modes.update);
      const request = {
        id: bookingId,
        successCallBack: () => {
          setIsLoadingBackDrop(false);
        },
        failCallBack: () => {
          setIsLoadingBackDrop(false);
        },
      };
      dispatch(getBooking(request));
    }
  }, [bookingId]);

  useEffect(() => {
    if (toUpdateBooking) {
      if (toUpdateBooking?.avisoLlegada?.id) {
        const request = {
          id: toUpdateBooking?.avisoLlegada?.id,
          successCallBack: () => {
            setIsLoadingBackDrop(false);
          },
          failCallBack: () => {
            setIsLoadingBackDrop(false);
          },
        };
        dispatch(getArrivalNotice(request));
      }
      initBooking();
      setPortDeChargement(toUpdateBooking?.portDechargement);
      setPortDestinataire(toUpdateBooking?.portDestinataire);
      setTransitaire(toUpdateBooking?.transitaire);
      setClientExportateur(toUpdateBooking?.exportateur);
      setTypeOperateur(toUpdateBooking?.typeOperator);
    }
  }, [toUpdateBooking, dispatch]);

  useEffect(() => {
    if (arrivalNotice) {
      if (isHandleEscaleSelect === true) {
        setArrivalNoticeFields();
        setIsHandleEscaleSelect(false);
      } else if (mode == modes.update) {
        initArrivalNotice();
      }
    }
  }, [arrivalNotice]);

  useEffect(() => {
    if (submitRequested) {
      formikRef.current?.submitForm();
      setSubmitRequested(false);
    }
  }, [submitRequested]);

  const initBooking = () => {
    formikRef?.current?.setValues({ ...toUpdateBooking });
    const { conteneurs: containers } = toUpdateBooking;
    setConteneurs([...containers]);
    formikRef?.current?.setFieldValue(
      "portDechargement",
      toUpdateBooking?.portDechargement?.description
    );
    formikRef?.current?.setFieldValue(
      "portDestinataire",
      toUpdateBooking?.portDestinataire?.description
    );
    formikRef?.current?.setFieldValue(
      "transitaire",
      toUpdateBooking?.transitaire?.code
    );
    formikRef?.current?.setFieldValue(
      "exportateur",
      toUpdateBooking?.exportateur?.description
    );
    formikRef?.current?.setFieldValue(
      "typeOperateur",
      toUpdateBooking?.typeOperator?.description
    );
  };
  const initArrivalNotice = () => {
    const updatedValues = {
      ...formikRef.current.values,
      numEscale: arrivalNotice.numEscale,
      numVoyage: arrivalNotice.travelNumber,
      navire: arrivalNotice.ship.name,
    };
    formikRef.current.setValues(updatedValues);
  };
  const setArrivalNoticeFields = () => {
    formikRef?.current?.setFieldValue("avisoLlegada", arrivalNotice?.id);
    formikRef?.current?.setFieldValue("numVoyage", arrivalNotice?.travelNumber);
    formikRef?.current?.setFieldValue("navire", arrivalNotice?.ship?.name);
    formikRef?.current?.setFieldValue(
      "armateur",
      arrivalNotice?.ship?.typeShipowner?.description
    );

    formikRef?.current?.setFieldValue(
      "dateEtd",
      arrivalNotice?.etd
        ? moment(arrivalNotice?.etd).format("YYYY-MM-DD[T]HH:mm:ss")
        : formikRef?.current.values?.dateEtd
        ? formikRef?.current.values?.dateEtd
        : ""
    );
    formikRef?.current?.setFieldValue(
      "dateEta",
      arrivalNotice?.arrivalDate
        ? moment(arrivalNotice?.arrivalDate).format("YYYY-MM-DD[T]HH:mm:ss")
        : ""
    );
    formikRef?.current?.setFieldValue(
      "typeOperateur",
      arrivalNotice?.rnoticeOperatorList[0]?.typeOperator?.description
    );
    setTypeOperateur(arrivalNotice?.rnoticeOperatorList[0]?.typeOperator);
  };
  useEffect(() => {
    if (isCreationSuccess) {
      setMode(modes.update);
    }
  }, [isCreationSuccess]);

  const handleSubmit = (values) => {
    setIsLoadingBackDrop(true);
    let payload = { ...formikRef?.current?.values };
    payload.portDechargement = portDeChargement?.id;
    payload.portDestinataire = portDestinataire?.id;
    payload.exportateur = clientExportateur?.id;
    payload.transitaire = transitaire?.id;
    payload.typeOperateur = typeOperateur?.id;

    payload.conteneurs = payload?.conteneurs?.map((elm) => {
      delete elm?.id;
      return elm;
    });

    const request = {
      payload,
      successCallBack: (data) => {
        //formikRef?.current?.resetForm();
        setIsLoadingBackDrop(false);
        setSnackBarState({
          ...snackBarState,
          openSnackBar: true,
          message: data?.message,
          severity: "success",
        });
        console.log(data?.data?.etat.code);
        if (data?.data?.etat.code === "3") {
          navigate("/ConsultationBooking", {
            state: { id: data?.data?.id },
          });
        } else {
          navigate(`/creation/${data?.data?.id}`);
        }
      },
      failCallBack: (message) => {
        setIsLoadingBackDrop(false);
        setSnackBarState({
          ...snackBarState,
          openSnackBar: true,
          message: message,
          severity: "error",
        });
      },
    };

    if (mode == modes.create || mode == modes.createAndSend) {
      if (mode == modes.create) {
        dispatch(creerBooking(request));
      } else if (mode == modes.createAndSend) {
        dispatch(creerAndSendBooking(request));
      }
    } else if (mode == modes.update  || mode == modes.updateAndSend) {
      payload.id = bookingId;
      payload.avisoLlegada = payload?.avisoLlegada.id !=null?payload.avisoLlegada.id:payload.avisoLlegada;

      if(mode == modes.update){
        dispatch(updateBooking(request));
      }else {
        dispatch(updateAndSendBooking(request));
      }
    }
  };

  const handleCancelBooking = () => {
    setIsLoadingBackDrop(true);
    const request = {
      id: bookingId,
      successCallBack: (message) => {
        setIsLoadingBackDrop(false);
        setSnackBarState({
          ...snackBarState,
          openSnackBar: true,
          message: message,
          severity: "success",
        });
        setTimeout(() => {
          navigate("/gestion", { search: { action: "manage" } });
        }, 2000);
      },
      failCallBack: (message) => {
        setIsLoadingBackDrop(false);
        setSnackBarState({
          ...snackBarState,
          openSnackBar: true,
          message: message,
          severity: "error",
        });
      },
    };
    dispatch(cancelBooking(request));
  };

  const handleSendBooking = () => {
    setMode(modes.updateAndSend);
    setSubmitRequested(true);
  };

  /**
  const handleSendBooking = () => {
    setIsLoadingBackDrop(true);
    const request = {
      id: bookingId,
      successCallBack: (message) => {
        setIsLoadingBackDrop(false);
        setSnackBarState({
          ...snackBarState,
          openSnackBar: true,
          message: message,
          severity: "success",
        });
        setTimeout(() => {
          navigate("/gestion", { search: { action: "manage" } });
        }, 2000);
      },
      failCallBack: (message) => {
        setIsLoadingBackDrop(false);
        setSnackBarState({
          ...snackBarState,
          openSnackBar: true,
          message: message,
          severity: "error",
        });
      },
    };
    dispatch(sendBooking(request));
  };
  **/

  const handleClose = () => {
    navigate("/gestion", { search: { action: "manage" } });
  };

  const cycleOptions = [
    { label: "Export", value: "export" },
    { label: "Transbordement", value: "transbordement" },
  ];
  const handleOnAddContainer = (containers) => {
    formikRef?.current?.setFieldValue("conteneurs", containers);
    setConteneurs(containers);
  };
  const handleOnDeleteContainer = (container) => {
    const conteneurs = formikRef?.current?.values.conteneurs.filter(
      (elm) => elm.id != container.id
    );
    formikRef?.current?.setFieldValue("conteneurs", conteneurs);
    setConteneurs(conteneurs);
  };
  const handleOnUpdateContainer = (containers) => {
    formikRef?.current?.setFieldValue("conteneurs", containers);
    setConteneurs(containers);
  };
  const handleOnMassiveLoading = (containers) => {
    formikRef?.current?.setFieldValue("conteneurs", containers);
    setConteneurs(containers);
  };
  const findDuplicateConteneurs = (arr) => {
    const arrValue = arr.map((elm) => elm?.codeIso);
    return arrValue.filter((item, index) => {
      return arrValue.indexOf(item) !== index;
    });
  };

  const handleEscaleUpdate = (row) => {
    setIsLoadingBackDrop(true);
    setIsHandleEscaleSelect(true);
    const request = {
      id: row?.id,
      successCallBack: () => {
        setIsLoadingBackDrop(false);
      },
      failCallBack: () => {
        setIsLoadingBackDrop(false);
      },
    };
    dispatch(getArrivalNotice(request));
  };

  const handleEscaleSelect = (row) => {
    setIsLoadingBackDrop(true);
    setIsHandleEscaleSelect(true);
    const request = {
      id: row?.id,
      successCallBack: () => {
        setIsLoadingBackDrop(false);
      },
      failCallBack: () => {
        setIsLoadingBackDrop(false);
      },
    };
    dispatch(getArrivalNotice(request));
  };

  return (
    <>
      <PuiMainContainer>
        <PuiMainTitle
          icon={<></>}
          title="Booking"
          trace={[
            "Accueil",
            `${mode === modes.update ? "Modifier" : "Créer"} un booking`,
          ]}
        />
        <PuiNavigation
          onRetour={() => {
            handleClose();
          }}
          retour
        >
          <React.Fragment key=".0">
            {(mode == modes.create || mode == modes.createAndSend) && (
              <PuiButton
                onClick={() => {
                  setSendBookingDialogOpen(true);
                  setCurrentAction("createAndSend");

                }}
              >
                Créer et envoyer
              </PuiButton>
            )}
            {mode == modes.update && (
              <>
                {toUpdateBooking?.etat.code ==
                  EtatBookingConstants.CONFIRME && (
                  <PuiButton onClick={() => setCancelBookingDialogOpen(true)}>
                    Annuler
                  </PuiButton>
                )}
                <PuiButton onClick={() => {
                  setSendBookingDialogOpen(true);
                  setCurrentAction("send");
                }}>
                  Envoyer
                </PuiButton>
              </>
            )}
            <PuiButton
              onClick={() => {
                setMode(mode === "update" ? modes.update : modes.create);
                setSubmitRequested(true);
              }}
            >
              {mode == "update" ? "Modifier" : "Créer"}
            </PuiButton>
          </React.Fragment>
        </PuiNavigation>
        {mode == modes.update ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Stack item xs={1}>
              <Primary>
                <h3> État </h3>
              </Primary>
            </Stack>
            <Stack item xs={2}>
              <PuiChip
                color={getChipColorByEtatCode(toUpdateBooking?.etat.code)}
                fullWidth
              >
                {" "}
                {getEtatDescriptionByCode(toUpdateBooking?.etat.code)}
              </PuiChip>
            </Stack>
          </Stack>
        ) : (
          ""
        )}
        <PuiSection title="Données booking" sx={{ marginTop: "15px" }}>
          <Formik
            debug={true}
            initialValues={bookingInitialValues}
            validationSchema={bookingValidationSchema}
            onSubmit={(values, actions) => {
              handleSubmit(values);
            }}
            innerRef={formikRef}
          >
            <PuiFormikForm>
              <PuiGrid container>
                <PuiGrid item sm={4} xs={4}>
                  <PuiTextField
                    id="numeroBooking"
                    name="numeroBooking"
                    label="N° Booking"
                    required
                  />
                </PuiGrid>
                <PuiGrid item sm={4} xs={4}>
                  <EscaleSearchPopUp
                    arrivalNotice={arrivalNotice}
                    onChange={(row) => handleEscaleSelect(row)}
                  />
                </PuiGrid>

                <PuiGrid item sm={4} xs={4}>
                  <PuiTextField
                    id="typeOperateur"
                    name="typeOperateur"
                    label="Opérateur"
                    disabled
                  />
                </PuiGrid>
                <PuiGrid item sm={4} xs={4}>
                  <PuiTextField
                    id="numVoyage"
                    name="numVoyage"
                    label="N° voyage"
                    disabled
                  />
                </PuiGrid>
                <PuiGrid item sm={4} xs={4}>
                  <PuiTextField
                    id="navire"
                    name="navire"
                    label="Navire"
                    disabled
                  />
                </PuiGrid>
                <PuiGrid item sm={4} xs={4}>
                  <PuiDateTimeField
                    formik={true}
                    fullWidth={true}
                    name="dateReservation"
                    label="Date Réservation"
                    value={formikRef?.current?.values.dateReservation}
                    onChange={(value) => {
                      formikRef?.current?.setFieldValue(
                        "dateReservation",
                        value
                      );
                    }}
                    renderInput={(params) => (
                      <PuiTextField
                        label=""
                        id="dateReservation"
                        name="dateReservation"
                        formik={true}
                        fullWidth={true}
                        required
                        {...params}
                      />
                    )}
                  />
                </PuiGrid>
                <PuiGrid item sm={4} xs={4}>
                  <PuiDateTimeField
                    formik={true}
                    fullWidth={true}
                    name="dateLimite"
                    label="Date limite du Booking"
                    value={formikRef?.current?.values.dateLimite}
                    onChange={(value) => {
                      formikRef?.current?.setFieldValue("dateLimite", value);
                    }}
                    renderInput={(params) => (
                      <PuiTextField
                        label=""
                        id="dateLimite"
                        formik={true}
                        name="dateLimite"
                        fullWidth={true}
                        required
                        {...params}
                      />
                    )}
                  />
                </PuiGrid>
                <PuiGrid item sm={4} xs={4}>
                  <PuiSpecificReferentielField
                    getRenderedValue={(row) => row?.description}
                    getReturnedValue={(row) => row?.id}
                    label="1er port de déchargement"
                    name="portDechargement"
                    required
                    value={mode === modes.update ? portDeChargement : null}
                    onChange={(row) => {
                      setPortDeChargement(row);
                    }}
                    onError={() => {}}
                    searchKey="LOCALITES"
                    title="Liste des ports"
                  />
                </PuiGrid>
                <PuiGrid item sm={4} xs={4}>
                  <PuiSpecificReferentielField
                    getRenderedValue={(row) => row?.description}
                    getReturnedValue={(row) => row?.id}
                    label="Destination finale"
                    name="portDestinataire"
                    value={mode === modes.update ? portDestinataire : null}
                    onChange={(row) => {
                      setPortDestinataire(row);
                    }}
                    onError={() => {}}
                    formik={true}
                    searchKey="LOCALITES"
                    title="Liste des ports"
                    required
                  />
                </PuiGrid>

                <PuiGrid item sm={4} xs={4}>
                  <PuiTextField
                    id="armateur"
                    name="armateur"
                    label="ARMATEUR"
                  />
                </PuiGrid>
                <PuiGrid item sm={4} xs={4}>
                  <PuiTextField
                    id="codeArmement"
                    name="codeAbonnementBl"
                    label="Code Armement B/L"
                  />
                </PuiGrid>
                <PuiGrid item sm={4} xs={4}>
                  <PuiSpecificReferentielField
                    searchKey="OPERATEURS_IMP_EXP"
                    name="exportateur"
                    required
                    value={mode === modes.update ? clientExportateur : null}
                    label="Client (exportateur/FF"
                    titre="Liste des exportateurs"
                    getRenderedValue={(row) => row?.description}
                    getReturnedValue={(row) => row?.code}
                    onChange={(row) => setClientExportateur(row)}
                  />
                </PuiGrid>
                <PuiGrid item sm={4} xs={4}>
                  <PuiSpecificReferentielField
                    searchKey="TRANSITAIRES"
                    name="transitaire"
                    value={mode === modes.update ? transitaire : null}
                    label="Code Transitaire"
                    titre="Liste des transitaires"
                    getRenderedValue={(row) => row?.description}
                    getReturnedValue={(row) => row?.code}
                    onChange={(row) => {
                      setTransitaire(row);
                    }}
                  />
                </PuiGrid>

                <PuiGrid item sm={4} xs={4}>
                  <PuiDateTimeField
                    formik={true}
                    fullWidth={true}
                    label="ETA"
                    name="dateEta"
                    value={formikRef?.current?.values.dateEta}
                    onChange={(value) => {
                      formikRef?.current?.setFieldValue("dateEta", value);
                    }}
                    renderInput={(params) => (
                      <PuiTextField
                        label=""
                        name="dateEta"
                        id="dateEta"
                        formik={true}
                        fullWidth={true}
                        required
                        {...params}
                      />
                    )}
                  />
                </PuiGrid>
                <PuiGrid item sm={4} xs={4}>
                  <PuiDateTimeField
                    formik={true}
                    fullWidth={true}
                    name="dateEtd"
                    value={formikRef?.current?.values.dateLimite}
                    label="ETD"
                    onChange={(value) => {
                      formikRef?.current?.setFieldValue("dateEtd", value);
                    }}
                    renderInput={(params) => (
                      <PuiTextField
                        label=""
                        name="dateEtd"
                        formik={true}
                        id="dateEtd"
                        fullWidth={true}
                        required
                        {...params}
                      />
                    )}
                  />
                </PuiGrid>
                <PuiGrid item sm={4} xs={4}>
                  <PuiRadioGroup
                    label="Cycle"
                    name="cycleBooking"
                    onChange={function noRefCheck() {}}
                    row
                  >
                    {cycleOptions.map((elm) => (
                      <PuiRadioItem label={elm.label} value={elm.value} />
                    ))}
                  </PuiRadioGroup>
                </PuiGrid>
              </PuiGrid>
            </PuiFormikForm>
          </Formik>
        </PuiSection>
        <Conteneurs
          conteneurs={updateConteneurs}
          onAddContainer={(containers) => handleOnAddContainer(containers)}
          onUpdateContainer={(containers) =>
            handleOnUpdateContainer(containers)
          }
          onDeleteContainer={(container) => handleOnDeleteContainer(container)}
          onMassiveLoading={(containers) => handleOnMassiveLoading(containers)}
        />
      </PuiMainContainer>

      <PuiLoadingBackdrop open={isLoadingBackDrop} />
      <CustomSnackbar
        open={openSnackBar}
        anchor={{
          vertical: "top",
          horizontal: "center",
        }}
        duration={duration}
        message={
          <span>
            <b>{message}</b>
          </span>
        }
        severity={severity}
        close={() => {
          setSnackBarState({ ...snackBarState, openSnackBar: false });
        }}
      />
      {sendBookingDialogOpen && (
        <PuiDialog
          open={sendBookingDialogOpen}
          title="Confirmation D'envoi"
          onClose={() => setSendBookingDialogOpen(false)}
        >
          <PuiGrid container>
            <PuiGrid item>
              <PuiIndication>
                {currentAction === "send" ?
                    "Vous êtes sur le point d'envoyer le booking. Souhaitez-vous continuer?" :
                    "Vous êtes sur le point de créer et d'envoyer le booking. Souhaitez-vous continuer?"
                }
              </PuiIndication>
            </PuiGrid>
          </PuiGrid>
          <PuiGrid container justifyContent="end" sx={{ mt: 2 }}>
            <PuiGrid item xs={12} sm={6} sx={{ textAlign: "right" }}>
              <PuiButton
                onClick={() => {
                  setSendBookingDialogOpen(false);
                }}
              >
                Annuler
              </PuiButton>
              <PuiButton
                onClick={() => {
                  if (currentAction === "send") {
                    handleSendBooking();
                  }
                  else if (currentAction === "createAndSend") {
                    setMode(modes.createAndSend);
                    setSubmitRequested(true);
                  }
                  setSendBookingDialogOpen(false);
                  setCurrentAction(null);
                }}
                sx={{ ml: 1 }}
              >
                OK
              </PuiButton>
            </PuiGrid>
          </PuiGrid>
        </PuiDialog>
      )}
      {cancelBookingDialogOpen && (
        <PuiDialog
          open={cancelBookingDialogOpen}
          title="Confirmation D'annulation"
          onClose={() => setCancelBookingDialogOpen(false)}
        >
          <PuiGrid container>
            <PuiGrid item>
              <PuiIndication>
                Vous ête sur le point d'annuler le booking. Souhaite vouz
                continuer?
              </PuiIndication>
            </PuiGrid>
          </PuiGrid>
          <PuiGrid container justifyContent="end" sx={{ mt: 2 }}>
            <PuiGrid item xs={12} sm={6} sx={{ textAlign: "right" }}>
              <PuiButton
                onClick={() => {
                  setCancelBookingDialogOpen(false);
                }}
              >
                Annuler
              </PuiButton>
              <PuiButton
                onClick={() => {
                  handleCancelBooking();
                  setCancelBookingDialogOpen(false);
                }}
                sx={{ ml: 1 }}
              >
                OK
              </PuiButton>
            </PuiGrid>
          </PuiGrid>
        </PuiDialog>
      )}
    </>
  );
}
