import  instance from "../../AxiosInterceptor";
import config from "../../config";

export const getArmateurApi = (data) => {

  return instance
    .post(
      config.url + "/ms-bookingApi/booking/searchArmateur",
      (data = { nAviso: data })
    )
    .then((response) => {
      return response;
    })
    .then((err) => {
      return err;
    });
};
