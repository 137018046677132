import {
  PuiButton,
  PuiCheckbox,
  PuiFormikForm,
  PuiGrid,
  PuiRadioGroup,
  PuiRadioItem,
  PuiSection,
  PuiSimplePopupReferentielField,
  PuiTable,
  PuiTableAction,
  PuiTextField,
} from "@portnet/ui";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {Formik} from "formik";
import React, {useEffect, useRef, useState} from "react";
import {conteneurDetailInitialValues} from "../data/InitialValues";
import {conteneurDetailValidationSchema} from "../data/ValidationSchema";
import ConteneurSearch from "../components/ConteneurSearch";
import UploadIcon from "@mui/icons-material/Upload";
import CustomSnackbar from "../../../common/components/CustomSnackbar/CustomSnackbar";
import {v4 as uuid} from "uuid";
import Grid from "@mui/material/Grid";

import {CollapsibleTableContainer} from "../../../common/components/CustomTable/CollapsibleTableContainer";
import xml2js from "xml2js";
import {mapContainersWithDetail, mapContainersWithoutDetail,} from "../services/XMLMapper";

const modes = {
  create: "create",
  update: "update",
};

export const Conteneurs = (props) => {
  const {
    conteneurs,
    onAddContainer,
    onDeleteContainer,
    onUpdateContainer,
    onMassiveLoading,
  } = props;
  const formikRef = useRef();
  const [checkedDetail, setCheckedDetail] = React.useState(false);
  const [fileName, setFileName] = useState("");
  const [valueFile, setValueFile] = useState("");
  const [mode, setMode] = useState(modes.create);
  const [displayDetail, setDisplayDetail] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [toUpdateContainer, setToUpdateContainer] = useState({});
  const [snackBarState, setSnackBarState] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "right",
    severity: "",
    duration: 3000,
    message: "",
  });
  const { openSnackBar, vertical, horizontal, severity, duration, message } =
    snackBarState;

  const handleUpdateConteneur = (row) => {
    setMode(modes.update);
    setCurrentRow(row);
    setToUpdateContainer({ ...row });
    formikRef?.current?.setFieldValue("withDetails", row?.withDetails);
    formikRef?.current?.setFieldValue("classImo", row?.classImo);
    setCheckedDetail(row?.withDetails);
  };
  const handleDeleteConteneur = (row) => {
    onDeleteContainer(row);
  };
  var poidsTotal = 0;
  var nombre = 0;

  useEffect(() => {
    if (conteneurs) {
      conteneurs.forEach((conteneur) => {
        if (conteneur.withDetails) {
          poidsTotal += conteneur.poidsBrut || 0;
          nombre++;
        } else {
          poidsTotal += conteneur.poidsTotal || 0;
          nombre += conteneur.nombreConteneur || 0;
        }
      });
    }
  }, [conteneurs]);
  useEffect(() => {
    if(displayDetail){
      conteneurs.forEach((conteneur) => {
        if(conteneur.conteneurDetails) {
          conteneurs.push(conteneur.conteneurDetails[0])
        }
      })
    }

  }, [displayDetail]);

  useEffect(() => {
      formikRef?.current?.setFieldValue("classImo", currentRow?.classImo);
  }, [currentRow,mode]);

  const findDuplicateConteneursByCodeIso = (arr) => {
    const arrValue = arr
      .filter((elm) => elm.withDetails === false)
      .map((elm) => elm?.codeIso);
    return arrValue.filter((item, index) => {
      return arrValue.indexOf(item) !== index;
    });
  };
  const findDuplicateConteneursByNum = (arr) => {
    const arrValue = arr
      .filter((elm) => elm.withDetails === true)
      .map((elm) => elm?.numConteneur);
    return arrValue.filter((item, index) => {
      return arrValue.indexOf(item) !== index;
    });
  };

  const tableActions = [
    <PuiTableAction
      onClick={(row) => {
        handleUpdateConteneur(row);
      }}
      title="Modifier"
    >
      <EditIcon />
    </PuiTableAction>,
    <PuiTableAction
      onClick={(row) => {
        handleDeleteConteneur(row);
      }}
      title="Supprimer"
    >
      <DeleteIcon />
    </PuiTableAction>,
  ];
  const columns = [
    { field: "numConteneur", headerName: "N° Conteneur", width: 300 },
    { field: "nombreConteneur", headerName: "Nombre", width: 300 },
    { field: "codeIso", headerName: "Code ISO", width: 300, flex: 1 },
  ];

  const handleSubmit = () => {
    let container = formikRef?.current?.values;
    if (mode == modes.create) {
      //debugger
      container.id = uuid();
      const containers = [...conteneurs, container];
      let duplicate = [];
      if (container.withDetails == true) {
        duplicate = findDuplicateConteneursByNum(containers);
      } else if (container.withDetails == false) {
        duplicate = findDuplicateConteneursByCodeIso(containers);
      }
      if (duplicate.length > 0) {
        setSnackBarState({
          ...snackBarState,
          openSnackBar: true,
          severity: "warning",
          message:
            (container.withDetails
              ? "N° Conteneur est déja présent dans la liste: "
              : "Code iso déja présent dans la liste des conteneurs: ") +
            duplicate.toString(),
        });
        return;
      }
      onAddContainer(containers);
    } else if (mode == modes.update) {
      if (toUpdateContainer.id != null) {
        container.id = toUpdateContainer.id;
      } else {
        container.id = uuid();
      }

      const containers = conteneurs.map((elm) => {
        if (elm.id === container.id) return container;
        return elm;
      });
      let duplicate = [];
      if (container.withDetails == true) {
        duplicate = findDuplicateConteneursByNum(containers);
      } else if (container.withDetails == false) {
        duplicate = findDuplicateConteneursByCodeIso(containers);
      }
      if (duplicate.length > 0) {
        setSnackBarState({
          ...snackBarState,
          openSnackBar: true,
          severity: "warning",
          message:
            (container.withDetails
              ? "N° Conteneur est déja présent dans la liste: "
              : "Code iso déja présent dans la liste des conteneurs: ") +
            duplicate.toString(),
        });
        return;
      }
      onUpdateContainer(containers);
      setToUpdateContainer({});
      setMode(modes.create);
    }
    formikRef?.current?.resetForm();
    setCheckedDetail(false);
  };

  const etatConteneurOptions = [
    { label: "Vide", value: "V" },
    { label: "Plein", value: "P" },
  ];
  const booleanOptions = [
    { label: "Oui", value: "oui" },
    { label: "Non", value: "non" },
  ];

  const handleClassImoChange = (newValue) => {
    formikRef?.current?.setFieldValue('classImo', newValue.code);
  };

  const onChangeFile = (e) => {
    const file = e?.target?.files[0];
    if (file instanceof Blob) {
      setFileName(file?.name);
      let reader = new FileReader();
      reader?.readAsText(file);
      reader.onload = (e) => {
        const parser = new xml2js.Parser({ explicitArray: false });
        parser.parseString(e.target.result, function (err, result) {
          const conteneurSansDetail =
            result?.DonneesBooking?.DonneesConteneursSansDetails
              ?.DonneesConteneurSansDetail;
          const conteneurAvecDetail =
            result?.DonneesBooking?.DonneesConteneursDetails
              ?.DonneesConteneurDetail;
          const mappedContainersWithoutDetail =
            mapContainersWithoutDetail(conteneurSansDetail);
          const mappedContainersWithDetail =
            mapContainersWithDetail(conteneurAvecDetail);
          const containers = [
            ...conteneurs,
            ...mappedContainersWithDetail,
            ...mappedContainersWithoutDetail,
          ];
          let duplicate = [];
          duplicate = findDuplicateConteneursByCodeIso(containers);
          if (duplicate.length > 0) {
            setSnackBarState({
              ...snackBarState,
              openSnackBar: true,
              severity: "warning",
              message:
                "Code iso déja présent dans la liste des conteneurs: " +
                duplicate.toString(),
            });
            return;
          } else {
            duplicate = findDuplicateConteneursByNum(containers);
            if (duplicate.length > 0) {
              setSnackBarState({
                ...snackBarState,
                openSnackBar: true,
                severity: "warning",
                message:
                  "N° Conteneur est déja présent dans la liste: " +
                  duplicate.toString(),
              });
              return;
            }
          }
          onMassiveLoading(containers);
        });
      };
    }
  };

  return (
    <>
      <PuiSection title="Conteneurs" sx={{ marginTop: "15px" }}>
        <PuiGrid container justifyContent="center">
          <PuiGrid item xs={12} sm={6} sx={{ textAlign: "center" }}>
            <PuiButton startIcon={<UploadIcon />} component="label">
              Charger un fichier XML
              <input
                type="file"
                name="file"
                onChange={(e) => onChangeFile(e)}
                value={valueFile}
                hidden
              />
            </PuiButton>
          </PuiGrid>
        </PuiGrid>
        <Formik
          initialValues={conteneurDetailInitialValues}
          validationSchema={conteneurDetailValidationSchema(checkedDetail)}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, resetForm);
          }}
          innerRef={formikRef}
        >
          <PuiFormikForm>
            <PuiGrid container justifyContent="center">
              <PuiGrid item xs={12} sm={6} sx={{ textAlign: "center" }}>
                <PuiCheckbox
                  label="Activer le détail du conteneur"
                  name="withDetails"
                  //checked={mode == modes.update ? toUpdateContainer?.activeDetail : checkedDetail}
                  onChange={(event) => {
                    setCheckedDetail(event.target.checked);
                  }}
                />
              </PuiGrid>
            </PuiGrid>
            <PuiGrid container spacing={2} sx={{ marginTop: "15px" }}>
              {checkedDetail ? (
                <PuiGrid item xs={6} sm={4}>
                  <ConteneurSearch
                    id="numConteneur"
                    name="numConteneur"
                    label="N° Conteneur"
                    value={
                      mode == modes.update
                        ? toUpdateContainer?.numConteneur
                        : null
                    }
                  />
                </PuiGrid>
              ) : (
                <PuiGrid item xs={6} sm={4}>
                  <PuiTextField
                    name="nombreConteneur"
                    value={
                      mode == modes.update
                        ? toUpdateContainer?.nombreConteneur
                        : null
                    }
                    label="Nombre"
                    required={!checkedDetail}
                  />
                </PuiGrid>
              )}
              <PuiGrid item xs={6} sm={4}>
                <PuiSimplePopupReferentielField
                  getRenderedValue={(row) => row?.code}
                  getReturnedValue={(row) => row?.code}
                  label="Code ISO"
                  name="codeIso"
                  required
                  searchKey="LISTE_TYPES_CONTENEURS"
                  title="List des types des conteneurs"
                  value={
                    mode == modes.update
                      ? { code: toUpdateContainer?.codeIso }
                      : null
                  }
                />
              </PuiGrid>
              {!checkedDetail && (
                <PuiGrid item xs={6} sm={4}>
                  <PuiTextField
                    name="poidsTotal"
                    value={
                      mode == modes.update
                        ? toUpdateContainer?.poidsTotal
                        : null
                    }
                    label="Poids total (KG)"
                    required={!checkedDetail}
                  />
                </PuiGrid>
              )}
              {checkedDetail && (
                <>
                  <PuiGrid item xs={6} sm={4}>
                    <PuiRadioGroup
                      label="Etat conteneur"
                      name="etatConteneur"
                      value={
                        mode == modes.update
                          ? toUpdateContainer?.etatConteneur
                          : formikRef?.current?.values?.etatConteneur
                      }
                      row
                    >
                      {etatConteneurOptions.map((elm) => (
                        <PuiRadioItem label={elm.label} value={elm.value} />
                      ))}
                    </PuiRadioGroup>
                  </PuiGrid>
                  <PuiGrid item xs={6} sm={4}>
                    <PuiTextField
                      name="poidsBrut"
                      value={
                        mode == modes.update ? toUpdateContainer?.poidsBrut : ""
                      }
                      label="Poids Brut (KG)"
                      required={true}
                    />
                  </PuiGrid>
                  <PuiGrid item xs={6} sm={4}>
                    <PuiTextField
                      name="poidsNet"
                      required
                      value={
                        mode == modes.update ? toUpdateContainer?.poidsNet : ""
                      }
                      label="Poids Net (KG)"
                    />
                  </PuiGrid>
                  <PuiGrid item xs={6} sm={4}>
                    <PuiSimplePopupReferentielField
                      getRenderedValue={(row) => row?.code}
                      getReturnedValue={(row) => row?.code}
                      label="Class IMO"
                      name="classImo"
                      value={
                        mode == modes.update
                          ? { code: toUpdateContainer?.classImo }
                          : null
                      }
                      searchKey="LISTE_TYPES_CLASSES_IMO"
                      title="List des classes imo"
                    />
                  </PuiGrid>
                  <PuiGrid item xs={6} sm={4}>
                    <PuiTextField
                      name="numScelleArmateur"
                      value={
                        mode == modes.update
                          ? toUpdateContainer.numScelleArmateur
                          : ""
                      }
                      label="N° Scellé armateur"
                    />
                  </PuiGrid>
                  <PuiGrid item xs={6} sm={4}>
                    <PuiTextField
                      name="numScelleChargeur"
                      value={
                        mode == modes.update
                          ? toUpdateContainer.scelleChargeur
                          : ""
                      }
                      label="N° Scellé chargeur"
                    />
                  </PuiGrid>
                  <PuiGrid item xs={6} sm={4}>
                    <PuiTextField
                      name="numPermisDouane"
                      value={
                        mode == modes.update
                          ? toUpdateContainer.numPermisDouane
                          : ""
                      }
                      label="N° Permis de douane"
                    />
                  </PuiGrid>
                  <PuiGrid item xs={6} sm={4}>
                    <PuiRadioGroup
                      label="Tag frigo"
                      name="tagFrigo"
                      value={
                        mode == modes.update
                          ? toUpdateContainer?.tagFrigo
                          : "non"
                      }
                      row
                    >
                      {booleanOptions.map((elm) => (
                        <PuiRadioItem label={elm.label} value={elm.value} />
                      ))}
                    </PuiRadioGroup>
                  </PuiGrid>
                  <PuiGrid item xs={6} sm={4}>
                    <PuiTextField
                      name="temperatureConsigne"
                      value={
                        mode == modes.update
                          ? toUpdateContainer.temperatureConsigne
                          : ""
                      }
                      label="Température consigne"
                    />
                  </PuiGrid>
                  <PuiGrid item xs={6} sm={4}>
                    <PuiRadioGroup
                      label="Tag dangereux"
                      name="tagDangereux"
                      value={
                        mode == modes.update
                          ? toUpdateContainer?.tagDangereux
                          : "non"
                      }
                      row
                    >
                      {booleanOptions.map((elm) => (
                        <PuiRadioItem label={elm.label} value={elm.value} />
                      ))}
                    </PuiRadioGroup>
                  </PuiGrid>
                  <PuiGrid item xs={6} sm={4}>
                    <PuiRadioGroup
                      label="Tag HG : hors gabarits"
                      name="tagHg"
                      value={
                        mode == modes.update ? toUpdateContainer?.tagHg : "non"
                      }
                      row
                    >
                      {booleanOptions.map((elm) => (
                        <PuiRadioItem label={elm.label} value={elm.value} />
                      ))}
                    </PuiRadioGroup>
                  </PuiGrid>
                  <PuiGrid item xs={6} sm={4}>
                    <PuiTextField
                      name="mesureDepassementHauteur"
                      value={
                        mode == modes.update
                          ? toUpdateContainer.mesureDepassementHauteur
                          : ""
                      }
                      label="Mesure dépassement (Hauteur)"
                    />
                  </PuiGrid>
                  <PuiGrid item xs={6} sm={4}>
                    <PuiTextField
                      name="mesureDepassementLargeur"
                      value={
                        mode == modes.update
                          ? toUpdateContainer.mesureDepassementLargeur
                          : ""
                      }
                      label="Mesure dépassement (Largeur)"
                    />
                  </PuiGrid>
                  <PuiGrid item xs={6} sm={4}>
                    <PuiTextField
                      name="mesureDepassementLongueur"
                      value={
                        mode == modes.update
                          ? toUpdateContainer.mesureDepassementLongueur
                          : ""
                      }
                      label="Mesure dépassement (Longueur)"
                    />
                  </PuiGrid>
                  <PuiGrid item xs={6} sm={4}></PuiGrid>
                </>
              )}
            </PuiGrid>
          </PuiFormikForm>
        </Formik>
        <PuiGrid container sx={{ marginTop: "15px" }} justifyContent="flex-end">
          <PuiButton
            onClick={() => {
              formikRef?.current.handleSubmit({});
            }}
          >
            {mode == modes.update ? "Modifier" : "Ajouter"}
          </PuiButton>
          {mode == modes.update && (
            <PuiButton
              onClick={() => {
                formikRef?.current?.resetForm();
                setMode(modes.create);
                setCheckedDetail(false);
              }}
              sx={{ marginLeft: "10px" }}
            >
              Annuler
            </PuiButton>
          )}
        </PuiGrid>
      </PuiSection>
      <PuiTable
        actions={tableActions}
        columns={columns}
        rows={conteneurs ?? []}
      />

      <PuiSection title="" sx={{ marginTop: "25px" }}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={12} md={12} sx={{ marginTop: "4px" }}>
            <CollapsibleTableContainer
              columns={columns}
              ops={true}
              rows={conteneurs ?? []}
              resultData={{
                poidsTotal: poidsTotal || 0,
                nombre: nombre || 0,
              }}
            />
          </Grid>
        </Grid>
      </PuiSection>
      <CustomSnackbar
        open={openSnackBar}
        anchor={{
          vertical: "top",
          horizontal: "center",
        }}
        duration={duration}
        message={
          <span>
            <b>{message}</b>
          </span>
        }
        severity={severity}
        close={() => {
          setSnackBarState({ ...snackBarState, openSnackBar: false });
        }}
      />
    </>
  );
};
export default Conteneurs;
