import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {PuiDateField, PuiGrid, PuiTextField,} from "@portnet/ui";

import {Grid} from "@mui/material";
import {searchListeEscale} from "../../../common/state/commonAction";
import moment from "moment";
import AppCustomPopupReferentielField from "./AppCustomPopupReferentielField";

export const EscaleSearchPopUp = (props) => {
  const formRef = useRef();


  const dispatch = useDispatch();

  const [selectedEscale, setSelectedEscale] = useState(null);
  const {
    content: Escales,
    isLoading: isEscalesLoading,
    isSuccessed: searchEscalesSuccessed,
  } = useSelector((state) => state.commonBooking.searchListeEscale ?? []);
  const [searchEscalePayload, setSearchEscalePayload] = useState({
    criteria: {},
    direction: "ASC",
    page: 0,
    size: 5,
    sortField: "id",
  });

  const [EscaleFetchingState, setEscaleFetchingState] = useState({
    content: [],
    totalElements: 0,
    pageNumber: 0,
    pageSize: 5,
  });

  const searcheEscalePageChangeHandler = (newPage) => {
    setSearchEscalePayload({ ...searchEscalePayload, page: newPage });
    dispatch(searchListeEscale({ ...searchEscalePayload, page: newPage }));
  };
  useEffect(() => {
    setSelectedEscale(props.arrivalNotice);
  }, [props.arrivalNotice]);

  useEffect(() => {
    console.log("useEffect executed");
    const criteria = { ...searchEscalePayload.criteria };
    const search = { ...searchEscalePayload, criteria };
    dispatch(searchListeEscale(search));
  }, []);

  useEffect(() => {
    if (searchEscalesSuccessed) {
      setEscaleFetchingState(Escales);
    }
  }, [searchEscalesSuccessed]);

  const searchEscaleSubmitHandler = (values) => {
    const criteria = { ...values };
    const search = { ...searchEscalePayload, criteria };
    setSearchEscalePayload(search);
    dispatch(searchListeEscale(search));
  };
  const EscalePopupColumns = [
    {
      field: "id",
      headerName: "Nº avis",
      sortable: false,
      flex: 1,
      valueGetter: ({ row }) => row?.id,
    },
    {
      field: "travelNumber",
      headerName: "Nº Voyage",
      sortable: false,
      flex: 1,
      valueGetter: ({ row }) => row?.travelNumber,
    },
    {
      field: "numEscale",
      headerName: "Nº d'escale",
      sortable: false,
      flex: 1,
    },
    {
      field: "shipName",
      headerName: "Navire",
      sortable: false,
      flex: 1,
    },
    {
      field: "arrivalDate",
      headerName: "Date ETA",
      sortable: false,
      minWidth: 200,
      valueGetter: ({ row }) =>
        row.arrivalDate ? moment(row.arrivalDate).format("DD MMMM YYYY") : "-",
    },
  ];
  return (
    <PuiGrid item xs={12}>
      <AppCustomPopupReferentielField
        title="Recherche avis d'arrivée"
          formik={true}
        formikProps={{
          initialValues: {},
          innerRef: formRef,
        }}
        label="Nº escale"
        name="numEscale"
        onSubmit={searchEscaleSubmitHandler}
        onPageChange={searcheEscalePageChangeHandler}
        loading={isEscalesLoading}
        rows={EscaleFetchingState?.content ?? []}
        rowCount={EscaleFetchingState?.totalElements}
        page={EscaleFetchingState?.pageNumber}
        pageSize={EscaleFetchingState?.pageSize}
        columns={EscalePopupColumns}
        onChange={props.onChange}
        required={true}
        value={selectedEscale}
        getRenderedValue={(row) => row.numEscale}
        getReturnedValue={(row) => row.numEscale}
        mode={"server"}
      >
        <PuiGrid container>
          <Grid item xs={12} sm={8} md={6}>
            <PuiTextField id="code" name="notifyNumber" label="N° avis" />
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <PuiTextField
              id="description"
              name="travelNumber"
              label="Nº Voyage"
            />
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <PuiTextField id="codePIN" name="numberScale" label="Nº d'escale" />
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <PuiTextField id="imo" name="imo" label="Imo" />
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <PuiDateField
              format="DD-MM-YYYY LT"
              label="Date ETA"
              id="ifu"
              name="etaDate"
            />
          </Grid>
        </PuiGrid>
      </AppCustomPopupReferentielField>
    </PuiGrid>
  );
};
