import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";

import styles from "../../../assets/jss/customInput.js";
import {ErrorMessage, Field, useField, useFormikContext} from "formik";
import { TextError } from "../Error/TextError.js";

const useStyles = makeStyles(styles);

export default function CustomInputField(props) {
  const classes = useStyles();
  const {
    name,
    variant,
    label,
    id,
    helperText,
    marginLeft,
    mayshowmsg = true,
    handleChange,
    onChange,
      onBlur,
    value,
      disabled,
      backgroundColor,
      ...rest
  } = props;
  const [field, meta] = useField(name);
    const [hasError, setHasError] = useState(false);
    const formikContext = useFormikContext();

    useEffect(() => {
        setHasError(Boolean(meta) && Boolean(meta.touched) && Boolean(meta.error));
    }, [meta]);
    useEffect(() => {
        if (Boolean(value)) {
            formikContext.setFieldValue(name, value);
        }
    }, [value]);

  return (
      <>
          <TextField
              id={id}
              label={label}
              variant={variant}
              disabled={disabled}
              error={hasError}
              helperText={hasError && meta.error}
              name={field.name}
              value={field.value}
              onChange={onChange ?? field.onChange}
              onBlur={onBlur ?? field.onBlur}
              //InputLabelProps={{ shrink: true, }}
              inputProps={{
                  style : {backgroundColor:backgroundColor}
              }}
              {...rest}
          />
      </>
  );
}
