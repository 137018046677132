import { put, call } from "redux-saga/effects";

import { getTransitaireSearchApi } from "../services/api/getTransitaireSearchApi";
import { getClientSearchApi } from "../services/api/getClientSearchApi";
import { getPremierPortApi } from "../services/api/getPremierPortApi";
// import * as actions from '../state/creationBookingAction';
import * as actions from "../state/commonAction";
import { getNumEscale } from "../../modules/creationBooking/state/creationBookingAction";
import { getNumEscaleApi } from "../services/api/getNumEscaleApi";

// /*************************************Saga Detail Booking********************************/
// export function* getNumEscaleSaga(action) {
//   // debugger;
//   try {
//     const response = yield call(getNumEscaleApi, action.payload);
//     yield put(actions.getNumEscaleSuccess(response.data));
//   } catch (error) {
//     yield put(actions.getNumEscaleFailed(error?.response?.data?.detail));
//   }
// }

/*************************************Saga Detail Booking********************************/
export function* getTransitaireSaga(action) {
  //   
  try {
    const response = yield call(getTransitaireSearchApi, action.payload);
    yield put(actions.getTransitaireSuccess(response.data));
  } catch (error) {
    yield put(actions.getTransitaireFail(error));
  }
}

/*************************************Saga Detail Booking********************************/
export function* getClientSearchSaga(action) {
  //   
  try {
    const response = yield call(getClientSearchApi, action.payload);
    yield put(actions.getClientSearchSuccess(response.data));
  } catch (error) {
    yield put(actions.getClientSearchFail(error));
  }
}
/*************************************Saga Detail Booking********************************/
export function* getPremierPortSaga(action) {
  //   
  try {
    const response = yield call(getPremierPortApi, action.payload);
    yield put(actions.getPremierPortSuccess(response.data));
  } catch (error) {
    yield put(actions.getPremierPortFail(error));
  }
}

export function* getEscaleSaga(action) {
  //   
  try {
    const response = yield call(getNumEscaleApi, action.payload);
    yield put(actions.searchListeEscaleSuccess(response.data));
  } catch (error) {
    yield put(actions.searchListeEscaleFail(error));
  }
}
