const config = {
  appVersion: "0.0.1",
  // url: "https://gap2.portnet.ma",
  // urlAuth: "http://gap2.portnet.ma",
  //  url: "http://localhost:9090",
  // urlAuth: "http://localhost:9090",
  // url: "https://gap-dev-api.portnet.ma",
  // urlAuth: "https://gap-dev-api.portnet.ma",
    url: "https://booking.portnet.ma",
    referentielBaseURL:"https://referentiel-api-prod-v2.portnet.ma/",
    portnetBaseUrl: "https://gu.portnet.ma/portnet",
    //url: "http://localhost:9090",

};

export default config;
