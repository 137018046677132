import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';

const useTableStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 0,
    backgroundColor: '#fafafa',
    '& .MuiTableCell-body': {
      fontSize: 15,
      paddingTop: 5,
      paddingBottom: 5,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    '& .MuiTableCell-head': {
      textTransform: 'uppercase',
      color: '#fafafa',
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: 'unset',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
}));

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      {/* *****************************************booking Data********************************************** */}

      <TableRow hover role="checkbox" tabIndex={-1} sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell align="center">{row?.numConteneur}</TableCell>
        <TableCell align="center">{row?.nombreConteneur}</TableCell>
        <TableCell align="center">{row?.codeIso}</TableCell>
      </TableRow>
      {/* *****************************************Conteneur Data********************************************** */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Détail du conteneur
              </Typography>
              {/* {row?.conteneurDetails.length == 0 && (
                <h4 style={{ color: "red" }}>
                  Oops, Malheureusement y'a pas de détail pour ce conteneur
                </h4>
              )}
              {row?.conteneurDetails.length !== 0 && (
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell variant="head" align="center">
                        N° Conteneur
                      </TableCell>
                      <TableCell align="center">Nombre</TableCell>
                      <TableCell align="center">Code ISO</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.conteneurDetails?.map((conteneurDetailRow) => (
                      <>
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={conteneurDetailRow?.numConteneur}
                        >
                          <TableCell align="center">
                            {conteneurDetailRow?.numConteneur}
                          </TableCell>
                          <TableCell align="center">
                            {conteneurDetailRow?.nombreConteneur}
                          </TableCell>
                          <TableCell align="center">
                            {conteneurDetailRow?.codeIso}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              )} */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function CollapsibleTable(props) {
  const { columns, ops, rows, resultData } = props;
  const classes = useTableStyles();

  return (
    <TableContainer className={classes.container}>
      <Table aria-label="collapsible table">
        <caption>
          <Typography
            gutterBottom
            variant="overline"
            color="primary"
            component="span"
            sx={{
              fontSize: 15,
              fontWeight: 'medium',
              color: 'primary',
              textAlign: 'center',
            }}
          >
            Nombre des conteneurs :<span style={{ color: 'red' }}>{resultData?.nombre}</span>
          </Typography>
          <span style={{ padding: '5%' }}></span>
          <Typography
            gutterBottom
            variant="overline"
            color="primary"
            component="span"
            sx={{
              fontSize: 15,
              fontWeight: 'medium',
              color: 'primary',
              textAlign: 'center',
            }}
          >
            Poids total des conteneurs :
            {
               <span style={{color: 'red'}}>{ resultData?.poidsTotal >0 ? resultData?.poidsTotal + ' KG' : ''}</span>
          }

        </Typography>
        </caption>
        <TableHead>
          <TableRow>
            {columns?.map((column) => {
              return (
                <TableCell
                  key={column?.id}
                  align={'center'}
                  style={{
                    minWidth: column?.minWidth,
                    fontWeight: 'bold',
                  }}
                >
                  {column?.label}
                </TableCell>
              );
            })}
            <TableCell key={1000} align="center">
              {ops}
              {/* {ops && 'OPERATIONS'} */}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <>
              <Row key={row.name} row={row} />
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
