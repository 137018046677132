import React, {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Grid from "@mui/material/Grid";
import styles from "../../../assets/jss/style";
import SearchIcon from "@mui/icons-material/Search";
import * as PropTypes from "prop-types";
import {Form, Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {getBookingSearch} from "../state/GestionBookingAction";
import moment from "moment";
import {useLocation, useNavigate} from "react-router-dom";
import EtatBooking from "../../../common/constants/etatBookingConstants";
import HistoriqueBooking from "../../creationBooking/components/HistoriqueBooking";
import HomeIcon from "@mui/icons-material/Home";
import BackspaceIcon from "@mui/icons-material/Backspace";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import {
  PuiButton,
  PuiChip,
  PuiDateTimeField,
  PuiGrid,
  PuiMainContainer,
  PuiMainTitle,
  PuiSection,
  PuiSelect,
  PuiSpecificReferentielField,
  PuiTable,
  PuiTableAction,
  PuiTextField,
} from "@portnet/ui";
import JwtUtils from "../../../common/utils/JwtUtils";
import {ROLE_ADMIN, ROLE_OPERATEUR_MANUTENTION,} from "../../../common/utils/Roles";
import {getChipColorByEtatCode} from "./colors";

const useStyles = makeStyles(styles);

const initialValues = {
  bookingDate: "",
  bookingNum: "",
  containerNum: "",
  escalNum: "",
  exporter: "",
  finalDestination: "",
  freightForwarderCode: "",
  imoShip: "",
  loadingPort: "",
  operatorId: "",
  travelNum: "",
  consignataire: "",
  etat: "",
};
const modes = {
  consult: "consult",
  manage: "manage",
};

function StyledTableCell(props) {
  return null;
}

StyledTableCell.propTypes = { children: PropTypes.node };

function StyledTableRow(props) {
  return null;
}

StyledTableRow.propTypes = { children: PropTypes.node };

export default function GestionBooking(props) {
  const { authorities } = useSelector((state) => state?.general);
  const content =
    useSelector((state) => state?.gestionBooking?.bookingSearchData?.content) ??
    [];
  const isLoading = useSelector(
    (state) => state?.gestionBooking?.isLoadingBookingSearch
  );
  const totalElements = useSelector(
    (state) => state?.gestionBooking?.bookingSearchData?.totalElements
  );

  const location = useLocation();
  const parameters = new URLSearchParams(location?.search);
  //const parameters = new URLSearchParams(window.location.search);
  const action = parameters.get("action");

  const [pager, setPager] = useState({
    page: 0,
    size: 10,
  });
  const navigate = useNavigate();

  const pageChangeHandler = (page) => {
    setPager({ ...pager, page: page });
  };
  const pageSizeChangeHandler = (size) => {
    setPager({ ...pager, size: size });
  };

  const [cardAnimaton, setCardAnimation] = useState("cardHidden");

  const [isReady, setReady] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [valuesPage, setValuesPage] = useState(null);

  const columns = [
    {
      field: "numeroBooking",
      headerName: "N° Booking",
      sortable: false,
      flex: 1,
      valueGetter: ({ row }) => row?.numeroBooking,
    },
    {
      field: "escaleNum",
      headerName: "N° d'escale",
      sortable: false,
      flex: 1,
      valueGetter: ({ row }) => row?.escaleNum,
    },
    {
      field: "travelNum",
      headerName: "N° voyage",
      hide: authorities?.content.ADMIN,
      sortable: false,
      flex: 1,
      valueGetter: ({ row }) => row?.travelNum,
    },
    {
      field: "creePar",
      headerName: "Consignataire",
      hide: !authorities?.content.ADMIN,
      sortable: false,
      flex: 1,
      valueGetter: ({ row }) => row?.creePar,
    },
    {
      field: "operator",
      headerName: "Opérateur",
      sortable: false,
      flex: 1,
      valueGetter: ({ row }) => row?.operator,
    },
    {
      field: "clientExpFF",
      headerName: "Client(exportateur/FF)",
      sortable: false,
      flex: 1.5,
      valueGetter: ({ row }) => row?.clientExpFF,
    },
    {
      field: "firstUnloadingPort",
      headerName: "1èr port de déchargement",
      sortable: false,
      flex: 1,
      valueGetter: ({ row }) => row?.firstUnloadingPort,
    },
    {
      field: "finalDestination",
      headerName: "Destination finale",
      sortable: false,
      flex: 1,
      valueGetter: ({ row }) => row?.finalDestination,
    },
    {
      field: "creationDate",
      headerName: "Date création",
      sortable: false,
      flex: 1,
      valueGetter: ({ row }) => row?.creationDate,
    },
    {
      field: "stateLabel",
      headerName: "Etat",
      flex: 1,
      renderCell: ({ row }) => (
        <PuiChip color={getChipColorByEtatCode(row.etat.code)} fullWidth>
          {row.stateLabel ?? "-"}
        </PuiChip>
      ),
    },
  ];

  useEffect(() => {
    setTimeout(function () {
      setReady(true);
    }, 10);
  }, []);

  const listBookingDefault = useSelector(
    (state) => state.gestionBooking.bookingDefaultData
  );
  const isLoadingBookingDefault = useSelector(
    (state) => state.gestionBooking.isLoadingBookingDefault
  );

  var listActivites = listBookingDefault;

  const listBookingSearch = useSelector(
    (state) => state.gestionBooking.bookingSearchData
  );
  const isLoadingBookingSearch = useSelector(
    (state) => state.gestionBooking.isLoadingBookingSearch
  );

  var listLoadingX = isLoadingBookingDefault;

  const dispatch1 = useDispatch();

  const classes = useStyles();
  const [openTransitairePopUp, setOpenTransitairePopUp] = useState(false);
  const [openNavirePopUp, setOpenNavirePopUp] = useState(false);
  const [openOperateurPopUp, setOpenOperateurPopUp] = useState(false);
  const [portDeChargement, setPortDeChargement] = useState(null);
  const [portDestinataire, setPortDestinataire] = useState(null);
  const [transitaire, setTransitaire] = useState(null);
  const [navire, setNavire] = useState(null);
  const [operateur, setOperateur] = useState(null);
  const [clientExportateur, setClientExportateur] = useState(null);

  const [openClientExpPopUp, setOpenClientExpPopUp] = useState(false);
  const [openPortChargementPopUp, setOpenPortChargementPopUp] = useState(false);
  const [openPortDestinationPopUp, setOpenPortDestinationPopUp] =
    useState(false);

  const [historiqueProps, setHistoriqueProps] = useState({
    open: false,
    id: null,
  });

  const getBookings = () => {
    const searchData = {
      bookingDate: valuesPage?.bookingDate
        ? moment(valuesPage?.bookingDate).format("YYYY-MM-DD[T]HH:mm:ss")
        : "", //Good
      bookingNum: valuesPage?.bookingNum || "", //Good
      containerNum: valuesPage?.containerNum || "", //Good
      escalNum: valuesPage?.escalNum || "", //Good
      exporter: valuesPage?.exporter || "", //Good
      finalDestination: valuesPage?.finalDestination || "", //Good
      freightForwarderCode: valuesPage?.freightForwarderCode || "", //Good
      imoShip: valuesPage?.imoShip || "", //Good
      loadingPort: valuesPage?.loadingPort || "",
      operatorId: valuesPage?.operatorId || "", //Good
      travelNum: valuesPage?.travelNum || "", //Good
      consigneeCode: valuesPage?.consignataire || "",
      etat: valuesPage?.etat || "",
      page: pager.page,
      size: pager.size,
    };
    dispatch1(getBookingSearch(searchData));
  };
  useEffect(() => {
    getBookings();
  }, [pager]);

  const handleSearchClick = (values) => {
    setValuesPage(values);
    // debugger;
    const searchData = {
      bookingDate: values?.bookingDate
        ? moment(values?.bookingDate).format("YYYY-MM-DD[T]HH:mm:ss")
        : "", //Good
      bookingNum: values?.bookingNum || "", //Good
      containerNum: values?.containerNum || "", //Good
      escalNum: values?.escalNum || "", //Good
      exporter: values?.exporter || "", //Good
      finalDestination: values?.finalDestination || "", //Good
      freightForwarderCode: values?.freightForwarderCode || "", //Good
      imoShip: values.imoShip || "", //Good
      loadingPort: values?.loadingPort || "",
      operatorId: values?.operatorId || "", //Good
      travelNum: values?.travelNum || "", //Good
      consigneeCode: values?.consignataire || "", //Good
      etat: values?.etat || "",
      page: 0,
      size: 10,
    };
    listLoadingX = isLoadingBookingSearch;
    dispatch1(getBookingSearch(searchData));
    setIsSearch(true);
  };

  const handleCancelSearchClick = (resetForm, values) => {
    setNavire(null);
    setTransitaire(null);
    setOperateur(null);
    setPortDeChargement(null);
    setPortDestinataire(null);
    setClientExportateur(null);
    setIsSearch(false);
    listLoadingX = isLoadingBookingDefault;

    listActivites = listBookingDefault?.content;
    resetForm();
    setItems([]);
  };

  const handleOperateurSelect = (formik, row) => {
    setOperateur(row);
    formik.setFieldValue("operatorId", row?.description);
  };
  const handleNavireSelect = (formik, row) => {
    setNavire(row);
    console.log(row);
    formik.setFieldValue("imoShip", row?.name);
  };
  const handleTransitaireSelect = (formik, row) => {
    setTransitaire(row);
    formik.setFieldValue("transitaire", row?.code);
  };
  const handlePortDechargementSelect = (formik, row) => {
    setPortDeChargement(row);
    formik.setFieldValue("portDechargement", row?.description);
  };
  const handleDestinationSelect = (formik, row) => {
    setPortDestinataire(row);
    formik.setFieldValue("portDestinataire", row.description);
  };
  const handleClientSelect = (formik, row) => {
    setClientExportateur(row);
    formik.setFieldValue("exportateur", row?.description);
  };

  return (
    <PuiMainContainer>
      <PuiMainTitle
        icon={<HomeIcon />}
        title="Liste des Booking"
        trace={["Booking", "Gestion des booking"]}
      />
      <br />
      <PuiSection title="Paramètres de recherche">
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            handleSearchClick(values);
          }}
        >
          {(formik) => (
            <Form className={classes.form}>
              <PuiGrid container spacing={1}>
                <PuiGrid item sm={4} xs={6}>
                  <PuiTextField
                    id="bookingNum"
                    name="bookingNum"
                    label="N° Booking"
                  />
                </PuiGrid>

                <PuiGrid item sm={4} xs={6}>
                  <PuiTextField
                    id="escalNum"
                    name="escalNum"
                    label="N° Escale"
                  />
                </PuiGrid>

                {!JwtUtils.hasRole(ROLE_OPERATEUR_MANUTENTION) && (
                  <PuiGrid item sm={4} xs={6}>
                    <PuiSpecificReferentielField
                      formik={true}
                      getRenderedValue={(row) => row?.description}
                      getReturnedValue={(row) => row?.id}
                      label="Operateur de manutention"
                      name="operatorId"
                      searchKey="OPERATEURS_MANUTENTION"
                    />
                  </PuiGrid>
                )}
                <PuiGrid item sm={4} xs={6}>
                  <PuiTextField
                    id="containerNum"
                    name="containerNum"
                    label="N° conteneur"
                  />
                </PuiGrid>
                <PuiGrid item sm={4} xs={6}>
                  <PuiTextField
                    id="travelNum"
                    name="travelNum"
                    label="N° voyage"
                  />
                </PuiGrid>
                <PuiGrid item sm={4} xs={6}>
                  <PuiSpecificReferentielField
                    formik={true}
                    getRenderedValue={(row) => row?.nom}
                    getReturnedValue={(row) => row?.imo}
                    label="Navire"
                    name="imoShip"
                    onChange={function noRefCheck() {}}
                    onError={function noRefCheck() {}}
                    searchKey="NAVIRES"
                  />
                </PuiGrid>

                <PuiGrid item sm={4} xs={6}>
                  <PuiSpecificReferentielField
                    formik={true}
                    getRenderedValue={(row) => row?.description}
                    getReturnedValue={(row) => row?.code}
                    label="Transitaire"
                    name="freightForwarderCode"
                    onChange={function noRefCheck() {}}
                    onError={function noRefCheck() {}}
                    searchKey="TRANSITAIRES"
                  />
                </PuiGrid>

                <PuiGrid item sm={4} xs={6}>
                  <PuiDateTimeField
                    name="bookingDate"
                    id="bookingDate"
                    label="Date de réservation"
                  />
                </PuiGrid>

                <PuiGrid item sm={4} xs={6}>
                  <PuiSpecificReferentielField
                    formik={true}
                    getRenderedValue={(row) => row?.description}
                    getReturnedValue={(row) => row?.code}
                    label="Importateurs exportateurs"
                    name="exporter"
                    onChange={function noRefCheck() {}}
                    onError={function noRefCheck() {}}
                    searchKey="OPERATEURS_IMP_EXP"
                  />
                </PuiGrid>

                <PuiGrid item sm={4} xs={6}>
                  <PuiSpecificReferentielField
                    formik={true}
                    getRenderedValue={(row) => row?.description}
                    getReturnedValue={(row) => row?.description}
                    label="Premier port de déchargement	"
                    name="loadingPort"
                    onChange={function noRefCheck() {}}
                    onError={function noRefCheck() {}}
                    searchKey="LOCALITES"
                  />
                </PuiGrid>

                <PuiGrid item sm={4} xs={6}>
                  <PuiSpecificReferentielField
                    formik={true}
                    getRenderedValue={(row) => row?.description}
                    getReturnedValue={(row) => row?.description}
                    label="Destination finale	"
                    name="finalDestination"
                    onChange={function noRefCheck() {}}
                    onError={function noRefCheck() {}}
                    searchKey="LOCALITES"
                  />
                </PuiGrid>

                {JwtUtils.hasRole(ROLE_ADMIN) && (
                  <PuiGrid item sm={4} xs={6}>
                    <PuiSpecificReferentielField
                      formik={true}
                      getRenderedValue={(row) => row?.description}
                      getReturnedValue={(row) => row?.codeEDI}
                      label="Consignataire"
                      name="consignataire"
                      onChange={function noRefCheck() {}}
                      onError={function noRefCheck() {}}
                      searchKey="CONSIGNATAIRES"
                    />
                  </PuiGrid>
                )}
                <PuiGrid item sm={4} xs={6}>
                  <PuiSelect
                    formik={true}
                    getOptionLabel={(options) => options.text}
                    getOptionValue={(option) => option.id}
                    label="ÉTAT"
                    name="etat"
                    onChange={(event) => event.text}
                    options={[
                      {
                        id: 1,
                        text: "Nouveau",
                      },
                      {
                        id: 2,
                        text: "Modifié",
                      },
                      {
                        id: 3,
                        text: "Envoyé",
                      },
                      {
                        id: 4,
                        text: "Confirmé",
                      },
                    ]}
                  />
                </PuiGrid>
              </PuiGrid>
              <br></br>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid justify="flex-end">
                  <Grid item>
                    <Stack direction="row" spacing={2}>
                      <PuiButton
                        outlined
                        color="tertiary"
                        size="sm"
                        startIcon={<BackspaceIcon />}
                        onClick={handleCancelSearchClick.bind(
                          null,
                          formik.resetForm,
                          formik.values
                        )}
                      >
                        Effacer
                      </PuiButton>

                      <PuiButton
                        onClick={function noRefCheck() {}}
                        startIcon={<SearchIcon />}
                        type="submit"
                      >
                        Rechercher
                      </PuiButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </PuiSection>
      <PuiTable
        sx={{ marginTop: "15px" }}
        actions={[
          <PuiTableAction
            onClick={(row) => {
              navigate("/ConsultationBooking", {
                state: { id: row?.id },
              });
            }}
            title="Consulter"
          >
            <VisibilityOutlinedIcon />
          </PuiTableAction>,

          <PuiTableAction
            onClick={(row) => {
              navigate(`/creation/${row?.id}`);
            }}
            hideFunc={(row) =>
              row?.etat?.code == EtatBooking.ANNULE ||
              row?.etat?.code == EtatBooking.ENVOYE ||
              action === modes.consult
            }
          title="Modifier"
          >
            <ModeEditOutlinedIcon />
         </PuiTableAction>,
          <PuiTableAction
              onClick={(row) => {
                setHistoriqueProps({
                  ...historiqueProps,
                  open: true,
                  id: row?.id,
                });
              }}
              title="Historique"
          >
            <Inventory2OutlinedIcon />
          </PuiTableAction>,
        ]}
        columns={columns}
        rows={content}
        onPageChange={pageChangeHandler}
        onPageSizeChange={pageSizeChangeHandler}
        loading={isLoading}
        rowCount={totalElements}
        pageSize={pager.size}
        page={pager.page}
        paginationMode="server"

        // rows={content}
        //                       columns={columns}
        //                       loading={isLoading}
        //                       paginationMode="server"
        //                       rowCount={totalElements}
        //                       rowsPerPageOptions={[5, 10, 15, 20]}
        //                       pageSize={pager.size}
        //                       page={pager.page}
        //                       onPageChange={pageChangeHandler}
        //                       onPageSizeChange={pageSizeChangeHandler}
        //                       disableColumnMenu={true}
        //                       disableColumnResize={true}
        //                       disableSelectionOnClick
        //                       disableColumnFilter={true}
      />

      {historiqueProps.open && (
        <HistoriqueBooking
          open={historiqueProps.open}
          id={historiqueProps.id}
          handleClose={() =>
            setHistoriqueProps({ ...historiqueProps, open: false })
          }
        />
      )}
    </PuiMainContainer>
  );
}
