/* eslint-disable no-unused-vars */
import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { DialogActions } from "@mui/material";
import styles from "../../../assets/jss/cssPage.js";
import Button from "../../../common/components/CustomButtons/Button.js";
import { Stack } from "@mui/material";
import Primary from "../../../common/components/Typography/Primary.js";

const useStyles = makeStyles(styles);

export default function ShipDialog(props) {
  // Performs search request
  const { onClose, open, maxWidth, content, title } = props;
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);

  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };

  // eslint-disable-next-line
  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      open={open}
      maxWidth={maxWidth}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <Primary>
          <h4>{title}</h4>
        </Primary>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} round color="primary" size="sm" autoFocus>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
