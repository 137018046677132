import { container, title } from "./material.js";
import {
  primaryColor,
  successColor,
  dangerColor,
  defaultFont
} from "./material.js";

const basicsStyle = theme => ({
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    paddingTop: "5vh",
    color: "#FFFFFF",
    paddingBottom: "5vh",
  },
});

export default basicsStyle;
