import React from "react";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";

import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import Primary from "../../../common/components/Typography/Primary.js";
import Button from "../../../common/components/CustomButtons/Button.js";
import styles from "../../../assets/jss/cssPage.js";
import HeaderLinks from "../../../common/components/Header/FlagLinks.js";

const useStyles = makeStyles(styles);

export default function NotFoundPage(props) {
  const { ...rest } = props;
  const navigate = useNavigate();

  const classes = useStyles();

  return (
    <div>
      <Header
        absolute
        color="primary"
        brand="GAP MODULE"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div className={classes.pageHeader}>
        <div className={classes.container}>
          <Grid container justifyContent="center" sx={{ marginTop: 20 }}>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              <Primary>
                <h1> 404</h1>
              </Primary>
              <>
                <Stack direction="row" spacing={2}>
                  <Typography
                    gutterBottom
                    variant="overline"
                    color="text.primary"
                    component="div"
                    sx={{ marginTop: 1.5 }}
                  >
                    Page not found
                  </Typography>
                </Stack>
              </>
            </Stack>
          </Grid>
          <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
            <Stack direction="row" spacing={2}>
              <Button
                round
                color="primary"
                size="sm"
                onClick={() => navigate("/")}
              >
                ACCUEIL
              </Button>
            </Stack>
          </Grid>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
