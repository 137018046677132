import  instance from "../../AxiosInterceptor";
import config from '../../config';

export const getCodeIsoApi = (data) => {
  // console.log(data);
  return instance
    .get(
      config.url +
        '/ms-bookingApi/equipmentType/search?code=' +
        data.code +
        '&description=' +
        data.description +
        '&page=' +
        data.page +
        '&size=10'
    )
    .then((response) => {
      return response;
    })
    .then((err) => {
      return err;
    });
};
