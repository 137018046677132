const roles = {
  ADMIN: "ROLE_VUPORTUARIA_ADMIN",
  ADMIN_TARIF: "ROLE_VUPORTUARIA_ADMIN_TARIF",
  DOUANIER: "ROLE_VUPORTUARIA_ADUANA",
  AGENT_DOUANIER: "ROLE_VUPORTUARIA_AGENTE_ADUANERO",
  AGENT_AMSSNUR: "ROLE_VUPORTUARIA_AMSSNUR_AGENT",
  RESPONSABLE_AMSSNUR: "ROLE_VUPORTUARIA_AMSSNUR_RESPONSIBLE",
  VALIDATEUR_AMSSNUR: "ROLE_VUPORTUARIA_AMSSNUR_VALIDATOR",
  ANP: "ROLE_VUPORTUARIA_ANP",
  ANRT: "ROLE_VUPORTUARIA_ANRT",
  ASSISTANCE_RDV_IMPORT: "ROLE_VUPORTUARIA_ASSISTANCE_RDV_IMPORT",
  BANQUIER: "ROLE_VUPORTUARIA_BANCO",
  CAPITAINE: "ROLE_VUPORTUARIA_CAPITANIA",
  COMPAGNIE_AERIENNE: "ROLE_VUPORTUARIA_COMPAGNIE_AERIENNE",
  COMPAGNIE_AERIENNE_MANUTENTION:
    "ROLE_VUPORTUARIA_COMPAGNIE_AERIENNE_MANUTENTION",
  CONSIGNATAIRE: "ROLE_VUPORTUARIA_CONSIGNATARIO",
  DEMANDEUR_AGREMENT: "ROLE_VUPORTUARIA_DEMANDEUR_AGREMENT",
  DISTRICT: "ROLE_VUPORTUARIA_DISTRICT",
  DISTRICT_POLICE: "ROLE_VUPORTUARIA_DISTRICT_POLICE",
  STATISTIQUES: "ROLE_VUPORTUARIA_ESTADISTICAS",
  EXPORTATEUR: "ROLE_VUPORTUARIA_EXPORTADOR",
  FACTURES_CLIENTS: "ROLE_VUPORTUARIA_FACTURAS_CLIENTE",
  FACTURES_EMETTEUR: "ROLE_VUPORTUARIA_FACTURAS_EMISOR",
  DOSSIER: "ROLE_VUPORTUARIA_FICHERO",
  FREIGHT_FORWARDER: "ROLE_VUPORTUARIA_FREIGHT_FORWARDER",
  FRETFORWARD: "ROLE_VUPORTUARIA_FRETFORWARD",
  GENDARMERIE_ROYALE: "ROLE_VUPORTUARIA_GENDARMERIE_ROYALE",
  AGENT_IDM: "ROLE_VUPORTUARIA_IDM_AGENT",
  AGENT_IDM_AUTO_AFFECTEUR: "ROLE_VUPORTUARIA_IDM_AGENT_AUTO_AFFECTEUR",
  SERVICE_CENTRAL_IDM: "ROLE_VUPORTUARIA_IDM_SERVICE_CENTRAL",
  SUPERVISEUR_IDM: "ROLE_VUPORTUARIA_IDM_SUPERVISEUR",
  IMPORTATEUR: "ROLE_VUPORTUARIA_IMPORTADOR",
  MCE: "ROLE_VUPORTUARIA_MCE",
  MEAD: "ROLE_VUPORTUARIA_MEAD",
  OC: "ROLE_VUPORTUARIA_OC",
  ONCF: "ROLE_VUPORTUARIA_ONCF",
  OPERATEUR: "ROLE_VUPORTUARIA_OPERADOR",
  CONTROLE_ORGANISME: "ROLE_VUPORTUARIA_ORGANISMOCONTROL",
  AUTRE_DEPARTEMENT: "ROLE_VUPORTUARIA_OTHER_DEPARTMENT",
  PREAVIS: "ROLE_VUPORTUARIA_PREAVIS",
  PRENEUR: "ROLE_VUPORTUARIA_PRENEUR",
  PROCURATION_DOUANE: "ROLE_VUPORTUARIA_PROCURATION_DOUANE",
  PROTECTION_CIVILE: "ROLE_VUPORTUARIA_PROTECTION_CIVILE",
  SOCIETE_DESTRUCTION: "ROLE_VUPORTUARIA_SOCIETE_DESTRUCTION",
  SUPER_INSPECTEUR_DOUANIER: "ROLE_VUPORTUARIA_SUPER_INSPEC_ADUANERO",
  SUPER_INSPECTEUR_DOUANIER_TC: "ROLE_VUPORTUARIA_SUPER_INSPEC_ADUANERO_TC",
  TRANSITAIRE: "ROLE_VUPORTUARIA_TRANSITARIO",
  TRANSPORTEUR: "ROLE_VUPORTUARIA_TRANSPORTEUR",
  USER_MANAGER: "ROLE_VUPORTUARIA_USER_MANAGER",
  UTILISATEUR: "ROLE_VUPORTUARIA_USUARIO",
  VALIDATEUR_DAP: "ROLE_VUPORTUARIA_VALIDATEUR_DAP",
};

export default roles;
