import * as ActionsTypes from "../../../common/state/StateConstants";

/*--------------------------------------------------POST----------------------------------------------------*/

/***********Detail Booking***************** */
export const getDetailBooking = (payload) => {
    return{
        type:ActionsTypes.GET_DETAILBOOKING,
        payload
    }
  }
  
  export const getDetailBookingSuccess = (payload) => {
    return{
        type:ActionsTypes.GET_DETAILBOOKING_SUCCESS,
        payload
    }
  }
  
  export const getDetailBookingFailed = (payload) => {
    return{
        type:ActionsTypes.GET_DETAILBOOKING_FAIL,
        payload
    }
}

