import { takeLatest } from "redux-saga/effects";
import * as ActionsTypes from "../state/StateConstants";
import {
  getClientSearchSaga,
  getTransitaireSaga,
  getPremierPortSaga,
  getEscaleSaga,
} from "./commonSaga";

export default function* commonWatcher() {
  /************************************Get****************************** */

  yield takeLatest(ActionsTypes.GET_TRANSITAIRE, getTransitaireSaga);
  yield takeLatest(ActionsTypes.GET_CLIENTESEARCH, getClientSearchSaga);
  yield takeLatest(ActionsTypes.GET_PREMIERPORT, getPremierPortSaga);
  yield takeLatest(ActionsTypes.GET_NUMESCALE, getEscaleSaga);

  /************************************Delete**************************** */

  /************************************Update****************************** */
}
