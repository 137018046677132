import  instance from "../../AxiosInterceptor";import config from "../../config";

export const getClassImoApi = (data) => {
  return instance
    .get(
      config.url +
        "/ms-bookingApi/imoClassType/search?code=" +
        data.code +
        "&description=" +
        data.description +
        "&page=" +
        data.page +
        "&size=10"
    )
    .then((response) => {
      return response;
    })
    .then((err) => {
      return err;
    });
};
