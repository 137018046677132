import React from 'react';
import { takeLatest } from "redux-saga/effects";
import * as ActionsTypes from "../../../common/state/StateConstants";
import {getPortsaga,getPortSearchsaga,getOperateurSearchsaga, getBookingDefaultsaga, getPremierPortsaga, getNavireDefaultsaga, getNavirSearchsaga, getTransitaireDefaultsaga, getTransitaireSeachsaga, getClientSeachsaga, getBookingSeachsaga, getHistoriqueSeachsaga} from './GestionBookingSaga'

function* GestionBookingWatcher(props) {

yield takeLatest(ActionsTypes.GET_PORT, getPortsaga );
yield takeLatest(ActionsTypes.GET_PORTSEARCH, getPortSearchsaga );
yield takeLatest(ActionsTypes.GET_OPERATEURSEARCH, getOperateurSearchsaga );
yield takeLatest(ActionsTypes.GET_BOOKINGDEFAULT, getBookingDefaultsaga );
yield takeLatest(ActionsTypes.GET_PREMIERPORT, getPremierPortsaga );
yield takeLatest(ActionsTypes.GET_NAVIREDEFAULT, getNavireDefaultsaga );
yield takeLatest(ActionsTypes.GET_NAVIRESEARCH, getNavirSearchsaga );
yield takeLatest(ActionsTypes.GET_TRANSITAIREDEFAULT, getTransitaireDefaultsaga );
yield takeLatest(ActionsTypes.GET_TRANSITAIRESEARCH, getTransitaireSeachsaga );
yield takeLatest(ActionsTypes.GET_CLIENTESEARCH, getClientSeachsaga );
yield takeLatest(ActionsTypes.GET_BOOKINGSEARCH, getBookingSeachsaga );
yield takeLatest(ActionsTypes.GET_HISTORIQUESEARCH, getHistoriqueSeachsaga );


}

export default GestionBookingWatcher;