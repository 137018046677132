import  instance from "../../AxiosInterceptor";
import config from "../../config";

export const getNavireSearchApi = (data) => {
    return instance
      .get(config.url+"/ms-bookingApi/ship/search?imo="+data?.imo+"&name="+data?.name+"&page="+data?.page+"&size=10")
      .then((response) => {
        return response;
      })
      .then((err) => {
        return err;
      });
  };
  