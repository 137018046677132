export const getChipColorByEtatCode = (etatCode) => {
  switch (etatCode) {
    case "1":
      return "primary"; // Nouveau
    case "2":
      return "info"; // Mod
    case "3":
      return "warning"; // Envoyé
    case "4":
      return "success"; // Confirmé
    case "5":
      return "error"; // Annulé
    case "6":
      return "info"; // En-cours, considering no "warning" or "secondary"
    case "7":
      return "error"; // Rejeté
    case "8":
      return "error"; // Erreur
    default:
      return "primary";
  }
};

export const getChipColorByEtatCodePackage = (etatLibelle) => {
  switch (etatLibelle) {
    case "EN_EXPLOITATION":
      return "success";
    case "DELETED":
      return "error";
    case "DESACTIVE":
      return "error";
    default:
      return "primary";
  }
};

export const getChipColorByColorName = (etatCode) => {
  switch (etatCode) {
    case "Nouveau":
      return "primary"; // Nouveau
    case "Modification":
      return "warning"; // Mod
    case "Envoyé":
      return "warning"; // Envoyé
    case "Confirmé":
      return "success"; // Confirmé
    case "Annulé":
      return "error"; // Annulé
    case "En-cours":
      return "info"; // En-cours, considering no "warning" or "secondary"
    case "Rejeté":
      return "error"; // Rejeté
    case "Erreur":
      return "error"; // Erreur
    default:
      return "primary";
  }
};

export const getEtatDescriptionByCode = (etatCode) => {
  switch (etatCode) {
    case "1":
      return "Nouveau"; // Nouveau
    case "2":
      return "Modification"; // Mod
    case "3":
      return "Envoyé"; // Envoyé
    case "4":
      return "Confirmé"; // Confirmé
    case "5":
      return "Annulé"; // Annulé
    case "6":
      return "En-cours"; // En-cours, considering no "warning" or "secondary"
    case "7":
      return "Rejeté"; // Rejeté
    case "8":
      return "Erreur"; // Erreur
    default:
      return "Nouveau";
  }
};
