import { takeLatest } from "redux-saga/effects";
import * as ActionsTypes from "../../../common/state/StateConstants";
import {
  getImoClassSaga,
  getNumContainerSaga,
  getClassImoRealSaga,
  getOperateurNameSaga,
  getArmateurSaga,
  getCreerBookingSaga,
  getCreerAndSendBookingSaga,
  getBookingSaga,
  getArrivalNoticeSaga,
  updateBookingSaga,
  cancelBookingSaga,
  sendBookingSaga,
  updateAndSendBookingSaga,
} from "./creationBookingSaga";

export default function* creationBookingWatcher() {
  /************************************Get****************************** */
  yield takeLatest(ActionsTypes.GET_CODEISO, getImoClassSaga);
  yield takeLatest(ActionsTypes.GET_NUMCONTAINER, getNumContainerSaga);
  yield takeLatest(ActionsTypes.GET_CLASSIMO, getClassImoRealSaga);

  yield takeLatest(ActionsTypes.GET_OPERATEURNAME, getOperateurNameSaga);
  yield takeLatest(ActionsTypes.GET_ARMATEUR, getArmateurSaga);
  yield takeLatest(ActionsTypes.GET_CREATIONBOOKING, getCreerBookingSaga);
  yield takeLatest(
    ActionsTypes.GET_CREATIONSENDBOOKING,
    getCreerAndSendBookingSaga
  );
  yield takeLatest(ActionsTypes.GET_BOOKING, getBookingSaga);
  yield takeLatest(ActionsTypes.GET_ARRIVAL_NOTICE, getArrivalNoticeSaga);
  yield takeLatest(ActionsTypes.UPDATE_BOOKING, updateBookingSaga);
  yield takeLatest(ActionsTypes.CANCEL_BOOKING, cancelBookingSaga);
  yield takeLatest(ActionsTypes.SEND_BOOKING, sendBookingSaga);
  yield takeLatest(ActionsTypes.UPDATE_AND_SEND_BOOKING, updateAndSendBookingSaga);
  

  /************************************Delete**************************** */

  /************************************Update****************************** */
}
