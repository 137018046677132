import * as ActionsTypes from "../state/StateConstants";
export const arrayContentElementInitialState = {
  content: [],
  isLoading: false,
  isSuccessed: false,
  hasError: false,
  error: null,
};

const initialState = {
  transitaire: {
    isLoading: false,
    totalElements: 0,
    content: [],
    error: {},
  },
  client: {
    isLoading: false,
    totalElements: 0,
    content: [],
    error: {},
  },
  port: {
    isLoading: false,
    totalElements: 0,
    content: [],
    error: {},
  },
  searchListeEscale: { ...arrayContentElementInitialState },
};
export const updateState = (referentiel, oldState, newState) => {
  return {
    ...oldState,
    [referentiel]: {
      ...oldState[referentiel],
      ...newState,
    },
  };
};
const searchListeEscale = (state) => {
  return updateState("searchListeEscale", state, {
    ...arrayContentElementInitialState,
    isLoading: true,
  });
};

const searchListeEscaleSuccess = (state, payload) => {
  return updateState("searchListeEscale", state, {
    isLoading: false,
    isSuccessed: true,
    content: payload,
  });
};

const searchListeEscaleFail = (state, payload) => {
  return updateState("searchListeEscale", state, {
    error: payload,
    isLoading: false,
    hasError: true,
  });
};

const commonReducer = (state = initialState, action) => {

  switch (action.type) {
    /********************Case :Creation Booking(Code ISO)************** */

    /********************Case :Creation Booking(Num Escale)************** */

    case ActionsTypes.GET_NUMESCALE:
      return searchListeEscale(state);
    case ActionsTypes.GET_NUMESCALE_SUCCESS:
      return searchListeEscaleSuccess(state, action.payload);
    case ActionsTypes.GET_NUMESCALE_FAIL:
      return searchListeEscaleFail(state, action.payload);
    /********************Case :Common popUp Booking(Num Escale)************** */
    /********************TRANSITAIRE****************** */

    case ActionsTypes.GET_TRANSITAIRE:
      return {
        ...state,
        transitaire: { ...state.transitaire, isLoading: true },
      };

    case ActionsTypes.GET_TRANSITAIRE_SUCCESS:
      //   debugger;
      return {
        ...state,
        transitaire: {
          ...state.transitaire,
          isLoading: false,
          content: action.response.content,
          totalElements: action.response.totalElements,
        },
      };

    case ActionsTypes.GET_TRANSITAIRE_FAIL:
      return {
        ...state,
        transitaire: { ...state.transitaire, isLoading: false },
      };
    /********************CLIENT****************** */

    case ActionsTypes.GET_CLIENTESEARCH:
      return {
        ...state,
        client: { ...state.client, isLoading: true },
      };

    case ActionsTypes.GET_CLIENTESEARCH_SUCCESS:
      //   debugger;
      return {
        ...state,
        client: {
          ...state.client,
          isLoading: false,
          content: action.response.content,
          totalElements: action.response.totalElements,
        },
      };

    case ActionsTypes.GET_CLIENTESEARCH_FAIL:
      return {
        ...state,
        client: { ...state.client, isLoading: false },
      };

    /********************CLIENT****************** */

    case ActionsTypes.GET_PREMIERPORT:
      return {
        ...state,
        port: { ...state.port, isLoading: true },
      };

    case ActionsTypes.GET_PREMIERPORT_SUCCESS:
      //   debugger;
      return {
        ...state,
        port: {
          ...state.port,
          isLoading: false,
          content: action.response.content,
          totalElements: action.response.totalElements,
        },
      };

    case ActionsTypes.GET_PREMIERPORT_FAIL:
      return {
        ...state,
        port: { ...state.port, isLoading: false },
      };

    default:
      return state;
  }
};

export default commonReducer;
