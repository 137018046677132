import { PuiLoadingBackdrop, useAlert } from "@portnet/ui";
import axios from "axios";
import jwtDecode from "jwt-decode";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import config  from "./config";
import { DEFAULT_ERROR_MESSAGE} from "./constants/generalConstants";
import {
  NETWORK_ERROR_PAGE_ROUTE,
  NOT_FOUND_ERROR_PAGE_ROUTE,
  SERVER_ERROR_PAGE_ROUTE,
} from "./constants/routerConstants";



export const isTokenExpired = (parsedToken) => {
  return Boolean(
    parsedToken?.exp && moment.unix(parsedToken.exp).isBefore(moment())
  );
};

const instance = axios.create({
  baseURL: config.url,
});

const AxiosInterceptor = ({ children }) => {
  const alertDispatch = useAlert();
  const navigate = useNavigate();

  const [isAppReady, setIsAppReady] = React.useState(false);

  const tokenFromStore = useSelector((state) => {
    return state.general.token;
  });

  const getErrorStatusAndMessage = (error) => {
    const errorStatus =
      error?.response?.data?.status ?? error?.response?.status;
    const errorMessage = error?.response?.data?.message;
    return [errorStatus, errorMessage];
  };

  const handleError = (errorStatus, errorMessage) => {
    if (errorStatus) {
      switch (String(errorStatus)) {
        case "404":
          navigate(NOT_FOUND_ERROR_PAGE_ROUTE);
          break;
        case "500":
          navigate(SERVER_ERROR_PAGE_ROUTE);
          break;
        default:
          break;
      }
      alertDispatch("error", errorMessage ?? DEFAULT_ERROR_MESSAGE);
    } else {
      navigate(NETWORK_ERROR_PAGE_ROUTE);
    }
  };

  const configuration = (config) => {
    const token =
      new URLSearchParams(window.location.search).get("token") ??
      tokenFromStore;
    if (token) {
      if (!isTokenExpired(jwtDecode(token))) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        window.location.href = `${config.portnetBaseUrl}/app/seguridad/user/login.jsp`;
      }
    } else {
      console.warn(
        "No authentication token available. Request might not be authorized."
      );
    }
    return config;
  };

  React.useEffect(() => {
    instance.interceptors.request.use((config) => configuration(config));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenFromStore]);

  React.useEffect(() => {
    const responseInterceptor = instance.interceptors.response.use(
      (response) => response,
      (error) => {
        const [errorStatus, errorMessage] = getErrorStatusAndMessage(error);
        handleError(errorStatus, errorMessage);
        return Promise.reject(error);
      }
    );
    setIsAppReady(true);
    return () => {
      instance.interceptors.response.eject(responseInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAppReady ? children : <PuiLoadingBackdrop open={true} />;
};

export { AxiosInterceptor };

export default instance;
