import { PuiMainContainer } from "@portnet/ui";
import React from "react";
import { Outlet } from "react-router-dom";
import {AxiosInterceptor} from "../AxiosInterceptor";

const AppMainContainer = () => {
  return (
    <PuiMainContainer>
      <AxiosInterceptor>
        <Outlet />
      </AxiosInterceptor>
    </PuiMainContainer>
  );
};

export default AppMainContainer;
