import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: 'auto',
      borderRadius: 0,
    },
  },
}));

//
export default function CustomSnackbar(props) {
  const { open, message, close, severity, anchor, duration } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={anchor}
        open={open}
        autoHideDuration={duration}
        onClose={close}
      >
        <Alert onClose={close} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
