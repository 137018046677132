/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import DeleteForever from '@mui/icons-material/DeleteForever';
import Edit from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Tooltip from '@mui/material/Tooltip';
import HistoryIcon from '@mui/icons-material/History';
import CustomDialog from '../CustomDialog/CustomDialog.js';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import Button from "@mui/material/Button";
import Button from '../../../common/components/CustomButtons/Button';

// import Button from "../../../common/components/CustomButtons/Button";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from 'react-redux';
// import {getHistoriqueTraitement
//   getHistoriqueDemandes,
//   getHistoriqueTraitement,
//   getListeDemandes,
// } from "../../../modules/DemandeListe/state/ListeDemandesActions.js";
import moment from 'moment';
import Backdrop from '@mui/material/Backdrop';
import CustomInputField from '../CustomField/CustomInputField.js';
import { Formik } from 'formik';
import { getHistoriqueSearch } from '../../../modules/gestionBooking/state/GestionBookingAction.js';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

// import { updateMainLevee } from "../../../modules/consultationCaution/state/consultationCautionActions.js";

/************************************************ Makes Zebra Concept **************************************************  */
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#232f66',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useTableStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 0,
    backgroundColor: '#fafafa',
    '& .MuiTableCell-body': {
      fontSize: 15,
      paddingTop: 5,
      paddingBottom: 5,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    '& .MuiTableCell-head': {
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: 'unset',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
}));
/*********************************************************************************************************************** */

var beDeleted;

var openH = false;

const initialValues = {
  nom: '',
  ice: '',
  rib: '',
  montant: '',
  benificiaire: '',
};

var varFlagMl = false;
export default function CustomTable(props) {
  const {
    rows,
    columns,
    pagination,
    ops,
    entityRef,
    dis,
    func,
    entity,
    onEditModif,
    onEdit,
    onChangePage,
    pageNumber,
    loadinV,
    resultFlag,
    resultData,
    disOperation,
  } = props;
  const classes = useTableStyles();
  const [page, setPage] = React.useState(pageNumber ? pageNumber : 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState('');
  const [title, setTitle] = React.useState('');

  const [openDelete, setOpenDelete] = React.useState(false);
  const [itemDetailAcceuil, setItemDetailAcceuil] = React.useState();
  const [fullWidth, setFullWidth] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState('md');
  const [rowPop, setRowPop] = React.useState([]);
  const [openD, setOpenD] = React.useState(false);

  const [openDetail, setOpenDetail] = React.useState(false);

  const [detailCaution, setDetailCaution] = useState(null);
  const [valuesPage, setValuesPage] = useState(null);

  // const [flagML ,setflagML] = useState(false);

  const listHistoriqueSarch = useSelector((state) => state.gestionBooking.historiqueSearchData);
  const isLoadinglistHistoriqueSarch = useSelector(
    (state) => state.gestionBooking.isLoadingHistoriqueSearch
  );

  const dispatch = useDispatch();

  const listHistoriqueSearch = listHistoriqueSarch?.content;



  const handleClickOpenDetail = (row) => {
    setOpenDetail(true);
    setDetailCaution(row);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const dispatchX = props.dis;
  const functionX = props.func;
  const navigate = useNavigate();
  const handleClose = (value) => {
    setOpen(false);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
    setMaxWidth('md');
  };

  const handleChangePage = (event, newPage) => {
    if (onChangePage) {
      onChangePage(newPage);
    }
    setPage(newPage);
  };

  const onChangeHistoPage = (page) => {
    const searchData = {
      id: valuesPage?.id || '',
      page: page,
      size: 10,
    };
    dispatch(getHistoriqueSearch(searchData));
    // listLoadingX = isLoadingBookingSearch;
  };

  const handleChangeHistoPage = (event, newPage) => {
    if (onChangeHistoPage) {
      onChangeHistoPage(newPage);
    }
    setPage(newPage);
  };

  /********************************************************************************* */
  /**************************Supprimer un enregistrement****************************** */
  const onDeleteSelected = async (v) => {
    dispatchX(functionX(v.id));
    setOpen(true);
    setTitle('Suppression');
    setContent(entity + " qui correspond au 'code:" + v.code + "' a été bien supprimé !");
  };
  /********************************************************************************** */

  const handleClickOpenDelete = async (v) => {
    beDeleted = v;
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseConfime = async () => {
    onEdit(beDeleted);
    setOpenDelete(false);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onZoomSelectedEntity = (row) => {

    onEdit(row);
  };

  const popupDetail = (row) => {

    navigate('/ConsultationBooking', {
      state: { id: row.id },
    });
  };

  const onZoomSelected = (row) => {
    navigate('/demande/detail', {
      state: { id: row.id, email: row.email, num: row.numero },
    });
  };
  /**************************Editer un enregistrement****************************** */
  const onEditingSelected = async (v) => {
    onEditModif(v);
  };

  const handleClickDeleteLoc = async (v) => {
    beDeleted = v;
    setOpenDelete(true);
    // onEdit(v);
  };

  /**************************Remplir les champs de MainLevee****************************** */
  const onFillData = async (v) => {

    onEdit(v);
  };

  const handleOpenD = async (v) => {

    setValuesPage(v);
    const searchData = {
      id: v?.id || '',
      page: 0,
      size: 10,
    };
    dispatch(getHistoriqueSearch(searchData));
    // onEdit(v);

    openH = true;
    setOpenD(true);
    // demandeArray.map((item) => {
    //   if (v.numero == item.numDemande) {
    //     dispatch(getHistoriqueTraitement(item.id));
    //   }
    // });
  };

  const handleCloseD = () => {
    openH = false;
    setOpenD(false);
  };

  function createData(dateOperation, utilisateur, dateDebut, dateFin, zone, actions) {
    return { dateOperation, utilisateur, dateDebut, dateFin, zone, actions };
  }

  const rowsV1 = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 'Creation demande'),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 'Validation demande'),
    createData('Eclair', 262, 16.0, 24, 6.0, 'Validation ANP'),
    createData('Cupcake', 305, 3.7, 67, 4.3, 'Creation demande'),
    createData('Gingerbread', 356, 16.0, 49, 3.9, 'Validation DGSN'),
  ];

  return (
    <Paper className={classes.root} sx={{ borderRadius: 0 }}>
      {loadinV ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          //onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <TableContainer className={classes.container}>
          <Table aria-label="collapsible table">
            {resultFlag && (
              <>
                <caption>
                  <Typography
                    gutterBottom
                    variant="overline"
                    color="primary"
                    component="span"
                    sx={{
                      fontSize: 15,
                      fontWeight: 'medium',
                      color: 'primary',
                      textAlign: 'center',
                    }}
                  >
                    Nombre des conteneurs :
                    <span style={{ color: 'red' }}>{resultData?.nombre}</span>
                  </Typography>
                  <span style={{ padding: '5%' }}></span>
                  <Typography
                    gutterBottom
                    variant="overline"
                    color="primary"
                    component="span"
                    sx={{
                      fontSize: 15,
                      fontWeight: 'medium',
                      color: 'primary',
                      textAlign: 'center',
                    }}
                  >
                    Poids total des conteneurs :
                    <span style={{ color: 'red' }}>{resultData?.poidsTotal + ' KG'}</span>
                  </Typography>
                </caption>
              </>
              // <h1>
              //   Nombre des conteneurs: {resultData?.nombre} Poids total des
              //   conteneurs :{resultData?.poidsTotal + " KG"}
              // </h1>
            )}
            {/******************Header of the table*************** */}
            <TableHead>
              <TableRow sx={{ backgroundColor: '#232f66' }}>
                {columns?.map((column) => {
                  return (
                    <TableCell
                      key={column.id}
                      align={'center'}
                      style={{
                        minWidth: column.minWidth,
                        color: 'white',
                      }}
                    >
                      {column.label}
                    </TableCell>
                  );
                })}

                <TableCell key={1000} align="center" style={{ color: 'white' }}>
                  {ops && 'OPERATIONS'}
                </TableCell>
              </TableRow>
            </TableHead>
            {/***************************************************** */}

            {/**************Body of the table************************/}
            <TableBody>
              {!pagination
                ? rows?.map((row) => {
                    return (
                      <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        {props.columns?.map((column) => {
                          // console.log(column);
                          var value = row[column.id];

                          if (column.id === 'port') {
                            value = value?.libelleFR;
                          }

                          if (column.label === 'Navire') {
                            value = value?.name;
                          }

                          if (column.label === 'Date ETA') {
                            value = moment(value).format('DD/MM/YYYY hh:mm');
                          }

                          // if(value=="undefined"){
                          //   value="aze"
                          // }
                          // console.log(column.id)
                          // console.log(row)
                          if (column.id === 'banque') {
                            value = row?.banque?.libelle;
                          }

                          if (column.id === 'etat') {
                            value = row?.cautionEtat?.param_value;
                          }

                          if (column.id === 'mainLevee') {
                            value = row?.cautionMainLevee?.param_value;
                          }

                          return (
                            <StyledTableCell
                              key={column.id}
                              align={'center'}
                              sx={{ cursor: 'pointer' }}
                            >
                              {value}
                            </StyledTableCell>
                          );
                        })}

                        <StyledTableCell key={1000} align="center">
                          {props.ops === 'DetailBooking' && (
                            <>
                              <Tooltip title="Détail" arrow>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  color="primary"
                                  onClick={() => {
                                    if (entityRef == true) {
                                      // console.log(row)
                                      return popupDetail(row);
                                    }
                                    return onZoomSelected(row);
                                  }}
                                >
                                  <VisibilityIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                          {props.ops === 'DetailEtView' && (
                            <>
                              {row?.stateLabel !== 'Nouveau' &&
                                row?.stateLabel != 'Modifié' &&
                                row?.stateLabel !== 'Confirmé' && (
                                  <Tooltip title="Détail" arrow>
                                    <IconButton
                                      aria-label="expand row"
                                      size="small"
                                      color="primary"
                                      onClick={() => {
                                        if (entityRef == true) {
                                          // console.log(row)
                                          return popupDetail(row);
                                        }
                                        return onZoomSelected(row);
                                      }}
                                    >
                                      <VisibilityIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                )}

                              {/****************Editer ********************************/}
                              {(row?.stateLabel === 'Nouveau' ||
                                row?.stateLabel === 'Modifié' ||
                                row?.stateLabel === 'Confirmé') &&
                                row?.blocked === false && (
                                  <Tooltip title="Modifier" arrow>
                                    <IconButton
                                      style={{ padding: 8 }}
                                      aria-label="history row"
                                      size="small"
                                      onClick={() => onEditingSelected(row)}
                                    >
                                      <Edit fontSize="small" color="primary" />
                                    </IconButton>
                                  </Tooltip>
                                )}

                              {/* {row?.state==="Confirmé" &&
                        <Tooltip title="Modifier" arrow>
                        <IconButton
                          style={{ padding: 8 }}
                          aria-label="history row"
                          size="small"
                          onClick={() => onEditingSelected(row)}
                        >
                          <Edit fontSize="small" color="primary" />
                        </IconButton>
                      </Tooltip>
                        } */}
                              {/***************************************************** */}

                              {/***************Delete******************************** */}
                              <Tooltip title="Historique" arrow>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  color="primary"
                                  onClick={() => handleOpenD(row)}
                                >
                                  <HistoryIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              {/***************************************************** */}
                            </>
                          )}
                          {props.ops === 'Edit' && (
                            <>
                              {/****************Editer ********************************/}
                              <Tooltip title="Modifier" arrow>
                                <IconButton
                                  style={{ padding: 8 }}
                                  aria-label="history row"
                                  size="small"
                                  onClick={() => onEditingSelected(row)}
                                >
                                  <Edit fontSize="small" color="primary" />
                                </IconButton>
                              </Tooltip>
                              {/***************************************************** */}

                              {/***************Delete******************************** */}
                              <Tooltip title="Supprimer" arrow>
                                <IconButton
                                  style={{ padding: 8 }}
                                  aria-label="edit row"
                                  size="small"
                                  onClick={() => handleClickOpenDelete(row)}
                                >
                                  <DeleteForever fontSize="small" color="primary" />
                                </IconButton>
                              </Tooltip>
                              {/***************************************************** */}
                            </>
                          )}

                          {props.ops === 'ConteneurOperation' && (
                            <>
                              {/****************Editer ********************************/}
                              <Tooltip title="Modifier" arrow>
                                <IconButton
                                  style={{ padding: 8 }}
                                  aria-label="history row"
                                  size="small"
                                  disabled={props.disOperation}
                                  onClick={() => onEditingSelected(row)}
                                  color="primary"
                                >
                                  <Edit fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              {/***************************************************** */}

                              {/***************Delete******************************** */}
                              <Tooltip title="Supprimer" arrow>
                                <IconButton
                                  style={{ padding: 8 }}
                                  aria-label="edit row"
                                  size="small"
                                  onClick={() => handleClickDeleteLoc(row)}
                                  disabled={props.disOperation}
                                  color="primary"
                                >
                                  <DeleteForever fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              {/***************************************************** */}
                            </>
                          )}

                          {ops == 'View' && (
                            <Tooltip title="Voir +" arrow>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                color="primary"
                                onClick={() => {
                                  if (entityRef == true) {
                                    // console.log(row)
                                    return popupDetail(row);
                                  }
                                  return onZoomSelected(row);
                                }}
                                disabled={
                                  String(row?.cautionEtat?.param_value) != 'en cours' &&
                                  String(row?.cautionEtat?.param_value) != 'validé'
                                    ? true
                                    : false
                                }
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {/* {console.log(row?.cautionEtat?.param_value)} */}

                          {ops === 'Check' && entity === 'imoClass' && (
                            <Tooltip title="Sélectionner" arrow>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                color="primary"
                                onClick={() => onFillData(row)}
                                // onClick={() => {
                                //   if (entityRef == true) {
                                //     return popupDetail(row);
                                //   }
                                //   return onZoomSelected(row);
                                // }}
                              >
                                <CheckOutlinedIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}

                          {ops === 'Check' && entity === 'numContainer' && (
                            <Tooltip title="Sélectionner" arrow>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                color="primary"
                                onClick={() => onFillData(row)}
                                // onClick={() => {
                                //   if (entityRef == true) {
                                //     return popupDetail(row);
                                //   }
                                //   return onZoomSelected(row);
                                // }}
                              >
                                <CheckOutlinedIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}

                          {ops === 'Check' && entity === 'Operateur' && (
                            <Tooltip title="Sélectionner" arrow>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                color="primary"
                                onClick={() => onFillData(row)}
                                // onClick={() => {
                                //   if (entityRef == true) {
                                //     return popupDetail(row);
                                //   }
                                //   return onZoomSelected(row);
                                // }}
                              >
                                <CheckOutlinedIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {ops === 'Check' && entity === 'OperateurPort' && (
                            <Tooltip title="Sélectionner" arrow>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                color="primary"
                                onClick={() => onFillData(row)}
                                // onClick={() => {
                                //   if (entityRef == true) {
                                //     return popupDetail(row);
                                //   }
                                //   return onZoomSelected(row);
                                // }}
                              >
                                <CheckOutlinedIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}

                          {ops === 'Check' && entity === 'Caution' && (
                            <Tooltip title="Sélectionner" arrow>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                color="primary"
                                onClick={() => onFillData(row)}
                                // onClick={() => {
                                //   if (entityRef == true) {
                                //     return popupDetail(row);
                                //   }
                                //   return onZoomSelected(row);
                                // }}
                              >
                                <CheckOutlinedIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {ops === 'Check' && entity === 'Importateur' && (
                            <Tooltip title="Sélectionner" arrow>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                color="primary"
                                onClick={() => onFillData(row)}
                                // onClick={() => {
                                //   if (entityRef == true) {
                                //     return popupDetail(row);
                                //   }
                                //   return onZoomSelected(row);
                                // }}
                              >
                                <CheckOutlinedIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}

                          {ops === 'Search' && (
                            <Tooltip title="Sélectionner" arrow>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                color="primary"
                                onClick={() => onZoomSelected(row)}
                              >
                                <CheckOutlinedIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {ops === 'ViewHistoric' && (
                            <>
                              <Tooltip title="Voir +" arrow>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  color="primary"
                                  onClick={() => onZoomSelected(row)}
                                >
                                  <VisibilityIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Historique" arrow>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  color="primary"
                                  onClick={() => handleOpenD(row)}
                                >
                                  <HistoryIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                : rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
                    return (
                      <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        {props?.columns?.map((column) => {
                          var value = row[column?.id];
                          if (column.id === 'port') {
                            value = value?.libelleFR;
                          }

                          if (value == 'undefined') {
                            value = 'aze';
                          }

                          if (column.id === 'banque') {
                            value = value?.banque?.libelle;
                          }

                          if (column.id === 'etat') {
                            value = value?.cautionEtat?.param_value;
                          }

                          if (column.id === 'mainLevee') {
                            value = value?.cautionMainLevee?.param_value;
                          }

                          return (
                            <StyledTableCell
                              key={column.id}
                              align={'center'}
                              sx={{ cursor: 'pointer' }}
                            >
                              {value}
                            </StyledTableCell>
                          );
                        })}

                        <StyledTableCell key={1000} align="center">
                          {props.ops === 'Edit' && (
                            <>
                              <Tooltip title="Modifier" arrow>
                                <IconButton
                                  style={{ padding: 8 }}
                                  aria-label="history row"
                                  size="small"
                                  onClick={() => onEditingSelected(row)}
                                >
                                  <Edit fontSize="small" color="primary" />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Supprimer" arrow>
                                <IconButton
                                  style={{ padding: 8 }}
                                  aria-label="edit row"
                                  size="small"
                                  onClick={() => handleClickOpenDelete(row)}
                                >
                                  <DeleteForever fontSize="small" color="primary" />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                          {ops === 'Check' && entity === 'Importateur' && (
                            <Tooltip title="Sélectionner" arrow>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                color="primary"
                                onClick={() => onFillData(row)}
                                // onClick={() => {
                                //   if (entityRef == true) {
                                //     return popupDetail(row);
                                //   }
                                //   return onZoomSelected(row);
                                // }}
                              >
                                <CheckOutlinedIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {ops === 'Check' && entity === 'Caution' && (
                            <Tooltip title="Voir +" arrow>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                color="primary"
                                onClick={() => {
                                  if (entityRef == true) {
                                    return popupDetail(row);
                                  }
                                  return onZoomSelected(row);
                                }}
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}

                          {ops === 'View' && entity === 'Caution' && (
                            <Tooltip title="Voir +++" arrow>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                color="primary"
                                onClick={() => {
                                  if (entityRef == true) {
                                    return popupDetail(row);
                                  }
                                  return onZoomSelected(row);
                                }}
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {ops === 'View' && entity !== 'Caution' && (
                            <Tooltip title="Voir +" arrow>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                color="primary"
                                onClick={() => {
                                  if (entityRef == true) {
                                    return onZoomSelectedEntity(row);
                                  }
                                  return onZoomSelected(row);
                                }}
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {ops === 'Search' && (
                            <Tooltip title="Sélectionner" arrow>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                color="primary"
                                onClick={() => onZoomSelected(row)}
                              >
                                <CheckOutlinedIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {ops === 'ViewHistoric' && (
                            <>
                              <Tooltip title="Voir +" arrow>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  color="primary"
                                  onClick={() => onZoomSelected(row)}
                                >
                                  <VisibilityIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Historique" arrow>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  color="primary"
                                  onClick={() => onEditingSelected(row)}
                                >
                                  <HistoryIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      )}
      {props.totalElements ? (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={props.totalElements}
          rowsPerPage={rowsPerPage}
          page={props.pageNumber}
          onPageChange={handleChangePage}
        />
      ) : null}
      <CustomDialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        title={title}
        content={content}
      />
      <>
        {isLoadinglistHistoriqueSarch ? (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            //onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <Dialog
            open={openH}
            onClose={handleCloseD}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            //sx={{width:1000}}
            fullWidth={fullWidth}
            maxWidth="lg"
          >
            <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 'bold' }}>
              {'Historique de booking'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {itemDetailAcceuil?.adresseFR}
                <Paper className={classes.root} sx={{ borderRadius: 0 }}>
                  <TableContainer className={classes.container} component={Paper}>
                    <Table sx={{ minWidth: 800 }}>
                      <TableHead sx={{ marginTop: '13%', backgroundColor: '#232f66' }}>
                        <TableRow sx={{ backgroundColor: '#232f66' }}>
                          <TableCell align="left" width={'12%'} style={{ color: 'white' }}>
                            Nº d'échanges
                          </TableCell>
                          <TableCell align="left" width={'19%'} style={{ color: 'white' }}>
                            Fonction de la demande
                          </TableCell>
                          <TableCell align="left" style={{ color: 'white' }}>
                            Nom Message
                          </TableCell>
                          <TableCell align="left" style={{ color: 'white' }}>
                            Date
                          </TableCell>
                          <TableCell align="left" style={{ color: 'white' }}>
                            Nom utilisateur
                          </TableCell>
                          <TableCell align="left" style={{ color: 'white' }}>
                            Observations
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {listHistoriqueSearch?.map((histoRow) => (
                          <StyledTableRow
                            // key={row.name}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <StyledTableCell align="left" component="th" scope="row">
                              {histoRow.exchangeNumber}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {histoRow?.functionLabel}
                            </StyledTableCell>
                            <StyledTableCell align="left">{histoRow?.nameMessage}</StyledTableCell>
                            <StyledTableCell align="left">{histoRow?.exchangeDate}</StyledTableCell>
                            <StyledTableCell align="left">{histoRow?.userName}</StyledTableCell>
                            <StyledTableCell align="left">{histoRow?.observation}</StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>

                {listHistoriqueSarch?.totalElements ? (
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={listHistoriqueSarch?.totalElements}
                    rowsPerPage={rowsPerPage}
                    page={listHistoriqueSarch?.pageNumber}
                    onPageChange={handleChangeHistoPage}
                  />
                ) : null}
              </DialogContentText>

              {/* <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Switch checked={fullWidth} onChange={handleFullWidthChange} />
              }
              label="Full width"
            />
          </Box> */}
            </DialogContent>

            <DialogActions>
              <Button onClick={handleCloseD} round color="primary" size="sm" autoFocus>
                Fermer
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Suppression'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Etes-vous sure de faire cette opération ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseDelete}>
            Fermer
          </Button>
          <Button variant="contained" onClick={handleCloseConfime} color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDetail}
        onClose={handleCloseDetail}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Détail du caution N° ' + detailCaution?.numCaution}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running. */}
            <Formik
              initialValues={initialValues}
              //onSubmit={onSubmit}
              //innerRef={formRef}
              //validationSchema={validationSchema}
            >
              {({ errors, touched, setFieldValue, handleBlur, values, handleChange }) => (
                <form>
                  <Grid container xs={12} sm={12} md={12} justifyContent="center">
                    <Stack spacing={0}>
                      <Stack
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                      >
                        <Grid item xs={12} sm={6}>
                          <h4>CAUTION N° :</h4>
                        </Grid>
                        <Grid item xs={12} sm={6} justifyContent="center">
                          <CustomInputField
                            id="benificiaire"
                            name="benificiaire"
                            label=""
                            // fullWidth
                            variant="outlined"
                            value={detailCaution?.ice}
                            disabled={true}
                          />
                        </Grid>
                        {/* </Stack> */}

                        <div className={classes.space10} />
                        {/* <Stack
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems="center"
                                        direction="row"
                                    > */}
                        <Grid item xs={12} sm={6}>
                          <h4>BÉNIFICIAIRE :</h4>
                        </Grid>
                        <Grid item xs={12} sm={6} justifyContent="center">
                          <CustomInputField
                            id="benificiaire"
                            name="benificiaire"
                            label=""
                            // fullWidth
                            variant="outlined"
                            value={detailCaution?.benificiaire}
                            disabled={true}
                          />
                        </Grid>
                      </Stack>
                      <div className={classes.space10} />
                      <Stack
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                      >
                        <Grid item xs={12} sm={6}>
                          <h4>BANQUE :</h4>
                        </Grid>
                        <Grid item xs={12} sm={6} justifyContent="center">
                          <CustomInputField
                            id="benificiaire"
                            name="benificiaire"
                            label=""
                            // fullWidth
                            variant="outlined"
                            value={detailCaution?.banque?.libelle}
                            disabled={true}
                          />
                        </Grid>
                        {/* </Stack> */}
                        <div className={classes.space10} />
                        {/* <Stack
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems="center"
                                        direction="row"
                                    > */}
                        <Grid item xs={12} sm={6}>
                          <h4>MONTANT :</h4>
                        </Grid>
                        <Grid item xs={12} sm={6} justifyContent="center">
                          <CustomInputField
                            id="benificiaire"
                            name="benificiaire"
                            label=""
                            // fullWidth
                            variant="outlined"
                            value={detailCaution?.montant}
                            disabled={true}
                          />
                        </Grid>
                      </Stack>
                      <div className={classes.space10} />
                      <Stack
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                      >
                        <Grid item xs={12} sm={6}>
                          <h4>ÉTAT :</h4>
                        </Grid>
                        <Grid item xs={12} sm={6} justifyContent="center">
                          <CustomInputField
                            id="benificiaire"
                            name="benificiaire"
                            label=""
                            // fullWidth
                            variant="outlined"
                            value={detailCaution?.cautionEtat?.param_value}
                            disabled={true}
                          />
                        </Grid>
                        {/* </Stack> */}
                        <div className={classes.space10} />
                        {/* <Stack
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems="center"
                                        direction="row"
                                    > */}
                        <Grid item xs={12} sm={6}>
                          <h4>OPÉRATEUR :</h4>
                        </Grid>
                        <Grid item xs={12} sm={6} justifyContent="center">
                          <CustomInputField
                            id="operateur"
                            name="benificiaire"
                            label=""
                            // fullWidth
                            variant="outlined"
                            value={detailCaution?.operateur}
                            disabled={true}
                          />
                        </Grid>
                      </Stack>
                      <div className={classes.space10} />
                      <Stack
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                      >
                        <Grid item xs={12} sm={6}>
                          <h4>MAIN LEVÉE :</h4>
                        </Grid>
                        <Grid item xs={12} sm={6} justifyContent="center">
                          <CustomInputField
                            id="benificiaire"
                            name="benificiaire"
                            label=""
                            // fullWidth
                            variant="outlined"
                            value={detailCaution?.cautionMainLevee?.param_value}
                            disabled={true}
                          />
                        </Grid>
                        {/* </Stack> */}
                        <div className={classes.space10} />
                        {/* {console.log(detailCaution)} */}
                        <Grid item xs={12} sm={6}>
                          <h4>RIB :</h4>
                        </Grid>
                        <Grid item xs={12} sm={6} justifyContent="center">
                          <CustomInputField
                            id="benificiaire"
                            name="benificiaire"
                            label=""
                            // fullWidth
                            variant="outlined"
                            value={detailCaution?.rib}
                            disabled={true}
                          />
                        </Grid>
                      </Stack>
                      <div className={classes.space10} />
                      <Stack
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                      >
                        <Grid item xs={12} sm={6}>
                          <h4>ICE :</h4>
                        </Grid>
                        <Grid item xs={12} sm={6} justifyContent="center">
                          <CustomInputField
                            id="benificiaire"
                            name="benificiaire"
                            label=""
                            // fullWidth
                            variant="outlined"
                            value={detailCaution?.ice}
                            disabled={true}
                          />
                        </Grid>
                        {/* </Stack> */}
                        <div className={classes.space10} />
                        <Grid item xs={12} sm={6}></Grid>
                        <Grid item xs={12} sm={6} justifyContent="center"></Grid>
                      </Stack>
                      <div className={classes.space10} />

                      <Stack
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                      >
                        <Grid item xs={12} sm={8} justifyContent="center">
                          <h3>VALIDER LA MAIN LEVÉE :</h3>
                        </Grid>
                        <Grid item xs={12} sm={3} justifyContent="center">
                          {' '}
                          <Button
                            variant="contained"
                            color="error"
                            size="sm"
                            // onClick={()=>handleRefuserML(detailCaution)}
                            // // onClick={handleCancelSearchClick.bind(null,resetForm)}
                            // // disabled={isLoadingCaution}
                          >
                            Réfuser
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={3} justifyContent="center">
                          <Button
                            variant="contained"
                            color="success"
                            size="sm"
                            // onClick={()=>handleValiderML(detailCaution)}
                            // // onClick={handleCancelSearchClick.bind(null,resetForm)}
                            // // disabled={isLoadingCaution}
                          >
                            Valider
                          </Button>
                        </Grid>
                      </Stack>
                    </Stack>
                  </Grid>
                </form>
              )}
            </Formik>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetail} autoFocus>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
