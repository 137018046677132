import  instance from "../../AxiosInterceptor";
import config from '../../config';

export const creerBookingApi = (payload) => {
  return instance.post(config.url + '/ms-bookingApi/booking/save', payload)
    .then((response) => {
      return response;
    })
    .then((err) => {
      return err;
    });
};

export const updateBooking = (payload) => {
    return instance.put(config.url + '/ms-bookingApi/booking/update', payload);
};

export const updateAndSendBooking = (payload) => {
  return instance.put(config.url + '/ms-bookingApi/booking/updateAndSend', payload);
};


