import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@mui/styles";
import Grid from "@mui/material/Grid";
import styles from "../../../assets/jss/cssPage.js";
import Primary from "../../../common/components/Typography/Primary.js";
import CustomSnackbar from "../../../common/components/CustomSnackbar/CustomSnackbar.js";
import * as Yup from "yup";
import {useLocation, useNavigate} from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {Form, Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {getDetailBooking} from "../state/detailBookingAction";
import CollapsibleTable from "../../../common/components/CustomTable/CollapsibleTable.js";
import {
  PuiChip,
  PuiGrid,
  PuiMainContainer,
  PuiMainTitle,
  PuiNavigation,
  PuiRadioGroup,
  PuiRadioItem,
  PuiSection,
  PuiTextField,
} from "@portnet/ui";
import HomeIcon from "@mui/icons-material/Home";
import {getChipColorByColorName} from "../../gestionBooking/ui/colors.js";
import {Stack} from "@mui/material";

const useStyles = makeStyles(styles);

const columns = [
  {
    id: "numConteneur",
    label: "N° Conteneur",
    minWidth: 60,
    fontWeight: "bold",
  },
  { id: "nombre", label: "Nombre", minWidth: 60, fontWeight: "bold" },
  { id: "codeIso", label: "Code ISO", minWidth: 60, fontWeight: "bold" },
];

const files = [{ name: "Copie_fichier.pdf" }];
const typeEntity = [
  { id: 1, libelle: "EA" },
  { id: 2, libelle: "DGSN" },
  { id: 3, libelle: "ANP" },
];
var flagB1 = 0;
var flagB2 = false;
var flagB3 = false;
var flagB4 = false;
var globalVariable = null;
var dialogBool = false;
var iter = 0;

export default function EntiteAccueil(props) {
  const [valueRadio, setValueRadio] = React.useState("");

  const handleChangeRadio = (event) => {
    setValueRadio(event.target.value);
  };

  const [valueTab, setValueTab] = React.useState("1");

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const location = useLocation();

  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const dispatchDetail = useDispatch();
  const dispatchClose = useDispatch();
  const dispatch1 = useDispatch();
  const navigate = useNavigate();
  const [funcValueFile, setFuncValueFile] = useState([]);
  const [allCin, setAllCin] = useState();
  const formikRef = useRef();

  const [dataY, setDataY] = useState({
    persons: [
      {
        nomFR: "",
        prenomFR: "",
        address: "",

        cin: "",
        dateExpirationCIN: "",
        domiciliationCinFR: "",
        email: "",
        cinPJ: "",
      },
    ],

    mandataire: [
      {
        adresseAR: "",
        adresseFR: "",
        cin: "",
        dateExpirationCIN: "",
        domiciliationCinAR: "",
        domiciliationCinFR: "",
        email: "",
        nomAR: "",
        nomFR: "",
        prenomAR: "",
        prenomFR: "",
        representantSocieteAR: "",
        representantSocieteFR: "",

        cinPJ: "",
      },
    ],

    entityAcceuil: {
      adresseFR: "",
      centreRC: "",
      dateCreation: "",
      dateModification: "",
      email: "",
      fax: "",
      identifiant: "",
      identifiantFiscal: "",
      motifRefus: "",
      numeroICE: "",
      numeroRC: "",
      raisonSocialeFR: "",
      statutEntite: "",
      taxeProfessionnelle: "",
      telephone: "",
      typeEntite: "",
      port: "",
      zones: [],
      typeActivite: "",
      typeOperation: "",

      raisonSocialPJ: "",
      numeroRCPJ: "",
      numeroICEPJ: "",
      identifiantFiscalPJ: "",
      taxeProfessionellePJ: "",
      logoPJ: "",
    },
  });

  const error = useSelector((state) => state?.entiteAcceuil?.error);
  const success = useSelector((state) => state?.entiteAcceuil?.success);
  var data = useSelector((state) => state?.entiteAcceuil?.itemSaved);
  const failed = useSelector((state) => state?.entiteAcceuil?.failed);
  const apiWaiting = useSelector((state) => state?.entiteAcceuil?.isLoading);

  const detailBookingData = useSelector(
    (state) => state?.detailBooking?.detailBookingData
  );
  const isLoadingdetailBooking = useSelector(
    (state) => state?.detailBooking?.isLoadingDetailBooking
  );

  var listActivities = detailBookingData?.data;
  var operateurValue = "";
  // if (listActivities?.typeOperatorMap) {
  //   console.log(Object?.values(listActivities?.typeOperatorMap));

  //   if (Array.isArray(listActivities?.typeOperatorMap)) {
  //     listActivities?.typeOperatorMap?.map((operator) => {
  //       if (listActivities?.typeOperatorId == Object.keys(operator)) {
  //         var operateurValue = Object.values(operator);
  //       }
  //     });
  //   } else {
  //     var operateurValue = Object?.values(listActivities?.typeOperatorMap);
  //   }
  // }
  // console.log(listActivities);
  console.log(listActivities?.typeOperatorList);
  if (listActivities?.typeOperatorList) {
    console.log(Object?.values(listActivities?.typeOperatorList));

    if (Array.isArray(listActivities?.typeOperatorList)) {
      listActivities?.typeOperatorList?.map((operator) => {
        operateurValue = operator?.label;
        console.log(operateurValue);
      });
    } else {
      operateurValue = Object?.values(listActivities?.typeOperatorList);
    }
  }

  console.log(operateurValue);

  if (success == true) {
    dialogBool = true;
    flagB3 = false;
    flagB4 = false;
  }

  Yup.addMethod(Yup.array, "unique", function (message, mapper = (a) => a) {
    return this.test("unique", message, function (list) {
      return list.length === new Set(list.map(mapper)).size;
    });
  });

  const cycleOptions = [
    { label: "Transbordement", value: "transbordement" },
    { label: "Export", value: "export" },
  ];

  const validationSchemaOne = Yup.object({
    entityAcceuil: Yup.object({
      adresseFR: Yup.string()
        .required("Ce champ est obligatoire")
        .matches(
          /^[A-Za-z0-9 _éèô]*[A-Za-z0-9éèô][A-Za-z0-9 _éèô]*$/,
          "Seuls les alphabets sont autorisés pour ce champ"
        ),
      centreRC: Yup.string()
        .required("Ce champ est obligatoire")
        .matches(
          /^[A-Za-z0-9 _éèô]*[A-Za-z0-9éèô][A-Za-z0-9 _éèô]*$/,
          "Seuls les alphabets sont autorisés pour ce champ"
        ),
      email: Yup.string()
        .email("Verifier le format de mail")
        .required("Ce champ est obligatoire"),
      fax: Yup.string()
        .required("Ce champ est obligatoire")
        .matches(
          /^[A-Za-z0-9 _éèô]*[A-Za-z0-9éèô][A-Za-z0-9 _éèô]*$/,
          "Seuls les alphabets sont autorisés pour ce champ"
        ),
      identifiantFiscal: Yup.string()
        .required("Ce champ est obligatoire")
        .matches(
          /^[A-Za-z0-9 _éèô]*[A-Za-z0-9éèô][A-Za-z0-9 _éèô]*$/,
          "Seuls les alphabets sont autorisés pour ce champ"
        ),
      numeroICE: Yup.string()
        .required("Ce champ est obligatoire")
        .matches(/^\d+$/, "Seuls les chiffres sont autorisés pour ce champ"),
      numeroRC: Yup.string()
        .required("Ce champ est obligatoire")
        .matches(/^\d+$/, "Seuls les chiffres sont autorisés pour ce champ"),
      raisonSocialeFR: Yup.string()
        .required("Ce champ est obligatoire")
        .matches(
          /^[A-Za-z0-9 _éèô]*[A-Za-z0-9éèô][A-Za-z0-9 _éèô]*$/,
          "Seuls les alphabets sont autorisés pour ce champ"
        ),
      taxeProfessionnelle: Yup.string()
        .required("Ce champ est obligatoire")
        .matches(
          /^[A-Za-z0-9 _éèô]*[A-Za-z0-9éèô][A-Za-z0-9 _éèô]*$/,
          "Seuls les alphabets sont autorisés pour ce champ"
        ),
      telephone: Yup.string()
        .required("Ce champ est obligatoire")
        .matches(
          /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
          "Le numéro de téléphone n'est pas valide"
        ),
      typeEntite: Yup.string().required("Ce champ est obligatoire"),
      zones: Yup.array().min(1, "Saisiser au moins une zone"),
      port: Yup.object().required("Ce champ est obligatoire"),
      typeActivite: Yup.object().required("Ce champ est obligatoire"),
      typeOperation: Yup.object().required("Ce champ est obligatoire"),
      raisonSocialPJ: Yup.string().required("Ce fichier est obligatoire"),
      numeroRCPJ: Yup.string().required("Ce fichier est obligatoire"),
      numeroICEPJ: Yup.string().required("Ce fichier est obligatoire"),
      identifiantFiscalPJ: Yup.string().required("Ce fichier est obligatoire"),
      taxeProfessionellePJ: Yup.string().required("Ce fichier est obligatoire"),
      logoPJ: Yup.string().required("Ce fichier est obligatoire"),
    }),
  });

  const validationSchemaTwo = Yup.object({
    mandataire: Yup.array(
      Yup.object({
        adresseFR: Yup.string()
          .required("Ce champ est obligatoire")
          .matches(
            /^[A-Za-z0-9 _éèô]*[A-Za-z0-9éèô][A-Za-z0-9 _éèô]*$/,
            "Seuls les alphabets sont autorisés pour ce champ"
          ),
        dateExpirationCIN: Yup.string().required("Ce champ est obligatoire"),
        email: Yup.string()
          .email("Verifier le format de mail")
          .required("Ce champ est obligatoire"),
        nomFR: Yup.string()
          .required("Ce champ est obligatoire")
          .matches(
            /^[A-Za-z0-9 _éèô]*[A-Za-z0-9éèô][A-Za-z0-9 _éèô]*$/,
            "Seuls les alphabets sont autorisés pour ce champ"
          ),
        prenomFR: Yup.string()
          .required("Ce champ est obligatoire")
          .matches(
            /^[A-Za-z0-9 _éèô]*[A-Za-z0-9éèô][A-Za-z0-9 _éèô]*$/,
            "Seuls les alphabets sont autorisés pour ce champ"
          ),
        representantSocieteFR: Yup.string()
          .required("Ce champ est obligatoire")
          .matches(
            /^[A-Za-z0-9 _éèô]*[A-Za-z0-9éèô][A-Za-z0-9 _éèô]*$/,
            "Seuls les alphabets sont autorisés pour ce champ"
          ),
        cinPJ: Yup.string().required("Ce fichier est obligatoire"),
        domiciliationCinFR: Yup.string()
          .required("Ce champ est obligatoire")
          .matches(
            /^[A-Za-z0-9 _éèô]*[A-Za-z0-9éèô][A-Za-z0-9 _éèô]*$/,
            "Seuls les alphabets sont autorisés pour ce champ"
          ),
        cin: Yup.string()
          .required("Ce champ est obligatoire")
          .matches(
            /^[A-Za-z0-9 _éèô]*[A-Za-z0-9éèô][A-Za-z0-9 _éèô]*$/,
            "Seuls les alphabets sont autorisés pour ce champ"
          ),
      })
    ),
  });

  const duplicateNameCheck = (list, cinValue) => {
    let dupNumber = 0;

    for (let i = 0; i < list.length; i++) {
      if (cinValue === list[i].cin) {
        dupNumber++;
        if (dupNumber == 2) {
          return false;
        }
      }
    }
    return true;
  };

  const headersSchema = Yup.object().shape({
    adminEmails: Yup.array().of(Yup.string()).unique("email must be unique"),
  });
  const validationSchemaThree = Yup.object({
    persons: Yup.array(
      Yup.object({
        nomFR: Yup.string()
          .required("Ce champ est obligatoire")
          .matches(
            /^[A-Za-z0-9 _éèô]*[A-Za-z0-9éèô][A-Za-z0-9 _éèô]*$/,
            "Seuls les alphabets sont autorisés pour ce champ"
          ),
        prenomFR: Yup.string()
          .required("Ce champ est obligatoire")
          .matches(
            /^[A-Za-z0-9 _éèô]*[A-Za-z0-9éèô][A-Za-z0-9 _éèô]*$/,
            "Seuls les alphabets sont autorisés pour ce champ"
          ),
        address: Yup.string()
          .required("Ce champ est obligatoire")
          .matches(
            /^[A-Za-z0-9 _éèô]*[A-Za-z0-9éèô][A-Za-z0-9 _éèô]*$/,
            "Seuls les alphabets sont autorisés pour ce champ"
          ),

        cin: Yup.string()
          .required("Ce champ est obligatoire")
          .matches(
            /^[A-Za-z0-9 _éèô]*[A-Za-z0-9éèô][A-Za-z0-9 _éèô]*$/,
            "Seuls les alphabets sont autorisés pour ce champ"
          )
          .test("Unique", "Le cin saisie déja existe ", (cinValue) => {
            return duplicateNameCheck(allCin, cinValue);
          }),
        dateExpirationCIN: Yup.string().required("Ce champ est obligatoire"),
        domiciliationCinFR: Yup.string().required("Ce champ est obligatoire"),
        email: Yup.string()
          .email("Verifier le format de mail")
          .required("Ce champ est obligatoire"),
        cinPJ: Yup.string().required("Ce fichier est obligatoire"),
      })
    ),
  });

  const onSubmit = (values) => {
    if (step == 0) {
      if (values.entityAcceuil.statutEntite === "") {
        values.entityAcceuil.statutEntite = 0;
      }

      values.entityAcceuil.dateCreation = [
        Date.now().toString().slice(0, 10),
        ".",
        Date.now().toString().slice(10),
      ].join("");
      setDataY((prev) => ({ ...prev, ...values }));
    }

    if (step == 1) {
      setDataY((prev) => ({ ...prev, ...values }));
    }

    if (step != 2) {
      handleNextStep(values);
    }

    if (step == 2) {
      const mandataireEntites = values.mandataire;
      const utilisateurEntites = values.persons;
      const resultData = {
        ...values.entityAcceuil,
        mandataireEntites,
        utilisateurEntites,
      };

      setDataY((prev) => ({ ...prev, ...values }));
    }

    /******************************************************************************************************************************************* */
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    navigate("/");
  };

  const handleCloseFailed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dialogBool = false;
  };

  const [adress, setAdress] = React.useState("");
  const handlChange = (e) => {
    setAdress(e.target.value);
  };

  const [step, setStep] = React.useState(0);

  const [errorZone, setErrorZone] = useState("");

  useEffect(() => {
    const searchData = {
      id: location?.state?.id,
    };
    dispatchDetail(getDetailBooking(searchData));
  }, []);

  setTimeout(function () {
    setCardAnimation("");
  }, 300);

  const steps = ["INFORMATION BOOKING", "LISTE DES CONTENEURS"];

  const classes = useStyles();
  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleNextStep = () => {
    setStep(step < 1 ? step + 1 : 1);
  };
  const handlePrevStep = (values) => {
    setDataY((prev) => ({ ...prev, ...values }));
    setStep(step > 0 ? step - 1 : 0);
  };

  const { ...rest } = props;

  function readFileDataAsBase64(e) {
    const file = e.target.files[0];

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsDataURL(file);
    });
  }

  const handlePrecedent = () => {
    navigate("/gestion");
  };
  console.log(formikRef?.current?.values);

  return (
    <>
      <div>
        <PuiMainContainer>
          <PuiMainTitle
            icon={<HomeIcon />}
            title="Liste des Booking"
            trace={["Booking", "Consultation Booking"]}
          />
          <PuiNavigation
            onRetour={() => handlePrecedent()}
            retour
          ></PuiNavigation>

          <Stack direction="row" alignItems="center" spacing={1}>
            <Stack xs={1}>
              <Primary>
                <h3 className={classes.title}> État </h3>
              </Primary>
            </Stack>
            <Stack xs={4}>
              <PuiChip
                color={getChipColorByColorName(listActivities?.stateLabel)}
                fullWidth
              >
                {" "}
                {listActivities?.stateLabel ?? "-"}
              </PuiChip>
            </Stack>
          </Stack>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isLoadingdetailBooking}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <>
            <PuiSection title="Données du Booking">
              {handleCloseFailed()}

              <Formik
                initialValues={dataY}
                onSubmit={onSubmit}
                innerRef={formikRef}
              >
                {({ values, setFieldValue }) => (
                  <Form className={classes.form}>
                    <PuiGrid container spacing={1}>
                      <PuiGrid item xs={12} sm={4} md={4}>
                        <PuiTextField
                          id="bookingNum"
                          name="bookingNum"
                          label="N° Booking"
                          value={listActivities?.numeroBooking || ""}
                          disabled
                        />
                      </PuiGrid>
                      <PuiGrid item xs={12} sm={4} md={4}>
                        <PuiTextField
                          id="escaleNum"
                          name="escaleNum"
                          label="N° escale"
                          disabled
                          value={listActivities?.escaleNum || ""}
                        />
                      </PuiGrid>
                      <PuiGrid item xs={12} sm={4} md={4}>
                        <PuiTextField
                          id="operatorId"
                          name="operatorId"
                          label="Opérateur"
                          disabled
                          value={operateurValue || ""}
                        />
                      </PuiGrid>

                      <PuiGrid item xs={12} sm={4} md={4}>
                        <PuiTextField
                          id="travelNum"
                          name="travelNum"
                          label="N° voyage"
                          disabled
                          value={listActivities?.travelNum || ""}
                        />
                      </PuiGrid>
                      <PuiGrid item xs={12} sm={4} md={4}>
                        <PuiTextField
                          id="imoShip"
                          name="imoShip"
                          label="Navire"
                          disabled
                          value={listActivities?.travelNum || ""}
                        />
                      </PuiGrid>
                      <PuiGrid item xs={12} sm={4} md={4}>
                        <PuiTextField
                          name="bookingDate"
                          id="bookingDate"
                          disabled
                          label="DATE DE RÉSERVATION	"
                          value={listActivities?.reservationDate || ""}
                        />
                      </PuiGrid>
                      <PuiGrid item xs={12} sm={4} md={4}>
                        <PuiTextField
                          name="bookingDateLimite"
                          id="bookingDateLimite"
                          label="DATE LIMITE DU BOOKING"
                          disabled
                          value={listActivities?.limitDate || ""}
                        />
                      </PuiGrid>
                      <PuiGrid item xs={12} sm={4} md={4}>
                        <PuiTextField
                          id="loadingPort"
                          name="loadingPort"
                          label="Premier port de déchargement"
                          disabled
                          value={listActivities?.firstUnloadingPort || ""}
                        />
                      </PuiGrid>
                      <PuiGrid item xs={12} sm={4} md={4}>
                        <PuiTextField
                          id="finalDestination"
                          name="finalDestination"
                          label="Destination finale"
                          disabled
                          value={listActivities?.finalDestination || ""}
                        />
                      </PuiGrid>
                      <PuiGrid item xs={12} sm={4} md={4}>
                        <PuiTextField
                          name="ARMATEUR"
                          id="ARMATEUR"
                          disabled
                          label="ARMATEUR"
                          value={listActivities?.armateurLabel || ""}
                        />
                      </PuiGrid>
                      <PuiGrid item xs={12} sm={4} md={4}>
                        <PuiTextField
                          name="armementCode"
                          id="armementCode"
                          disabled
                          label="Code Armement B/L"
                          value={listActivities?.codeAbonnementBl || ""}
                        />
                      </PuiGrid>
                      <PuiGrid item xs={12} sm={4} md={4}>
                        <PuiTextField
                          id="exporter"
                          name="exporter"
                          label="Client (exportateur/FF)"
                          disabled
                          value={listActivities?.exporter || ""}
                        />
                      </PuiGrid>

                      <PuiGrid item xs={12} sm={4} md={4}>
                        <PuiTextField
                          id="freightForwarderCode"
                          name="freightForwarderCode"
                          label="Code Transitaire"
                          disabled
                          value={listActivities?.freightForwarderCode || ""}
                        />
                      </PuiGrid>

                      <PuiGrid item xs={12} sm={4} md={4}>
                        <PuiTextField
                          name="ETA"
                          id="ETA"
                          disabled
                          label="ETA"
                          value={listActivities?.etaDate || ""}
                        />
                      </PuiGrid>

                      <PuiGrid item xs={12} sm={4} md={4}>
                        <PuiTextField
                          name="ETD"
                          id="ETD"
                          disabled
                          label="ETD"
                          value={listActivities?.etdDate || ""}
                        />
                      </PuiGrid>
                      <PuiGrid item xs={12} sm={4} md={4}>
                        <PuiRadioGroup
                          label="Cycle"
                          name="etatConteneur"
                          disabled
                          row
                          value={listActivities?.cycleBooking || ""}
                        >
                          {cycleOptions.map((elm) => (
                            <>
                              <div>{console.log(elm)}</div>
                              <PuiRadioItem
                                label={elm.label}
                                value={elm.value}
                              />
                            </>
                          ))}
                        </PuiRadioGroup>
                      </PuiGrid>
                      {/* <Grid item xs={12} sm={4} md={4} sx={{ marginTop: '4px' }}>
                        <FormControl>
                          <FormLabel id="demo-controlled-radio-buttons-group">Cycle</FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={listActivities?.cycleBooking || ''}
                            onChange={handleChangeRadio}
                          >
                            <FormControlLabel value="export" control={<Radio />} label="Export" />
                            <FormControlLabel
                              value="transbordement"
                              control={<Radio />}
                              label="Transbordement"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid> */}
                    </PuiGrid>
                  </Form>
                )}
              </Formik>
            </PuiSection>
          </>
          <>
            {handleCloseFailed()}

            <PuiSection title="Données du Conteneur" sx={{ marginTop: "25px" }}>
              <Formik initialValues={dataY} onSubmit={onSubmit}>
                {({ values, setFieldValue }) => (
                  <Form className={classes.form}>
                    <Grid container justifyContent="center" spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{ marginTop: "4px" }}
                      >
                        <CollapsibleTable
                          columns={columns}
                          ops={true}
                          rows={listActivities?.containers}
                          resultData={{
                            poidsTotal: listActivities?.totalWeight + " " || "",
                            nombre: listActivities?.totalContainers || "",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </PuiSection>
          </>

          <CustomSnackbar
            open={failed}
            anchor={{
              vertical: "bottom",
              horizontal: "left",
            }}
            duration={4000}
            severity="error"
            close={handleCloseFailed}
            message={
              <span>
                <b>{error}</b>
              </span>
            }
          />
        </PuiMainContainer>
      </div>
    </>
  );
}
