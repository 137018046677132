import  instance from "../../AxiosInterceptor";
import config from "../../config";

export const getPremierPortApi = (data) => {
    return instance
      .get(config.url+"/ms-bookingApi/locality/search?code="+data?.code+"&country="+data?.country+"&page="+data?.page+"&port="+data?.description+"&size=10")
      .then((response) => {
        return response;
      })
      .then((err) => {
        return err;
      });
  };
  