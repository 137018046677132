import {v4 as uuid} from "uuid";

export const mapContainersWithDetail = (conteneurs)=>{
    if (conteneurs){
        if (!Array.isArray(conteneurs)){
            conteneurs = [conteneurs];
        }
        conteneurs = conteneurs.map(elm=>{
            let mappedConteneur = {};
            mappedConteneur.id = uuid();
 //           mappedConteneur.idClassImo = elm.ClassIMO;
            mappedConteneur.classImo= elm.ClassIMO;
            mappedConteneur.etatConteneur = elm.Etat;
            mappedConteneur.mesureDepassementHauteur = elm.MesureDepassementHauteur;
            mappedConteneur.mesureDepassementLargeur = elm.MesureDepassementLargeur;
            mappedConteneur.mesureDepassementLongueur = elm.MesureDepassementLongueur;
            mappedConteneur.numConteneur = elm.NumConteneur;
            mappedConteneur.numPermisDouane = elm.NumPermisDouane;
            mappedConteneur.numScelleArmateur = elm.NumscelleArmateur;
            mappedConteneur.scelleChargeur = elm.NumscelleChargeur;
            mappedConteneur.poidsBrut = elm.PoidBrut;
            mappedConteneur.poidsNet = elm.PoidNet;
            mappedConteneur.tagDangereux = elm.TagDangereux === "O"?"oui":"non";
            mappedConteneur.tagFrigo = elm.TagFrigo === "O"?"oui":"non";
            mappedConteneur.TagHG = elm.TagHG === "O"?"oui":"non";
            mappedConteneur.temperatureConsigne = elm.Temperateurconsigne;
            mappedConteneur.codeIso = elm.codeISO;
            mappedConteneur.nombreConteneur = 1;
            mappedConteneur.withDetails = true;
            return mappedConteneur;
        })
        return conteneurs;
    }
    return null;
}

export const mapContainersWithoutDetail = (conteneurs)=>{
    if (conteneurs){
        if (!Array.isArray(conteneurs)){
            conteneurs = [conteneurs];
        }
        conteneurs = conteneurs.map(elm=>{
            let mappedConteneur = {};
            mappedConteneur.id = uuid();
            mappedConteneur.nombreConteneur = elm.NombreConteneurs;
            mappedConteneur.poidsTotal = elm.PoidsTotal;
            mappedConteneur.codeIso = elm.codeISO;
            mappedConteneur.withDetails = false;
            return mappedConteneur;
        })
        return conteneurs;
    }
    return null;
}