import * as Yup from "yup";

export const bookingValidationSchema = Yup.object({
  numEscale: Yup.string().required("Ce champ est obligatoire"),
  numeroBooking: Yup.string().required("Ce champ est obligatoire"),
  dateReservation: Yup.string().required("Ce champ est obligatoire"),
  dateLimite: Yup.date().required("Ce champ est obligatoire"),
  portDechargement: Yup.string().required("Ce champ est obligatoire"),
  portDestinataire: Yup.string()
    .notOneOf(
      [Yup.ref("portDechargement")],
      "Le port de destination finale doit être different au port de déchargement "
    )
    .required("Ce champ est obligatoire"),
  exportateur: Yup.string().required("Ce champ est obligatoire"),
  dateEtd: Yup.date().required("Ce champ est obligatoire").nullable(),
  dateEta: Yup.date().required("Ce champ est obligatoire"),
});

export const conteneurDetailValidationSchema = (checkDetail) => {
  if (checkDetail) {
    return Yup.object({
      numConteneur: Yup.string()
        .required("Ce champ est obligatoire")
        .nullable(),
      codeIso: Yup.string().required("Ce champ est obligatoire").nullable(),
      etatConteneur: Yup.string()
        .required("Ce champ est obligatoire")
        .nullable(),
      poidsBrut: Yup.string()
        .required("Ce champ est obligatoire")
        .matches(/^[0-9]+$/, "Seuls les chiffres sont autorisés pour ce champ")
        .max(10, "le max du nombre chiffre c'est 10")
        .nullable(),
      poidsNet: Yup.string()
        .matches(/^[0-9]+$/, "Seuls les chiffres sont autorisés pour ce champ")
        .max(10, "le max du nombre chiffre c'est 10")
        .nullable(),
      classImo: Yup.string().when("tagDangereux", {
        is: "oui",
        then: Yup.string().required(
          "Le champ 'classe IMO' est obligatoire si le 'Tag dangereux' est mis à oui"
        ),
      }),
      numScelleArmateur: Yup.string()
        .matches(/^[0-9]+$/, "Seuls les chiffres sont autorisés pour ce champ")
        .max(10, "le max du nombre chiffre c'est 10")
        .nullable(),
      scelleChargeur: Yup.string()
        .matches(/^[0-9]+$/, "Seuls les chiffres sont autorisés pour ce champ")
        .max(10, "le max du nombre chiffre c'est 10")
        .nullable(),
      numPermisDouane: Yup.string()
        .matches(/^[0-9]+$/, "Seuls les chiffres sont autorisés pour ce champ")
        .max(10, "le max du nombre chiffre c'est 10")
        .nullable(),
      temperatureConsigne: Yup.string()
        .matches(/^[0-9]+$/, "Seuls les chiffres sont autorisés pour ce champ")
        .when("tagFrigo", {
          is: "oui",
          then: Yup.string()
            .required("Ce champ est oubligatoire")
            .matches(
              /^[0-9]+$/,
              "Seuls les chiffres sont autorisés pour ce champ"
            )
            .max(10, "le max du nombre chiffre c'est 10"),
        })
        .nullable(),
      mesureDepassementHauteur: Yup.string()
        .matches(/^[0-9]+$/, "Seuls les chiffres sont autorisés pour ce champ")
        .when("tagHg", {
          is: "oui",
          then: Yup.string()
            .required("Ce champ est oubligatoire")
            .matches(
              /^[0-9]+$/,
              "Seuls les chiffres sont autorisés pour ce champ"
            )
            .matches(/^(?!0)[0-9]+$/, "Chemps doit étre supérieur ou égale à 0")
            .max(10, "le nombre max des chiffre c'est 10"),
        })
        .nullable(),
      mesureDepassementLargeur: Yup.string()
        .matches(/^[0-9]+$/, "Seuls les chiffres sont autorisés pour ce champ")
        .when("tagHg", {
          is: "oui",
          then: Yup.string()
            .required("Ce champ est oubligatoire")
            .matches(
              /^[0-9]+$/,
              "Seuls les chiffres sont autorisés pour ce champ"
            )
            .matches(/^(?!0)[0-9]+$/, "Chemps doit étre supérieur ou égale à 0")
            .max(10, "le nombre max des chiffre c'est 10"),
        })
        .nullable(),
      mesureDepassementLongueur: Yup.string()
        .matches(/^[0-9]+$/, "Seuls les chiffres sont autorisés pour ce champ")
        .when("tagHg", {
          is: "oui",
          then: Yup.string()
            .required("Ce champ est oubligatoire")
            .matches(
              /^[0-9]+$/,
              "Seuls les chiffres sont autorisés pour ce champ"
            )
            .matches(/^(?!0)[0-9]+$/, "Chemps doit étre supérieur ou égale à 0")
            .max(10, "le nombre max des chiffre c'est 10"),
        })
        .nullable(),
    });
  } else {
    return Yup.object({
      codeIso: Yup.string().required("Ce champ est obligatoire").nullable(),
      nombreConteneur: Yup.string()
        .required("Ce champ est obligatoire")
        .nullable()
        .matches(/^[0-9]+$/, "Seuls les chiffres sont autorisés pour ce champ")
        .max(10, "le max du nombre chiffre c'est 10"),
      poidsTotal: Yup.string()
        .required("Ce champ est obligatoire")
        .nullable()
        .matches(/^[0-9]+$/, "Seuls les chiffres sont autorisés pour ce champ")
        .max(10, "le max du nombre chiffre c'est 10"),
    });
  }
};
