import * as ActionsTypes from "../../../common/state/StateConstants";
const initialState = {
  conteneur: {
    isLoading: false,
    totalElements: 0,
    content: [],
    error: {},
  },
  escale: {
    isLoading: false,
    totalElements: 0,
    content: [],
    error: {},
  },
  typeEquipement: {
    isLoading: false,
    totalElements: 0,
    content: [],
    error: {},
  },
  imoClass: {
    isLoading: false,
    totalElements: 0,
    content: [],
    error: {},
  },
  toUpdateBooking: null,
  arrivalNotice: null,
  entityAcceuil: [],
  mandataire: [],
  imoClassData: [],
  classImoData: [],
  operateurNameData: [],
  armateurData: [],
  creationBookingData: [],
  messageSucced: [],
  error: null,
  success: false,
  failed: false,
  successNumContainer: false,
  failedNumContainer: false,
  successClassImo: false,
  failedClassImo: false,
  isLoading: false,
  isLoadingImoClass: false,
  isLoadingNumContainer: false,
  isLoadingClassImo: false,
  isLoadingNumEscale: false,
  isLoadingOperteurName: false,
  isLoadingArmateur: false,
  isLoadingCreerBooking: false,
  itemSaved: null,
};

const EntiteAcceuilReducer = (state = initialState, action) => {
  // 
  // console.log(action.payload);
  switch (action.type) {
    /********************Case :Creation Booking(Code ISO)************** */

    case ActionsTypes.GET_CODEISO:
      return {
        ...state,
        typeEquipement: { ...state.typeEquipement, isLoading: true },
      };

    case ActionsTypes.GET_CODEISO_SUCCESS:
      return {
        ...state,
        typeEquipement: {
          ...state.typeEquipement,
          isLoading: false,
          totalElements: action.response.totalElements,
          content: action.response.content,
        },
      };

    case ActionsTypes.GET_CODEISO_FAIL:
      return {
        ...state,
        typeEquipement: { ...state.typeEquipement, isLoading: false },
      };

    /********************Case :Creation Booking(Num Container)************** */

    case ActionsTypes.GET_NUMCONTAINER:
      return {
        ...state,
        conteneur: { ...state.conteneur, isLoading: true },
      };

    case ActionsTypes.GET_NUMCONTAINER_SUCCESS:
      return {
        ...state,
        conteneur: {
          ...state.conteneur,
          isLoading: false,
          content: action.response.content,
          totalElements: action.response.totalElements,
        },
      };

    case ActionsTypes.GET_NUMCONTAINER_FAIL:
      return {
        ...state,
        conteneur: { ...state.conteneur, isLoading: false },
      };

    /********************Case :Creation Booking(Class IMO)************** */

    case ActionsTypes.GET_CLASSIMO:
      return {
        ...state,
        imoClass: { ...state.imoClass, isLoading: true },
      };

    case ActionsTypes.GET_CLASSIMO_SUCCESS:
      return {
        ...state,
        imoClass: {
          ...state.imoClass,
          isLoading: false,
          content: action.response.content,
          totalElements: action.response.totalElements,
        },
      };

    case ActionsTypes.GET_CLASSIMO_FAIL:
      return {
        ...state,
        imoClass: { ...state.imoClass, isLoading: false },
      };

    /********************Case :Creation Booking(Num Escale)************** */

    /********************Case :Creation Booking(Operateur Name)************** */

    case ActionsTypes.GET_OPERATEURNAME:
      return {
        ...state,
        isLoadingOperteurName: true,
      };

    case ActionsTypes.GET_OPERATEURNAME_SUCCESS:
      return {
        ...state,
        isLoadingOperteurName: false,
        operateurNameData: action.payload,
      };

    case ActionsTypes.GET_OPERATEURNAME_FAIL:
      return {
        ...state,
        isLoadingOperteurName: false,
        operateurNameData: action.payload,
      };

    /********************Case :Creation Booking(Armateur)************** */

    case ActionsTypes.GET_ARMATEUR:
      return {
        ...state,
        isLoadingArmateur: true,
      };

    case ActionsTypes.GET_ARMATEUR_SUCCESS:
      return {
        ...state,
        isLoadingArmateur: false,
        armateurData: action.payload,
      };

    case ActionsTypes.GET_ARMATEUR_FAIL:
      return {
        ...state,
        isLoadingArmateur: false,
        armateurData: action.payload,
      };

    /*******************************Reset Creation Booking ******/
    case ActionsTypes.RESET_CREATION_FAIL:
      return {
        ...state,
        isCreationError: false,
      };
    case ActionsTypes.RESET_CREATION_SUCCESS:
      return {
        isCreationSuccess: false,
      };

    /********************Case :Creation Booking(Creer Booking)************** */

    case ActionsTypes.GET_CREATIONBOOKING:
      return {
        ...state,
        error: null,
        isLoadingCreerBooking: true,
      };

    case ActionsTypes.GET_CREATIONBOOKING_SUCCESS:
      // console.log('azeaezzaezaeazezaeazez');
      // console.log(action.payload);
      return {
        ...state,
        messageSucced: action.payload.message,
        isLoadingCreerBooking: false,
        creationBookingData: action.payload,
        isCreationSuccess: true,
        isCreationError: false,
      };

    case ActionsTypes.GET_CREATIONBOOKING_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isLoadingCreerBooking: false,
        creationBookingData: action.payload,
        isCreationSuccess: false,
        isCreationError: true,
      };

    /********************Case :Creation And Send Booking(Creer And Send Booking)************** */

    case ActionsTypes.GET_CREATIONSENDBOOKING:
      return {
        ...state,
        error: null,
        isLoadingCreerBooking: true,
      };

    case ActionsTypes.GET_CREATIONSENDBOOKING_SUCCESS:
      // console.log('azeaezzaezaeazezaeazez');
      // console.log(action.payload);
      return {
        ...state,
        messageSucced: action.payload.message,
        isLoadingCreerBooking: false,
        creationBookingData: action.payload,
        isCreationSuccess: true,
        isCreationError: false,
      };

    case ActionsTypes.GET_CREATIONSENDBOOKING_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isLoadingCreerBooking: false,
        creationBookingData: action.payload,
        isCreationSuccess: false,
        isCreationError: true,
      };

    case ActionsTypes.GET_BOOKING_SUCCESS:
      return { ...state, toUpdateBooking: action.booking };

    case ActionsTypes.GET_ARRIVAL_NOTICE_SUCCESS:
      return { ...state, arrivalNotice: action.arrivalNotice };

    default:
      return state;
  }
};

export default EntiteAcceuilReducer;
