import { container, defaultFont } from "./material.js";

const signupPageStyle = {
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    paddingTop: "15vh",
    color: "#FFFFFF",
    paddingBottom: "10vh",
  },
  containerPlain: {
    zIndex: "2",
    position: "relative",
    padding: "0vh",
    color: "#FFFFFF",
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: "rgb(207, 216, 220, 0.2)",
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""',
    },
    "& footer li a,& footer li a:hover,& footer li a:active": {
      color: "#FFFFFF",
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
    },
  },
  containerGrid: {
    padding: "15px",
    background: "rgb(207, 216, 220, 0.2)",
  },
  pageCenter: {
    minHeight: "100vh",
    height: "auto",
    //display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: "rgb(207, 216, 220, 0.2)",
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
    },
    "& footer li a,& footer li a:hover,& footer li a:active": {
      color: "#FFFFFF",
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
    },
  },
  form: {
    margin: "0",
  },
  cardHeaderLeft: {
    width: "auto",
    textAlign: "center",
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "-40px",
    padding: "20px 0",
    marginBottom: "15px",
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "-40px",
    padding: "20px 0",
    marginBottom: "15px",
  },
  socialIcons: {
    maxWidth: "24px",
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
  },
  divider: {
    marginTop: "30px",
    marginBottom: "0px",
    textAlign: "center",
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important",
  },
  socialLine: {
    marginTop: "1rem",
    textAlign: "center",
    padding: "0",
  },
  inputIconsColor: {
    color: "#495057",
  },
  space70: {
    height: "70px",
    display: "block",
  },
  space50: {
    height: "50px",
    display: "block",
  },
  space30: {
    height: "30px",
    display: "block",
  },
  space20: {
    height: "20px",
    display: "block",
  },
  space10: {
    height: "10px",
    display: "block",
  },
  space4: {
    height: "4px",
    display: "block",
  },
  labelRoot: {
    ...defaultFont,
    color: "#212121",
    fontWeight: "400",
    fontSize: "13px !important",
    lineHeight: "1.42857",
    top: "10px",
    letterSpacing: "unset",
    "& + $underline": {
      marginTop: "0px",
    },
  },
  label: {
    ...defaultFont,
    color: "#212121 !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "1.42857",
    letterSpacing: "unset",
    marginTop: "0px",

    "& + $underline": {
      marginTop: "0px",
    },
  },
  dropdownItem: {
    ...defaultFont,
    fontSize: "13px",
    padding: "10px 0px",
    margin: "0 5px",
    borderRadius: "2px",
    position: "relative",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "500",
    height: "fit-content",
    color: "#333",
    whiteSpace: "nowrap",
    minHeight: "unset",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  legend: {
    color: "#000 !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "0.42857 !important",
    letterSpacing: "unset",
    marginTop: "0px",
    "& + $underline": {
      marginTop: "0px",
    },
  },
};

export default signupPageStyle;
