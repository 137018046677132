import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { Formik, useField, useFormikContext } from "formik";
import { Stack } from "@mui/material";
import CustomInputField from "../../../common/components/CustomField/CustomInputField";
import Button from "../../../common/components/CustomButtons/Button";
import CustomTable from "../../../common/components/CustomTable/CustomTable";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { DataGrid, frFR } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../../common/components/CustomNoRowsOverlay";
import IconButton from "@mui/material/IconButton";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import Tooltip from "@mui/material/Tooltip";
import { getNumContainer } from "../state/creationBookingAction";
import {
  PuiCustomPopupReferentielField,
  PuiGrid,
  PuiTextField,
  PuiIcon,
  PuiSearchDialog,
  PuiTableAction,
  PuiFormikForm,
  PuiButton,
} from "@portnet/ui";

const ConteneurSearch = (props) => {
  const { id, name, label, value } = props;
  const dispatch = useDispatch();

  const [field, meta] = useField(name);
  const formikContext = useFormikContext();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [hasError, setHasError] = React.useState(false);

  const content =
    useSelector((state) => state.creationBooking.conteneur.content) ?? [];
  const isLoading = useSelector(
    (state) => state.creationBooking.conteneur.isLoading
  );
  const totalElements = useSelector(
    (state) => state.creationBooking.conteneur.totalElements
  );

  const [pager, setPager] = useState({
    page: 0,
    size: 10,
  });
  const [formikValues, setFormikValues] = useState({});

  const columns = [
    {
      field: "matricule",
      headerName: "N° du conteneur",
      sortable: false,
      minWidth: 200,
      valueGetter: ({ row }) => row?.matricule,
    },
    {
      field: "tractorRegistration",
      headerName: "N° tracteur",
      sortable: false,
      minWidth: 200,
      valueGetter: ({ row }) => row?.tractorRegistration,
    },
    {
      field: "capacityWeight",
      headerName: "Poids (Tn)",
      sortable: false,
      minWidth: 200,
      valueGetter: ({ row }) => row?.capacityWeight,
    },
    {
      field: "capacityVolume",
      headerName: "Volume (M3)",
      sortable: false,
      minWidth: 200,
      valueGetter: ({ row }) => row?.capacityVolume,
    },
    /* {
            field: "action",
            headerName: "",
            sortable: false,
            width: 40,
            renderCell: ({row}) => (
                <Tooltip title="Sélectionner" arrow>
                    <IconButton aria-label="expand row"
                                size="small"
                                color="primary"
                                onClick={() => {
                                    onSelect(row);
                                    onClose()
                                }}
                    >
                        <CheckOutlinedIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            )
        }*/
  ];

  useEffect(() => {
    setHasError(Boolean(meta) && Boolean(meta.touched) && Boolean(meta.error));
  }, [meta]);

  useEffect(() => {
    if (value !== undefined && value !== null) {
      formikContext.setFieldValue(name, value);
    }
  }, [value]);

  useEffect(() => {
    if (formikValues != null) {
      const payload = {
        ...formikValues,
        ...pager,
      };
      dispatch(getNumContainer(payload));
    }
  }, [pager]);

  const initialValues = {
    matricule: "",
  };
  const validationSchema = Yup.object({
    matricule: Yup.string().required("Ce champ est obligatoire"),
  });
  const handleSubmit = (values) => {
    const payload = {
      ...values,
      page: 0,
      size: pager.size,
    };
    dispatch(getNumContainer(payload));
    setFormikValues(values);
  };

  const pageChangeHandler = (page) => {
    setPager({ ...pager, page: page });
  };
  const selectHandler = (row) => {
    setIsDialogOpen(false);
    formikContext.setFieldValue(name, row?.matricule);
  };

  const tableActions = [
    <PuiTableAction title="Choisir" onClick={selectHandler}>
      <PuiIcon type="choisir" />
    </PuiTableAction>,
  ];

  return (
    <>
      <PuiTextField
        id={id}
        name={name}
        label={label}
        value={value}
        required
        error={hasError}
        InputProps={{
          endAdornment: (
            <PuiIcon
              type="rechercher"
              size="small"
              sx={{ marginRight: "-5px", cursor: "pointer" }}
              onClick={() => setIsDialogOpen(true)}
            />
          ),
        }}
      />
      <PuiSearchDialog
        open={isDialogOpen}
        title="Liste des conteneurs"
        onClose={() => setIsDialogOpen(false)}
        loading={isLoading}
        rowCount={totalElements}
        rows={content}
        page={pager.page}
        pageSize={pager.size}
        columns={columns}
        actions={tableActions}
        onPageChange={pageChangeHandler}
        tableProps={{
          paginationMode: "server",
          oneActionOnly: true,
          //...tableProps,
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ resetForm, submitForm }) => (
            <PuiFormikForm>
              <PuiGrid container>
                <PuiGrid item xs={12}>
                  <PuiGrid item xs={6}>
                    <PuiTextField name="matricule" label="Num conteneurs" />
                  </PuiGrid>
                </PuiGrid>
                <PuiGrid item xs={12}>
                  <PuiGrid container justifyContent="end">
                    <PuiGrid item>
                      <PuiButton
                        startIcon={<PuiIcon type="effacer" size="small" />}
                        onClick={resetForm}
                        color="tertiary"
                      >
                        Effacer
                      </PuiButton>
                    </PuiGrid>
                    <PuiGrid item>
                      <PuiButton
                        startIcon={<PuiIcon type="rechercher" size="small" />}
                        type="submit"
                        loading={isLoading}
                        loadingPosition="start"
                      >
                        Rechercher
                      </PuiButton>
                    </PuiGrid>
                  </PuiGrid>
                </PuiGrid>
              </PuiGrid>
            </PuiFormikForm>
          )}
        </Formik>
      </PuiSearchDialog>
    </>

    /*<PuiCustomPopupReferentielField
            title="Liste des conteneurs"
            name={name}
            value={value}
            label={label}
            columns={columns}
            rows={content}
            rowCount={totalElements}
            loading={isLoading}
            page={pager.page}
            pageSize={pager.size}
            getRenderedValue={(row) => row?.matricule}
            getReturnedValue={(row) => row?.matricule}
            onPageChange={pageChangeHandler}
            onSubmit={handleSubmit}
        >
            <PuiGrid container>
                <PuiGrid item xs={6}>
                    <PuiTextField name="matricule" label="Num conteneurs" />
                </PuiGrid>

            </PuiGrid>
        </PuiCustomPopupReferentielField>*/
  );
};
export default ConteneurSearch;
