import decode from "jwt-decode";

class JwtUtils {

  getUser(){
    return decode(localStorage.getItem("token"))?.user;
  }

  hasRole(roleIn) {
    if(!localStorage.getItem("token")) {
      return false;
    }
    let roles = decode(localStorage.getItem("token"))?.roles;
    if(roles) {
      if (roles.includes(roleIn)) return true;
      return false;
    }
    return false;
  }

  hasAnyRole(rolesIn) {
    if(rolesIn.length === 0) {
      return true;
    }
    for (let i=0; i<rolesIn.length; i++){
      if (this.hasRole(rolesIn[i])) {
        return true;
      }
    }
    return false;
  }
}

export default new JwtUtils();
