import  instance from "../../AxiosInterceptor";
import config from "../../config";

export const getDetailBookingApi = (data) => {
    return instance
  .get(config.url+"/ms-bookingApi/booking/getDetailBooking?id="+data.id)
  .then((response) => {
        return response;
      })
      .then((err) => {
        return err;
      });
  };