import * as ActionsTypes from "../../../common/state/StateConstants";
const initialState = {
    entityAcceuil: [],
    mandataire: [],
    detailBookingData: [],
    error: null,
    success: false,
    failed: false,
    isLoading: false,
    isLoadingDetailBooking: false,
    itemSaved: null
    
  };

  const EntiteAcceuilReducer = (state = initialState, action) => {
    switch (action.type) {
      

      /********************Case :Detail Booking************** */


      case ActionsTypes.GET_DETAILBOOKING:
        return {
          ...state,
          isLoadingDetailBooking: true,
        };
  
      case ActionsTypes.GET_DETAILBOOKING_SUCCESS:

          return {
            ...state,
            isLoadingDetailBooking: false,
            success: true,
            failed: false,
            detailBookingData:action.payload,
            
          };
      
      case ActionsTypes.GET_DETAILBOOKING_FAIL:
            return {
              ...state,
              isLoadingDetailBooking: false,
              success: false,
              failed: true,
              detailBookingData: action.payload,
            };
  
      default:
        return state;
    }
  };
  
  export default EntiteAcceuilReducer;
  