import  instance from '../../AxiosInterceptor';
import config from '../../config';

export const getClientSearchApi = (data) => {
  return instance
    .get(
      config.url +
        '/ms-bookingApi/operatorImpExp/search?centerRc=' +
        data?.centerRc +
        '&code=' +
        data?.code +
        '&codePIN=' +
        data?.codePIN +
        '&description=' +
        data?.description +
        '&ice=' +
        data?.ice +
        '&ifu=' +
        data?.ifu +
        '&numberRc=' +
        data?.numberRc +
        '&page=' +
        data?.page +
        '&size=10'
    )
    .then((response) => {
      return response;
    })
    .then((err) => {
      return err;
    });
};
