import React from "react";
import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  PuiNetworkErrorPage,
  PuiNotFoundErrorPage,
  PuiServerErrorPage,
  PuiForbiddenErrorPage,
} from "@portnet/ui";
import AppMainContainer from "../AppMainContainer";
import GestionBooking from "../../../modules/gestionBooking/ui/GestionBooking.js";
import DetailBooking from "../../../modules/detailBooking/ui/detailBooking.js";
import CreationBooking from "../../../modules/creationBooking/ui/CreationBooking.js";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import { useCheckAuthority } from "../../hooks/useCheckAuthority";
import {
  FORBIDDEN_ERROR_PAGE_ROUTE,
  NETWORK_ERROR_PAGE_ROUTE,
  NOT_FOUND_ERROR_PAGE_ROUTE,
  SERVER_ERROR_PAGE_ROUTE
} from "../../constants/routerConstants";

const PrivateRoute = ({ children, roles = [] }) => {
  const { checkAuthority } = useCheckAuthority(roles);
  const isAuthorized = useSelector((state) => checkAuthority(state));

  return isAuthorized ? children : <Navigate to={FORBIDDEN_ERROR_PAGE_ROUTE} replace />;
};

const ErrorPageContainer = ({ children }) => {
  const retourHandler = () => {
    // Navigate logic here
  };
  return React.cloneElement(children, { onRetour: retourHandler });
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppMainContainer />,
    children: [
      { path: "ConsultationBooking", element: <DetailBooking /> },
      {
        path: SERVER_ERROR_PAGE_ROUTE,
        element: <ErrorPageContainer><PuiServerErrorPage /></ErrorPageContainer>,
      },
      {
        path: "Creation",
        element: (
            <PrivateRoute
                roles={[
                  { key: "CONSIGNATAIRE", isInversed: false },
                ]}
            >
              <CreationBooking />
            </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "Creation/:bookingId",
        element: (
            <PrivateRoute
                roles={[
                  { key: "CONSIGNATAIRE", isInversed: false },
                ]}
            >
              <CreationBooking />
            </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "Gestion",
        element: (
            <PrivateRoute
                roles={[
                  { key: "CONSIGNATAIRE", isInversed: false },
                  { key: "ADMIN", isInversed: false },
                ]}
            >
              <GestionBooking />
            </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "Gestion/:action",
        element: (
            <PrivateRoute
                roles={[
                  { key: "CONSIGNATAIRE", isInversed: false },
                  { key: "ADMIN", isInversed: false },
                ]}
            >
              <GestionBooking />
            </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: NOT_FOUND_ERROR_PAGE_ROUTE,
        element: <ErrorPageContainer><PuiNotFoundErrorPage /></ErrorPageContainer>,
      },
      {
        path: FORBIDDEN_ERROR_PAGE_ROUTE,
        element: <ErrorPageContainer><PuiForbiddenErrorPage /></ErrorPageContainer>,
      },
      {
        path: NETWORK_ERROR_PAGE_ROUTE,
        element: <ErrorPageContainer><PuiNetworkErrorPage /></ErrorPageContainer>,
      },
      { path: "*", element: <NotFoundPage /> },
    ]
  },
]);

export default router;