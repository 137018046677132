import { createTheme } from '@mui/material/styles';
import { primaryColor, secondaryColor } from "../../assets/jss/material";
const defaultDark = process.env.REACT_APP_THEME_DARK === "true";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor
    },
    secondary: {
      main: secondaryColor
    },
    type: defaultDark ? "dark" : "light",
  },

  primaryColor: primaryColor,
  secondaryColor: secondaryColor,
  dark: defaultDark,
});

const appearance = {};


appearance.defaultPrimaryColor = primaryColor;
appearance.defaultSecondaryColor = secondaryColor;
appearance.defaultDark = defaultDark;

appearance.defaultTheme = defaultTheme;

appearance.isDefaultTheme = (theme) => {
  if (!theme) {
    return false;
  }
  return false;
};

appearance.createTheme = (theme) => {
  if (!theme) {
    return null;
  }

  let primaryColor = theme.primaryColor;
  let secondaryColor = theme.secondaryColor;
  let dark = theme.dark;

  if (!primaryColor || !secondaryColor) {
    return null;
  }
  if (!primaryColor || !secondaryColor) {
    return null;
  }

  theme = createTheme({
    palette: {
      primary: primaryColor.import,
      secondary: secondaryColor.import,
      type: dark ? "dark" : "light",
    },

    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
    dark: dark,
  });

  return theme;
};
export default appearance;
