import React from "react";
import Stack from "@mui/material/Stack";
import { Button, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";

export default function UserLinks(props) {
  //const classes = useStyles();

  const useStyles = makeStyles((theme) => ({
    myClassName: {
      //backgroundColor: "pink",
      color: "white",
      "&:hover": {
        backgroundColor: "white",
        color: "#232f66",
      },
    },
  }));

  const navigate = useNavigate();
  const classes = useStyles();

  const handleLogout = () => {
    localStorage.clear();
    //window.location.reload(false);
    navigate("/");
  };
  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      <Button
        endIcon={<LogoutIcon className={classes.myClassName} />}
        style={{ color: "white" }}
        onClick={handleLogout}
      >
        Se déconnecter
      </Button>
    </Stack>
  );
}
