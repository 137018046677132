/*************************************GET PORT(Default) --->DropDown*************************  */
export const GET_PORT = 'GET_PORT';
export const GET_PORT_SUCCESS = 'GET_PORT_SUCCESS';
export const GET_PORT_FAIL = 'GET_PORT_FAIL';
/*************************************GET PORT(Search) --->DropDown*************************  */
export const GET_PORTSEARCH = 'GET_PORTSEARCH';
export const GET_PORTSEARCH_SUCCESS = 'GET_PORTSEARCH_SUCCESS';
export const GET_PORTSEARCH_FAIL = 'GET_PORTSEARCH_FAIL';
/*************************************GET OPERATEUR(Search) --->DropDown*************************  */
export const GET_OPERATEURSEARCH = 'GET_OPERATEURSEARCH';
export const GET_OPERATEURSEARCH_SUCCESS = 'GET_OPERATEURSEARCH_SUCCESS';
export const GET_OPERATEURSEARCH_FAIL = 'GET_OPERATEURSEARCH_FAIL';

/*************************************GET BOOKING(Search) --->DropDown*************************  */
export const GET_BOOKINGDEFAULT = 'GET_BOOKINGDEFAULT';
export const GET_BOOKINGDEFAULT_SUCCESS = 'GET_BOOKINGDEFAULT_SUCCESS';
export const GET_BOOKINGDEFAULT_FAIL = 'GET_BOOKINGDEFAULT_FAIL';

/*************************************GET BOOKING(Search) --->DropDown*************************  */
export const GET_PREMIERPORT = 'GET_PREMIERPORT';
export const GET_PREMIERPORT_SUCCESS = 'GET_PREMIERPORT_SUCCESS';
export const GET_PREMIERPORT_FAIL = 'GET_PREMIERPORT_FAIL';

/*************************************GET NAVIREDEFAULT(Search) --->DropDown*************************  */
export const GET_NAVIREDEFAULT = 'GET_NAVIREDEFAULT';
export const GET_NAVIREDEFAULT_SUCCESS = 'GET_NAVIREDEFAULT_SUCCESS';
export const GET_NAVIREDEFAULT_FAIL = 'GET_NAVIREDEFAULT_FAIL';

/*************************************GET NAVIREDEFAULT(Search) --->DropDown*************************  */
export const GET_NAVIRESEARCH = 'GET_NAVIRESEARCH';
export const GET_NAVIRESEARCH_SUCCESS = 'GET_NAVIRESEARCH_SUCCESS';
export const GET_NAVIRESEARCH_FAIL = 'GET_NAVIRESEARCH_FAIL';

/*************************************GET TRANSITAIREDEFAULT(Search) --->DropDown*************************  */
export const GET_TRANSITAIREDEFAULT = 'GET_TRANSITAIREDEFAULT';
export const GET_TRANSITAIREDEFAULT_SUCCESS = 'GET_TRANSITAIREDEFAULT_SUCCESS';
export const GET_TRANSITAIREDEFAULT_FAIL = 'GET_TRANSITAIREDEFAULT_FAIL';

/*************************************GET TRANSITAIRESEARCH(Search) --->DropDown*************************  */
export const GET_TRANSITAIRESEARCH = 'GET_TRANSITAIRESEARCH';
export const GET_TRANSITAIRESEARCH_SUCCESS = 'GET_TRANSITAIRESEARCH_SUCCESS';
export const GET_TRANSITAIRESEARCH_FAIL = 'GET_TRANSITAIRESEARCH_FAIL';

/*************************************GET TRANSITAIRESEARCH(Search) --->DropDown*************************  */
export const GET_CLIENTESEARCH = 'GET_CLIENTESEARCH';
export const GET_CLIENTESEARCH_SUCCESS = 'GET_CLIENTESEARCH_SUCCESS';
export const GET_CLIENTESEARCH_FAIL = 'GET_CLIENTESEARCH_FAIL';

/*************************************GET BOOKINGSEARCH(Search) --->DropDown*************************  */
export const GET_BOOKINGSEARCH = 'GET_BOOKINGSEARCH';
export const GET_BOOKINGSEARCH_SUCCESS = 'GET_BOOKINGSEARCH_SUCCESS';
export const GET_BOOKINGSEARCH_FAIL = 'GET_BOOKINGSEARCH_FAIL';

/*************************************GET HISTORIQUESEARCH(Search) --->DropDown*************************  */
export const GET_HISTORIQUESEARCH = 'GET_HISTORIQUESEARCH';
export const GET_HISTORIQUESEARCH_SUCCESS = 'GET_HISTORIQUESEARCH_SUCCESS';
export const GET_HISTORIQUESEARCH_FAIL = 'GET_HISTORIQUESEARCH_FAIL';

/*************************************GET GET_DETAILBOOKING(Search) --->Detail Booking*************************  */
export const GET_DETAILBOOKING = 'GET_DETAILBOOKING';
export const GET_DETAILBOOKING_SUCCESS = 'GET_DETAILBOOKING_SUCCESS';
export const GET_DETAILBOOKING_FAIL = 'GET_DETAILBOOKING_FAIL';

/***********Creation Booking (Code Iso)***************** */
export const GET_CODEISO = 'GET_CODEISO';
export const GET_CODEISO_SUCCESS = 'GET_CODEISO_SUCCESS';
export const GET_CODEISO_FAIL = 'GET_CODEISO_FAIL';

/***********Creation Booking (Num Container)***************** */
export const GET_NUMCONTAINER = 'GET_NUMCONTAINER';
export const GET_NUMCONTAINER_SUCCESS = 'GET_NUMCONTAINER_SUCCESS';
export const GET_NUMCONTAINER_FAIL = 'GET_NUMCONTAINER_FAIL';

/***********Creation Booking (Class Imo)***************** */
export const GET_CLASSIMO = 'GET_CLASSIMO';
export const GET_CLASSIMO_SUCCESS = 'GET_CLASSIMO_SUCCESS';
export const GET_CLASSIMO_FAIL = 'GET_CLASSIMO_FAIL';

/***********Creation Booking (Num Escale)***************** */
export const GET_NUMESCALE = 'GET_NUMESCALE';
export const GET_NUMESCALE_SUCCESS = 'GET_NUMESCALE_SUCCESS';
export const GET_NUMESCALE_FAIL = 'GET_NUMESCALE_FAIL';
/***********Common Booking (Transitaire)***************** */
export const GET_TRANSITAIRE = 'GET_TRANSITAIRE';
export const GET_TRANSITAIRE_SUCCESS = 'GET_TRANSITAIRE_SUCCESS';
export const GET_TRANSITAIRE_FAIL = 'GET_TRANSITAIRE_FAIL';

/***********Creation Booking (Operateur Name)***************** */
export const GET_OPERATEURNAME = 'GET_OPERATEURNAME';
export const GET_OPERATEURNAME_SUCCESS = 'GET_OPERATEURNAME_SUCCESS';
export const GET_OPERATEURNAME_FAIL = 'GET_OPERATEURNAME_FAIL';

/***********Creation Booking (Armateur)***************** */
export const GET_ARMATEUR = 'GET_ARMATEUR';
export const GET_ARMATEUR_SUCCESS = 'GET_ARMATEUR_SUCCESS';
export const GET_ARMATEUR_FAIL = 'GET_ARMATEUR_FAIL';
/***********Creation Booking (Creer Booking)***************** */
export const GET_CREATIONBOOKING = 'GET_CREATIONBOOKING';
export const GET_CREATIONBOOKING_SUCCESS = 'GET_CREATIONBOOKING_SUCCESS';
export const GET_CREATIONBOOKING_FAIL = 'GET_CREATIONBOOKING_FAIL';

/*********** Update Booking ***************** */
export const UPDATE_BOOKING = 'UPDATE_BOOKING';
export const UPDATE_BOOKING_SUCCESS = 'UPDATE_BOOKING_SUCCESS';
export const UPDATE_BOOKING_FAIL = 'UPDATE_BOOKING_FAIL';

/***********Creation And Send Booking (Creer et envoyer Booking)***************** */
export const GET_CREATIONSENDBOOKING = 'GET_CREATIONSENDBOOKING';
export const GET_CREATIONSENDBOOKING_SUCCESS = 'GET_CREATIONSENDBOOKING_SUCCESS';
export const GET_CREATIONSENDBOOKING_FAIL = 'GET_CREATIONSENDBOOKING_FAIL';

/*--------------------------------------------------RESET-CREATION-FAIL-----------------------------------------------------*/
export const RESET_CREATION_FAIL = 'RESET_CREATION_FAIL';
export const RESET_CREATION_SUCCESS = 'RESET_CREATION_SUCCESS';

/***********Get booking***************** */
export const GET_BOOKING = 'GET_BOOKING';
export const GET_BOOKING_SUCCESS = 'GET_BOOKING_SUCCESS';
export const GET_BOOKING_FAIL = 'GET_BOOKING_FAIL';

/*********** Get Arrival Notice ***************** */
export const GET_ARRIVAL_NOTICE = 'GET_ARRIVAL_NOTICE';
export const GET_ARRIVAL_NOTICE_SUCCESS = 'GET_ARRIVAL_NOTICE_SUCCESS';
export const GET_ARRIVAL_NOTICE_FAIL = 'GET_ARRIVAL_NOTICE_FAIL';

/*********** Cancel Booking ***************** */
export const CANCEL_BOOKING = 'CANCEL_BOOKING';

/*********** Cancel Booking ***************** */
export const SEND_BOOKING = 'SEND_BOOKING';

/*********** Update and send booking ***************** */
export const UPDATE_AND_SEND_BOOKING = 'UPDATE_AND_SEND_BOOKING';
export const UPDATE_AND_SEND_BOOKING_SUCCESS = 'UPDATE_AND_SEND_BOOKING_SUCCESS';
export const UPDATE_AND_SEND_BOOKING_FAIL = 'UPDATE_AND_SEND_BOOKING_FAIL';

