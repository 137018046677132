import  instance from "../../AxiosInterceptor";
import config from "../../config";

export const getBooking = (id) => {
    return instance.get(config.url+`/ms-bookingApi/booking/${id}`)
};

export const cancelBooking = (id) => {
    return instance.put(config.url+`/ms-bookingApi/booking/cancel`, {}, {
        params: {
            id: id
        }
    })
};

export const sendBooking = (id) => {
    return instance.put(config.url+`/ms-bookingApi/booking/send`, {}, {
        params: {
            id: id
        }
    })
};