import * as React from 'react';
import {useEffect} from 'react';
import Typography from '@mui/material/Typography';
import {formatPrices} from "../../../modules/gestionBooking/ui/helpers";


export const CollapsibleTableContainer = (props) => {
  const {  rows } = props;
  const [resultData, setResultData] = React.useState({});

  useEffect(() => {
      let total = 0;
      let nombre = 0;
      rows.forEach(row => {
          if (row.withDetails) {
              total += parseFloat(row.poidsBrut || 0);
              nombre += 1;
          } else {
              total += parseFloat(row.poidsTotal || 0);
              nombre += parseFloat(row.nombreConteneur || 0);
          }
      });
      setResultData({ total, nombre });
  }, [props.rows]);

  return (
      <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
          <Typography
              gutterBottom
              variant="overline"
              color="primary"
              component="span"
              sx={{
                  fontSize: 15,
                  fontWeight: 'medium',
                  color: 'primary',
                  textAlign: 'right',
                  textTransform: 'none', // This will prevent uppercase
              }}
          >
              Nombre des conteneurs :<span style={{color: 'red'}}>{resultData?.nombre}</span>
          </Typography>
          <Typography
              gutterBottom
              variant="overline"
              color="primary"
              component="span"
              sx={{
                  fontSize: 15,
                  fontWeight: 'medium',
                  color: 'primary',
                  textAlign: 'right',
                  textTransform: 'none', // This will prevent uppercase
              }}
          >
              Poids total des conteneurs :
              <span style={{color: 'red'}}>{formatPrices(resultData?.total) + ' KG'}</span>
          </Typography>
      </div>

  );
}
