import  instance from '../../AxiosInterceptor';
import config from '../../config';

export const getOperateurApi = (data) => {
  return instance
    .get(
      config.url +
        '/ms-bookingApi/typeOperator/search?code=' +
        data?.codeOperateur +
        '&description=' +
        data?.description +
        '&page=' +
        data?.page +
        '&portId=' +
        data?.portOperateur +
        '&size=' +
        data?.size
    )
    .then((response) => {
      return response;
    })
    .then((err) => {
      return err;
    });
};
