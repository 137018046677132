import  instance from "../../AxiosInterceptor";
import config from "../../config";

export const getNavireDefaultApi = (data) => {
    return instance
      .get(config.url+"/ms-bookingApi/ship/searchByIdNot?Id="+data?.id+"&page="+data?.page+"&size=5")
      .then((response) => {
        return response;
      })
      .then((err) => {
        return err;
      });
  };
  