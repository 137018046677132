import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import Hidden from "@mui/material/Hidden";
import logo from "../../../assets/img/brand/logo-portnet.png";
import styles from "../../../assets/jss/headerStyle.js";
import Button from "../CustomButtons/Button.js";
import avatar from "../../../assets/img/profil-annonyme.jpg";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import Home from "@mui/icons-material/Home";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import UserLinks from "./UserLinks";
import FlagLinks from "./FlagLinks";
import { ADMIN, DGSN, ENTITE, hasRole, OM } from "../../session/Autorisation";

const useStyles = makeStyles(styles);

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const useStyles1 = makeStyles((theme) => ({
  myClassName: {
    //backgroundColor: "pink",
    //color:"232f66",
      fontSize: 13,
      fontWeight: "normal",
      //color: "rgba(255,255,255,1)",
 
    "&:hover": {
      // backgroundColor: "white",
      color:"#232f66",
      fontSize:"130",
    }
  }
}));



const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 50,
  height: 50,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(0)} + 0px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(0)} + 0px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${0}px)`,
      marginLeft: 0,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const brandComponent = (
  <Typography variant="h6" noWrap>
    <Hidden smDown implementation="css">
      <Box aria-label="open drawer" component={RouterLink} to={"/"}>
        <img
          src={logo}
          alt={"Portnet Logo"}
          style={{ height: "auto", width: "20%" }}
        />
      </Box>
    </Hidden>
    <Hidden smUp implementation="css">
      <Box aria-label="open drawer" component={RouterLink} to={"/"}>
        <img
          src={logo}
          alt={"Portnet Logo"}
          style={{ height: "auto", width: "50%" }}
          component={RouterLink}
          to={"/"}
        />
      </Box>
    </Hidden>
  </Typography>
);
export default function Header(props) {
  const { auth } = props;

  const theme = useTheme();
  const [open, setOpen] = React.useState(auth);
  const [collapse, setCollapse] = React.useState(true);

  const classes = useStyles();
  const class1 = useStyles1(); 
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const handleClick = () => {
    setCollapse(!collapse);
  };

  // const hasRole = (role) => {
  //   return roles?.includes(role);
  // };

  const menuItems = [
    {
      title: "Accueil",
      icon: <Home />,
      description: "Menu",
      to: `/menu`,
      visible: true,
    },
    {
      title: "Liste des demandes",
      icon: <AssignmentIcon />,
      description: "Création d'une demande d'accès au port",
      to: `/liste-demande`,
      visible: hasRole(ENTITE),
    },
    {
      title: "Demandes validées",
      icon: <AssignmentIcon />,
      description: "Création d'une demande d'accès au port",
      to: `/liste-demande`,
      visible: hasRole(DGSN),
    },

    {
      title: "Interventions à bord",
      icon: <PendingActionsIcon />,
      description: "Interventions à bord",
      to: `/intervention`,
      visible: hasRole(OM),
    },
  ];
  const menuRef = [
    {
      title: "Type d'opération",
      icon: <Home />,
      description: "Operation",
      to: `/referentiel/operation`,
    },
    {
      title: "Type d'activité",
      icon: <AssignmentIcon />,
      description: "Activite",
      to: `/referentiel/activity`,
    },

    {
      title: "Secteur d'activité",
      icon: <PendingActionsIcon />,
      description: "Activite",
      to: `/referentiel/secteur`,
    },
    {
      title: "Port",
      icon: <ArchitectureIcon />,
      description: "Port",
      to: `/referentiel/port`,
    },
    {
      title: "Entité d'accueil",
      icon: <ArchitectureIcon />,
      description: "Entite Accueil",
      to: `/referentiel/entite`,
    },
    {
      title: "Zone",
      icon: <ArchitectureIcon />,
      description: "Zone",
      to: `/referentiel/zone`,
    },
    {
      title: "Navire",
      icon: <ArchitectureIcon />,
      description: "Navire",
      to: `/referentiel/navire`,
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ padding: 1 }}>
          {auth && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <div className={classes.flex}>{brandComponent}</div>
          {/* <Hidden  implementation="js">
            <Box>
              {!auth && (
                <Stack direction="row" spacing={1}>
                  <Stack justifyContent="center">
                    <Button
                      color="primary"
                      size="sm"
                      flag
                      outlined
                      sx={{ color: "#fff" }}
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </Button>
                  </Stack>
                </Stack>
              )}
            </Box>
          </Hidden> */}
          
        </Toolbar>
      </AppBar>
      {auth && (
        <Drawer
          variant="permanent"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Hidden smDown implementation="js">
            <DrawerHeader sx={{ marginTop: 1 }}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon sx={{ color: "white" }} />
                ) : (
                  <ChevronLeftIcon sx={{ color: "white" }} />
                )}
              </IconButton>
            </DrawerHeader>
          </Hidden>
          <Hidden mdUp implementation="js">
            <DrawerHeader
              sx={{ marginTop: "90px", justifyContent: "flex-end" }}
            >
              <Stack direction="row" spacing={2}>
                <FlagLinks />
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon sx={{ color: "white" }} />
                  ) : (
                    <ChevronLeftIcon sx={{ color: "white" }} />
                  )}
                </IconButton>
              </Stack>
            </DrawerHeader>
          </Hidden>

          <List
            sx={{
              width: "100%",
              maxWidth: drawerWidth,
              justifyContent: "center",
            }}
          >
            <ListItem
              sx={{ py: 0, minHeight: 80, color: "rgba(255,255,255,1)" }}
            >
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 18,
                  fontWeight: "bold",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <SmallAvatar alt="User" src={avatar} />
                </StyledBadge>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ py: 0, color: "rgba(255,255,255,1)" }}>
              {open && (
                <ListItemText
                  primary={localStorage.getItem("username")}
                  primaryTypographyProps={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "white",
                    textAlign: "center",
                  }}
                />
              )}
            </ListItem>
            <Divider
              variant="middle"
              component="li"
              style={{ background: "rgba(225, 225, 225, 0.4)" }}
            />

            {menuItems.map((item, index) => {
              if (item.visible)
                return (
                  <ListItemButton
                    key={item.title}
                    onClick={() => navigate(item.to)}
                    sx={{ py: 0, minHeight: 32, color: "white", bgcolor:"#232f66",":hover":{color:"#232f66",bgcolor:"white"} }}
                  >
                    <ListItemIcon sx={{ color: "inherit", marginLeft: "8px" }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.title}
                      primaryTypographyProps={{
                        fontSize: 16,
                        fontWeight: "medium",
                        //color: "white",
                      }}
                      className={class1.myClassName}
                    />
                  </ListItemButton>
                );
            })}

            {hasRole(ADMIN) && (
              <>
                <ListItemButton
                  sx={{ py: 0, minHeight: 32, color: "white", bgcolor:"#232f66",":hover":{color:"#232f66",bgcolor:"white"} }}
                  onClick={handleClick}
                >
                  <ListItemIcon sx={{ color: "inherit", marginLeft: "8px" }}>
                    <ArchitectureIcon />
                  </ListItemIcon>
                  <ListItemText
                  
                    primary="Referentiel"
                    
                    primaryTypographyProps={{
                      fontSize: 16,
                      fontWeight: "medium",

                      //  color: "white",
                    }}                    
                    //className={class1.myClassName}
                  />
                  {collapse ? (
                    <ExpandLess sx={{ color: "inherit", bgcolor:"inherit",":hover":{color:"inherit",bgcolor:"inherit"}  }} />
                  ) : (
                    <ExpandMore sx={{ color: "inherit", bgcolor:"inherit",":hover":{color:"inherit",bgcolor:"inherit"}  }} />
                  )}
                </ListItemButton  >
                {open && (
                  <Collapse 
                  in={collapse} 
                  timeout="auto" 
                  unmountOnExit>
                    {menuRef.map((ref, index) => (
                      <List component="div" disablePadding dense>
                        <ListItemButton
                          sx={{ pl: 4, alignItems: "center", color: "white", bgcolor:"#232f66",":hover":{color:"#232f66",bgcolor:"white"}  }}
                          onClick={() => navigate(ref.to)}
                        >
                          <ListItemText
                          
                            primary={ref.title}
                            primaryTypographyProps={{
                              fontSize: 13,
                              fontWeight: "normal",
                              //color: "rgba(255,255,255,1)",
                            }}
                            className={class1.myClassName}
                          />
                        </ListItemButton>
                      </List>
                    ))}
                  </Collapse>
                )}
              </>
            )}
          </List>
          <Hidden smDown implementation="js">
            <Box>
              <Divider
                style={{
                  background: "rgba(225, 225, 225, 0.4)",
                  margin: "6px",
                }}
              />

              <Stack direction="row" spacing={0} justifyContent="center">
                <UserLinks auth={auth} />
              </Stack>
            </Box>
          </Hidden>
          <Hidden mdUp implementation="js">
            <Box>
              <Divider
                style={{
                  background: "rgba(224, 224, 224, .3)",
                  margin: "6px",
                }}
              />
              {auth ? (
                <div>
                  <UserLinks auth={auth}  />
                </div>
              ) : (
                <Stack spacing={1}>
                  <Stack justifyContent="center">
                    <Button
                      color="primary"
                      size="sm"
                      flag
                      outlined
                      sx={{ color: "#fff" }}
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </Button>
                  </Stack>
                  <FlagLinks />
                </Stack>
              )}
            </Box>
          </Hidden>
        </Drawer>
      )}
    </Box>
  );
}
