export const bookingInitialValues = {
  numeroBooking: '',
  typeOperateur: '',
  numEscale: '',
  numVoyage: '',
  navire: '',
  transitaire: '',
  dateReservation: '',
  exportateur: '',
  portDechargement: "",
  portDestinataire: '',
  codeAbonnementBl: '',
  dateLimite: '',
  dateEtd: '',
  armateur: '',
  dateEta: '',
  cycleBooking: 'export',
  avisoLlegada: '',
  conteneurs: [],
};

export const conteneurDetailInitialValues = {
  id: null,
  withDetails: false,
  numConteneur: '',
  codeIso: '',
  etatConteneur: 'V',
  poidsBrut: '',
  poidsNet: '',
  classImo: '',
  numScelleArmateur: '',
  scelleChargeur: '',
  numPermisDouane: '',
  tagFrigo: 'non',
  temperatureConsigne: '',
  tagDangereux: 'non',
  tagHg: 'non',
  mesureDepassementHauteur: '',
  mesureDepassementLargeur: '',
  mesureDepassementLongueur: '',
  nombreConteneur: '',
  poidsTotal: '',
};
