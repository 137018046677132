export const ADMIN = "ROLE_ADMIN";
export const USER = "ROLE_USER";
export const AM = "ROLE_AM";
export const OM = "ROLE_OM";
export const DGSN = "ROLE_DGSN";
export const ENTITE = "ROLE_ENTITE";
export const REFERENTIEL = "ROLE_REFERENTIEL";
export const VIEW_ALL = "ROLE_VIEW_ALL";

export const hasRole = (role) => {
  let userRoles = localStorage.getItem("roles");
  return userRoles?.split(",").includes(role);
};
