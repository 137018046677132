export const DEFAULT_ERROR_MESSAGE =
  "Une erreur est survenue, veuillez reéssayer plus tard ou contacter le service client.";

export const FIELD_REQUIRED = "Ce champ est obligatoire.";
export const FIELD_NUMBER = "Ce champ doit être un nombre.";
export const FIELD_STRING = 'Ce champ doit être de type "String".';
export const EXPIRATION_DATE =
  "La date d'Expiration doit etre superieur a la date d'aujourd'hui";

export const KeyStyle = {
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "0.9rem",
  color: "rgb(80, 80, 80)",
  lineHeight: "1.4375em",
  letterSpacing: "0.00938em",
  fontWeight: "600",
};
export const ValueStyle = {
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "0.9rem",
  color: "rgb(80, 80, 80)",
  lineHeight: "1.4375em",
  letterSpacing: "0.00938em",
};

export const regimeOptions = [
  { id: 1, description: "Import" },
  { id: 2, description: "Export" },
  { id: 9, description: "Transbordement" },
  { id: 4, description: "Cabotage charge" },
  { id: 10, description: "Cabotage decharge" },
];
