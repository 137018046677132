import * as ActionsTypes from '../../../common/state/StateConstants';
import {GET_ARRIVAL_NOTICE, UPDATE_BOOKING} from "../../../common/state/StateConstants";

/*--------------------------------------------------POST----------------------------------------------------*/

/***********Creation Booking (imoClass)***************** */
export const getCodeIso = (payload) => {
  return {
    type: ActionsTypes.GET_CODEISO,
    payload,
  };
};

export const getCodeIsoSuccess = (response) => {
  return {
    type: ActionsTypes.GET_CODEISO_SUCCESS,
    response,
  };
};

export const getCodeIsoFailed = (payload) => {
  return {
    type: ActionsTypes.GET_CODEISO_FAIL,
    payload,
  };
};

/***********Creation Booking (numContainer)***************** */
export const getNumContainer = (payload) => {
  return {
    type: ActionsTypes.GET_NUMCONTAINER,
    payload,
  };
};

export const getNumContainerSuccess = (response) => {
  return {
    type: ActionsTypes.GET_NUMCONTAINER_SUCCESS,
    response,
  };
};

export const getNumContainerFailed = (payload) => {
  return {
    type: ActionsTypes.GET_NUMCONTAINER_FAIL,
    payload,
  };
};

/***********Creation Booking (Class IMO)***************** */
export const getClassImo = (payload) => {
  return {
    type: ActionsTypes.GET_CLASSIMO,
    payload,
  };
};

export const getClassImoSuccess = (response) => {
  return {
    type: ActionsTypes.GET_CLASSIMO_SUCCESS,
    response,
  };
};

export const getClassImoFailed = (payload) => {
  return {
    type: ActionsTypes.GET_CLASSIMO_FAIL,
    payload,
  };
};

/***********Creation Booking (Num Escale)***************** */
export const getNumEscale = (payload) => {
  return {
    type: ActionsTypes.GET_NUMESCALE,
    payload,
  };
};

export const getNumEscaleSuccess = (response) => {
  return {
    type: ActionsTypes.GET_NUMESCALE_SUCCESS,
    response: response,
  };
};

export const getNumEscaleFailed = (payload) => {
  return {
    type: ActionsTypes.GET_NUMESCALE_FAIL,
    payload,
  };
};

/***********Creation Booking (Operateur Name)***************** */
export const getOperteurName = (payload) => {
  return {
    type: ActionsTypes.GET_OPERATEURNAME,
    payload,
  };
};

export const getOperteurNameSuccess = (payload) => {
  return {
    type: ActionsTypes.GET_OPERATEURNAME_SUCCESS,
    payload,
  };
};

export const getOperteurNameFailed = (payload) => {
  return {
    type: ActionsTypes.GET_OPERATEURNAME_FAIL,
    payload,
  };
};

/***********Creation Booking (Armateur)***************** */
export const getArmateurSearch = (payload) => {
  return {
    type: ActionsTypes.GET_ARMATEUR,
    payload,
  };
};

export const getArmateurSearchSuccess = (payload) => {
  return {
    type: ActionsTypes.GET_ARMATEUR_SUCCESS,
    payload,
  };
};

export const getArmateurSearchFailed = (payload) => {
  return {
    type: ActionsTypes.GET_ARMATEUR_FAIL,
    payload,
  };
};
/***********Creation Booking (Creer Booking)***************** */
export const creerBooking = (request) => {
  return {
    type: ActionsTypes.GET_CREATIONBOOKING,
    request,
  };
};

export const creerBookingSuccess = (payload) => {
  return {
    type: ActionsTypes.GET_CREATIONBOOKING_SUCCESS,
    payload,
  };
};

export const creerBookingFailed = (payload) => {
  return {
    type: ActionsTypes.GET_CREATIONBOOKING_FAIL,
    payload,
  };
};

/*********** Update Booking **************** */
export const updateBooking = (request) => {
  return {
    type: ActionsTypes.UPDATE_BOOKING,
    request,
  };
};

export const updateBookingSuccess = (booking) => {
  return {
    type: ActionsTypes.UPDATE_BOOKING_SUCCESS,
    booking,
  };
};

export const updateBookingFail = (error) => {
  return {
    type: ActionsTypes.UPDATE_BOOKING_FAIL,
    error,
  };
};

/***********Creation And Send Booking (Creer And Send Booking)***************** */
export const creerAndSendBooking = (request) => {
  return {
    type: ActionsTypes.GET_CREATIONSENDBOOKING,
    request,
  };
};

export const creerAndSendBookingSuccess = (payload) => {
  return {
    type: ActionsTypes.GET_CREATIONSENDBOOKING_SUCCESS,
    payload,
  };
};

export const creerAndSendBookingFailed = (payload) => {
  return {
    type: ActionsTypes.GET_CREATIONSENDBOOKING_FAIL,
    payload,
  };
};

/*--------------------------------------------------RESET-creation-----------------------------------------------------*/
export const resetCreationFail = (error) => {
  return {
    type: ActionsTypes.RESET_CREATION_FAIL,
  };
};
export const resetCreationSuccess = () => {
  return {
    type: ActionsTypes.RESET_CREATION_SUCCESS,
  };
};

/***********Get Booking ***************** */
export const getBooking = (request) => {
  return {
    type: ActionsTypes.GET_BOOKING,
    request,
  };
};

export const getBookingSuccess = (booking) => {
  return {
    type: ActionsTypes.GET_BOOKING_SUCCESS,
    booking,
  };
};

export const getBookingFail = (error) => {
  return {
    type: ActionsTypes.GET_BOOKING_FAIL,
    error,
  };
};

/***********Get Arrival Notice  ***************** */
export const getArrivalNotice = (request) => {
  return {
    type: ActionsTypes.GET_ARRIVAL_NOTICE,
    request,
  };
};

export const getArrivalNoticeSuccess = (arrivalNotice) => {
  return {
    type: ActionsTypes.GET_ARRIVAL_NOTICE_SUCCESS,
    arrivalNotice,
  };
};

export const getArrivalNoticeFail = (error) => {
  return {
    type: ActionsTypes.GET_ARRIVAL_NOTICE_FAIL,
    error,
  };
};

/*********** Cancel Booking  ***************** */
export const cancelBooking = (request) => {
  return {
    type: ActionsTypes.CANCEL_BOOKING,
    request,
  };
};
/*********** Send Booking  ***************** */
export const sendBooking = (request) => {
  return {
    type: ActionsTypes.SEND_BOOKING,
    request,
  };
};


/*********** Update Booking **************** */
export const updateAndSendBooking = (request) => {
  return {
    type: ActionsTypes.UPDATE_AND_SEND_BOOKING,
    request,
  };
};

export const updateAndSendBookingSuccess = (booking) => {
  return {
    type: ActionsTypes.UPDATE_AND_SEND_BOOKING_SUCCESS,
    booking,
  };
};

export const updateAndSendBookingFail = (error) => {
  return {
    type: ActionsTypes.UPDATE_AND_SEND_BOOKING_FAIL,
    error,
  };
};

