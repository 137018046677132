import { put, call } from "redux-saga/effects";
import {getPortApi,getPortSearchApi} from "../../../common/services/api/getPortApi"
import {getOperateurApi} from "../../../common/services/api/getOperateurApi"
import {getBookingApi} from "../../../common/services/api/getBookingApi"
import {getPremierPortApi} from "../../../common/services/api/getPremierPortApi"
import {getNavireDefaultApi} from "../../../common/services/api/getNavireDefaultApi"
import {getNavireSearchApi} from "../../../common/services/api/getNavireSearchApi"
import {getTransitaireDefaultApi} from "../../../common/services/api/getTransitaireDefaultApi"
import {getTransitaireSearchApi} from "../../../common/services/api/getTransitaireSearchApi"
import * as actions from '../state/GestionBookingAction'
import { getClientSearchApi } from "../../../common/services/api/getClientSearchApi";
import { getBookingSearchApi } from "../../../common/services/api/getBookingSearchApi";
import { getHistoriqueSearchApi } from "../../../common/services/api/getHistoriqueSearchApi";




export function* getPortsaga(action) {
    try {
        const response = yield call(getPortApi, action.payload);
        yield put(actions.getPortSuccess(response.data));
    }catch (error){
        yield put(actions.getPortLFail(error));
    }
}

export function* getPortSearchsaga(action) {
    try {
        const response = yield call(getPortSearchApi, action.payload);
        yield put(actions.getPortSearchSuccess(response.data));
    }catch (error){
        yield put(actions.getPortSearchFail(error));
    }
}

export function* getOperateurSearchsaga(action) {
    try {
        const response = yield call(getOperateurApi, action.payload);
        yield put(actions.getOperateurSuccess(response.data));
    }catch (error){
        yield put(actions.getOperateurSuccess(error));
    }
}

export function* getBookingDefaultsaga(action) {
    try {
        const response = yield call(getBookingApi, action.payload);
        yield put(actions.getBookingSuccess(response.data));
    }catch (error){
        yield put(actions.getBookingFail(error));
    }
}

export function* getPremierPortsaga(action) {
    try {
        const response = yield call(getPremierPortApi, action.payload);
        yield put(actions.getPremierPortSuccess(response.data));
    }catch (error){
        yield put(actions.getPremierPortFail(error));
    }
}

export function* getNavireDefaultsaga(action) {
    try {
        const response = yield call(getNavireDefaultApi, action.payload);
        yield put(actions.getNavireDefaultSuccess(response.data));
    }catch (error){
        yield put(actions.getNavireDefaultFail(error));
    }
}

export function* getNavirSearchsaga(action) {
    try {
        const response = yield call(getNavireSearchApi, action.payload);
        yield put(actions.getNavireSearchSuccess(response.data));
    }catch (error){
        yield put(actions.getNavireSearchFail(error));
    }
}

export function* getTransitaireDefaultsaga(action) {
    try {
        const response = yield call(getTransitaireDefaultApi, action.payload);
        yield put(actions.getTransitaireDefaultSuccess(response.data));
    }catch (error){
        yield put(actions.getTransitaireDefaultFail(error));
    }
}

export function* getTransitaireSeachsaga(action) {
    
    try {
        const response = yield call(getTransitaireSearchApi, action.payload);
        yield put(actions.getTransitaireSearchSuccess(response.data));
    }catch (error){
        yield put(actions.getTransitaireSearchFail(error));
    }
}

export function* getClientSeachsaga(action) {
    
    try {
        const response = yield call(getClientSearchApi, action.payload);
        yield put(actions.getClientSearchSuccess(response.data));
    }catch (error){
        yield put(actions.getClientSearchFail(error));
    }
}

export function* getBookingSeachsaga(action) {
    
    try {
        const response = yield call(getBookingSearchApi, action.payload);
        yield put(actions.getBookingSearchSuccess(response.data));
    }catch (error){
        yield put(actions.getBookingSearchFail(error));
    }
}

export function* getHistoriqueSeachsaga(action) {
    
    try {
        const response = yield call(getHistoriqueSearchApi, action.payload);
        yield put(actions.getHistoriqueSearchSuccess(response.data));
    }catch (error){
        yield put(actions.getHistoriqueSearchFail(error));
    }
}




