import { fork } from 'redux-saga/effects';
import creationBookingWatcher from '../../modules/creationBooking/saga/creationBookingWatcher';
import detailBookingWatcher from '../../modules/detailBooking/saga/detailBookingWatcher';

import GestionBookingWatcher from '../../modules/gestionBooking/saga/GestionBookingWatcher';
import commonWatcher from './commonWatcher';

export default function* rootSaga() {
  yield fork(GestionBookingWatcher);
  yield fork(detailBookingWatcher);
  yield fork(creationBookingWatcher);
  yield fork(commonWatcher);
}
