import {
  defaultFont,
  primaryColor,
  successColor,
  warningColor,
  dangerColor,
} from "./material.js";

const typographyStyle = {
  defaultFontStyle: {
    ...defaultFont,
    fontSize: "14px",
  },
  defaultHeaderMargins: {
    marginTop: "20px",
    marginBottom: "10px",
  },
  quote: {
    padding: "5px 10px",
    margin: "0 0 20px",
    fontSize: "13.5px",
    borderLeft: "3px solid #eee",
  },
  quoteText: {
    margin: "0 0 10px",
    fontStyle: "italic",
  },
  quoteAuthor: {
    display: "block",
    fontSize: "80%",
    lineHeight: "1.42857143",
    color: "#777",
  },
  mutedText: {
    color: "#777",
  },
  primaryText: {
    color: primaryColor,
    // textAlign: "center",
    textTransform: "uppercase",
  },
  infoText: {
    color: primaryColor,
  },
  successText: {
    color: successColor,
  },
  warningText: {
    color: warningColor,
  },
  dangerText: {
    color: dangerColor,
    fontSize: "85%",
    marginTop: "5px",
  },
  smallText: {
    fontSize: "65%",
    fontWeight: "400",
    lineHeight: "1",
    color: "#777",
  },
};

export default typographyStyle;
