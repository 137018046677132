import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import DialogActions from '@mui/material/DialogActions';
import Button from '../../../common/components/CustomButtons/Button';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { getHistoriqueSearch } from '../../gestionBooking/state/GestionBookingAction';
import { PuiDialog, PuiTable } from '@portnet/ui';

const HistoriqueBooking = (props) => {
  const { open, id, fullWidth, handleClose } = props;
  const dispatch = useDispatch();
  const content =
    useSelector((state) => state?.gestionBooking?.historiqueSearchData?.content) ?? [];
  const listHistoriqueSarch = useSelector((state) => state.gestionBooking.historiqueSearchData);
  const isLoading = useSelector((state) => state?.gestionBooking?.isLoadingHistoriqueSearch);
  const totalElements = useSelector(
    (state) => state?.gestionBooking?.historiqueSearchData?.totalElements
  );
  const [pager, setPager] = useState({
    page: 0,
    size: 10,
  });

  const pageChangeHandler = (page) => {
    setPager({ ...pager, page: page });
  };
  const pageSizeChangeHandler = (size) => {
    setPager({ ...pager, size: size });
  };
  useEffect(() => {
    if (id) {
      const searchData = {
        id: id,
        page: pager.page,
        size: pager.size,
      };
      dispatch(getHistoriqueSearch(searchData));
    }
  }, [id]);
  useEffect(() => {
    const searchData = {
      id: id,
      page: pager.page,
      size: pager.size,
    };
    dispatch(getHistoriqueSearch(searchData));
  }, [pager]);

  const handleChangeHistoPage = (event, page) => {
    setPager({ ...pager, page: page });
  };

  const columns = [
    {
      field: 'exchangeNumber',
      headerName: "Nº d'échanges",
      sortable: false,
      minWidth: 130,
      valueGetter: ({ row }) => row?.exchangeNumber,
    },
    {
      field: 'functionLabel',
      headerName: 'Fonction de la demande',
      sortable: false,
      minWidth: 200,
      valueGetter: ({ row }) => row?.functionLabel,
    },
    {
      field: 'nameMessage',
      headerName: 'Nom Message',
      sortable: false,
      minWidth: 150,
      valueGetter: ({ row }) => row?.nameMessage,
    },
    {
      field: 'exchangeDate',
      headerName: 'Date',
      sortable: false,
      minWidth: 130,
      valueGetter: ({ row }) => row?.exchangeDate,
    },
    {
      field: 'userName',
      headerName: 'Nom utilisateur',
      sortable: false,
      minWidth: 130,
      valueGetter: ({ row }) => row?.userName,
    },
    {
      field: 'observation',
      headerName: 'Observations',
      sortable: false,
      minWidth: 160,
      valueGetter: ({ row }) => row?.observation,
    },
  ];
  return (
    <PuiDialog
      open={open}
      onClose={handleClose}
      title="Historique de booking"
      fullWidth
      maxWidth="md"
    >
      <PuiTable
        columns={columns}
        rows={content}
        onPageChange={pageChangeHandler}
        onPageSizeChange={pageSizeChangeHandler}
        loading={isLoading}
        rowCount={totalElements}
        pageSize={pager.size}
        page={pager.page}
        paginationMode="server"
      />
      <DialogActions>
        <Button onClick={handleClose} round color="primary" size="sm" autoFocus>
          Fermer
        </Button>
      </DialogActions>
    </PuiDialog>
  );
};
export default HistoriqueBooking;
