import  instance from '../../AxiosInterceptor';
import config from '../../config';

export const getBookingSearchApi = (data) => {
  return instance.get(
    config.url +
      '/ms-bookingApi/booking/getBooking?bookingDate=' +
      data?.bookingDate +
      '&bookingNum=' +
      data?.bookingNum +
      '&consigneeCode=' +
      data?.consigneeCode +
      '&containerNum=' +
      data?.containerNum +
      '&escalNum=' +
      data?.escalNum +
      '&exporter=' +
      data?.exporter +
      '&finalDestination=' +
      data?.finalDestination +
      '&freightForwarderCode=' +
      data?.freightForwarderCode +
      '&imoShip=' +
      data?.imoShip +
      '&loadingPort=' +
      data?.loadingPort +
      '&operatorId=' +
      data?.operatorId +
      '&etat=' +
      data?.etat +
      '&page=' +
      data?.page +
      '&size=10&travelNum=' +
      data?.travelNum
  );
  /* console.log(data);
    return axios
  .get(config.url+"/ms-bookingApi/booking/getBooking?bookingDate="+data?.bookingDate+"&bookingNum="+data?.bookingNum+"&consigneeCode="+""+"&containerNum="+data?.containerNum+"&escalNum="+data?.escalNum+"&exporter="+data?.exporter+"&finalDestination="+data?.finalDestination+"&freightForwarderCode="+data?.freightForwarderCode+"&imoShip="+data?.imoShip+"&loadingPort="+data?.loadingPort+"&operatorId="+data?.operatorId+"&page="+data?.page+"&size=10&travelNum="+data?.travelNum)
  .then((response) => {
        return response;
      })
      .then((err) => {
        return err;
      });*/
};
