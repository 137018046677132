import  instance from "../../AxiosInterceptor";
import config from "../../config";

export const getBookingApi = (data) => {
    return instance
      .get(config.url+"/ms-bookingApi/booking/getDefaultList")
      .then((response) => {
        return response;
      })
      .then((err) => {
        return err;
      });
  };
  