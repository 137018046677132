import * as ActionsTypes from  "../StatesConstants";
import roles from "../../../common/constants/roles";
import {updateState} from "../../../common/helpers";

const authoritiesInitialState = {
  content: {},
  user: null,
};

const initialState = {
  token: null,
  authorities: { ...authoritiesInitialState },
};


/*--------------------------------------------------TOKEN----------------------------------------------------*/
const setToken = (state, payload) => {
  return { ...state, token: payload };
};

/*--------------------------------------------------AUTHORITIES----------------------------------------------------*/

const setAuthorities = (state, payload) => {
  const content = {};

  for (const key in roles) {
    for (let i = 0, dataLength = payload.length; i < dataLength; i++) {
      content[key] = roles[key] === payload[i];
      if (content[key]) break;
    }
  }

  return updateState("authorities", state, {
    user: payload[0].user,
    content,
  });
};

const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    /*--------------------------------------------------TOKEN----------------------------------------------------*/
    case ActionsTypes.GENERAL_SET_TOKEN:
      return setToken(state, action.payload);
    /*--------------------------------------------------AUTHORITIES----------------------------------------------------*/
    case ActionsTypes.GENERAL_SET_AUTHORITIES:
      return setAuthorities(state, action.payload);
    default:
      return state;
  }
};

export default generalReducer;
