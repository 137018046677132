import { combineReducers } from 'redux';
import detailBookingReducer from '../../modules/detailBooking/state/detailBookingReducer';
import GestionBookingReducer from '../../modules/gestionBooking/state/GestionBookingReducer';
import creationBookingReducer from '../../modules/creationBooking/state/creationBookingReducer';
import commonReducer from './commonReducer';
import generalStateReducer from "../../modules/general/generalState/GeneralStateReducer";

const rootReducer = combineReducers({
  gestionBooking: GestionBookingReducer,
  detailBooking: detailBookingReducer,
  creationBooking: creationBookingReducer,
  commonBooking: commonReducer,
  general: generalStateReducer,
});

export default rootReducer;
