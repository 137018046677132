import React from 'react';
import * as ActionsTypes from '../../../common/state/StateConstants';

const initialState = {
  navire: {
    isLoading: false,
    totalElements: 0,
    content: [],
    error: {},
  },

  operateur: {
    isLoading: false,
    totalElements: 0,
    content: [],
    error: {},
  },
};

const GestionBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    /******************************************************************* */

    /******************* Operateur Search**************************** */

    case ActionsTypes.GET_OPERATEURSEARCH:
      return {
        ...state,
        operateur: { ...state.operateur, isLoading: true },
      };

    case ActionsTypes.GET_OPERATEURSEARCH_SUCCESS:
      return {
        ...state,
        operateur: {
          ...state.operateur,
          isLoading: false,
          content: action.response.content,
          totalElements: action.response.totalElements,
        },
      };

    case ActionsTypes.GET_OPERATEURSEARCH_FAIL:
      return {
        ...state,
        operateur: { ...state.operateur, isLoading: false },
      };

    /******************* Operateur Search**************************** */

    case ActionsTypes.GET_BOOKINGDEFAULT:
      return {
        ...state,
        isLoadingBookingDefault: true,
      };

    case ActionsTypes.GET_BOOKINGDEFAULT_SUCCESS:
      return {
        ...state,
        isLoadingBookingDefault: false,
        bookingDefaultData: action.payload,
        error: false,
      };

    case ActionsTypes.GET_BOOKINGDEFAULT_FAIL:
      return {
        ...state,
        isLoadingBookingDefault: false,
        bookingDefaultData: action.payload,
        error: true,
      };

    /********************Case : popUp Booking(Navire)************** */
    /********************Navire****************** */

    case ActionsTypes.GET_NAVIRESEARCH:
      return {
        ...state,
        navire: { ...state.navire, isLoading: true },
      };

    case ActionsTypes.GET_NAVIRESEARCH_SUCCESS:
      //   debugger;
      return {
        ...state,
        navire: {
          ...state.navire,
          isLoading: false,
          content: action.response.content,
          totalElements: action.response.totalElements,
        },
      };

    case ActionsTypes.GET_NAVIRESEARCH_FAIL:
      return {
        ...state,
        navire: { ...state.navire, isLoading: false },
      };

    /******************* Booking  Search**************************** */

    case ActionsTypes.GET_BOOKINGSEARCH:
      return {
        ...state,
        isLoadingBookingSearch: true,
      };

    case ActionsTypes.GET_BOOKINGSEARCH_SUCCESS:
      return {
        ...state,
        isLoadingBookingSearch: false,
        bookingSearchData: action.payload,
        error: false,
      };

    case ActionsTypes.GET_BOOKINGSEARCH_FAIL:
      return {
        ...state,
        isLoadingBookingSearch: false,
        bookingSearchData: action.payload,
        error: true,
      };

    /******************* Booking  Search**************************** */

    case ActionsTypes.GET_HISTORIQUESEARCH:
      return {
        ...state,
        isLoadingHistoriqueSearch: true,
      };

    case ActionsTypes.GET_HISTORIQUESEARCH_SUCCESS:
      return {
        ...state,
        isLoadingHistoriqueSearch: false,
        historiqueSearchData: action.payload,
        error: false,
      };

    case ActionsTypes.GET_HISTORIQUESEARCH_FAIL:
      return {
        ...state,
        isLoadingHistoriqueSearch: false,
        historiqueSearchData: action.payload,
        error: true,
      };

    default:
      return state;
  }
};

export default GestionBookingReducer;
