import React, {useEffect, useState} from 'react';
import {PuiAlertProvider, PuiLocalizationProvider, PuiReferentielProvider,} from "@portnet/ui";
import appearance from './common/utils/appearance';
import {RouterProvider} from "react-router-dom";
import config from "./common/config";
import router from "./common/components/Router/router";
import {useDispatch} from "react-redux";
import useQuery from "./common/hooks/useQuery";
import {isTokenExpired} from "./common/AxiosInterceptor";
import jwtDecode from "jwt-decode";
import {setAuthorities, setToken} from "./modules/general/generalState/GeneralStateActions";
import {NETWORK_ERROR_PAGE_ROUTE} from "./common/constants/routerConstants";


const App = () => {
  const { token } = useQuery();
  const dispatch = useDispatch();

  const [isTokenDecoded, setIsTokenDecoded] = useState(false);

  const redirectToPortnet = () => {
    window.location.href = `${config.portnetBaseUrl}/app/seguridad/user/login.jsp`;
  };
  const redirectToErrorPage = () => {
    window.location.href = NETWORK_ERROR_PAGE_ROUTE;
  };

  useEffect(() => {
    if (!token) {
      redirectToPortnet();
    }
    try {
      const decodedToken = jwtDecode(token);
      if (isTokenExpired(decodedToken)) {
        redirectToPortnet();
      } else {
        dispatch(setAuthorities(decodedToken.roles));
        dispatch(setToken(token));
        setIsTokenDecoded(true);
      }
    } catch (error) {
      redirectToPortnet();
     }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [theme] = useState(appearance.defaultTheme);
  return (
      <PuiAlertProvider>
        <PuiReferentielProvider
            token={`Bearer ${token}`}// Use the test token if in test environment
            secret
            baseURL={config.referentielBaseURL}
        >
          <PuiLocalizationProvider>
            <RouterProvider router={router} />
          </PuiLocalizationProvider>
        </PuiReferentielProvider>
      </PuiAlertProvider>
  );
};
export default App;

