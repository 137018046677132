import  instance from "../../AxiosInterceptor";
import config from "../../config";

export const getHistoriqueSearchApi = (data) => {
    return instance
  .get(config.url+"/ms-bookingApi/booking/getBookingHist?id="+data?.id+"&page="+data?.page+"&size=10")
  .then((response) => {
        return response;
      })
      .then((err) => {
        return err;
      });
  };
