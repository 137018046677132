import * as ActionsTypes from "../state/StateConstants";

/*--------------------------------------------------POST----------------------------------------------------*/

/***********Common Booking (Transitaire)***************** */
export const getTransitaire = (payload) => {
  return {
    type: ActionsTypes.GET_TRANSITAIRE,
    payload,
  };
};

export const getTransitaireSuccess = (response) => {
  return {
    type: ActionsTypes.GET_TRANSITAIRE_SUCCESS,
    response: response,
  };
};

export const getTransitaireFail = (payload) => {
  return {
    type: ActionsTypes.GET_TRANSITAIRE_FAIL,
    payload,
  };
};

/***********Common Booking (Client)***************** */
export const getClientSearch = (payload) => {
  return {
    type: ActionsTypes.GET_CLIENTESEARCH,
    payload,
  };
};

export const getClientSearchSuccess = (response) => {
  return {
    type: ActionsTypes.GET_CLIENTESEARCH_SUCCESS,
    response: response,
  };
};

export const getClientSearchFail = (payload) => {
  return {
    type: ActionsTypes.GET_CLIENTESEARCH_FAIL,
    payload,
  };
};

/***********Common Booking (Premier Port)***************** */
export const getPremierPort = (payload) => {
  return {
    type: ActionsTypes.GET_PREMIERPORT,
    payload,
  };
};

export const getPremierPortSuccess = (response) => {
  return {
    type: ActionsTypes.GET_PREMIERPORT_SUCCESS,
    response: response,
  };
};

export const getPremierPortFail = (payload) => {
  return {
    type: ActionsTypes.GET_PREMIERPORT_FAIL,
    payload,
  };
};

/*--------------------------------------------------RESET-creation-----------------------------------------------------*/
export const resetCreationFail = (error) => {
  return {
    type: ActionsTypes.RESET_CREATION_FAIL,
  };
};
export const resetCreationSuccess = () => {
  return {
    type: ActionsTypes.RESET_CREATION_SUCCESS,
  };
};

export const searchListeEscale = (payload) => {
  return {
    type: ActionsTypes.GET_NUMESCALE,
    payload,
  };
};

export const searchListeEscaleSuccess = (payload) => {
  return {
    type: ActionsTypes.GET_NUMESCALE_SUCCESS,
    payload,
  };
};

export const searchListeEscaleFail = (payload) => {
  return {
    type: ActionsTypes.GET_NUMESCALE_FAIL,
    payload,
  };
};
