const useQuery = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const queryObject = {};
  queryParams.forEach((paramValue, paramKey) => {
    queryObject[paramKey] = paramValue;
  });
  return queryObject;
};

export default useQuery;
