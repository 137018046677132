import React from "react";
//import { makeStyles } from "@mui/styles";
import LanguageIcon from "@mui/icons-material/Language";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "../CustomButtons/Button.js";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
//import styles from "../../../assets/jss/headerLinksStyle.js";

//const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  //const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const flagsItems = [
    {
      title: "English",
      value: "en",
    },
    {
      title: "French",
      value: "fr",
    },
  ];

  return (
    <div>
      <Button color="primary" size="sm" flagIcon>
        <LanguageIcon />
      </Button>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color="primary"
        size="sm"
        flag
      >
        EN&nbsp;
        <ArrowDropDownIcon />
      </Button>
      <Paper style={{ borderRadius: "0px!important" }}>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuList
            dense
            sx={{
              width: 94,
              maxWidth: "100%",
            }}
            role="menu"
          >
            {flagsItems.map((prop, key) => {
              return (
                <>
                  <MenuItem onClick={handleClose}>
                    <ListItemText>{prop.title}</ListItemText>
                  </MenuItem>
                  <Divider />
                </>
              );
            })}
          </MenuList>
        </Menu>
      </Paper>
    </div>
  );
}
