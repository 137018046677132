import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { FORBIDDEN_ERROR_PAGE_ROUTE} from "../constants/routerConstants";

export const useCheckAuthority = (requiredRoles = []) => {
  const { content: authorities } = useSelector(
    (state) => state.general.authorities
  );
  const navigate = useNavigate();

  const checkAuthority = () => {
    const notAllowedRoles = [
      "SOCIETE_DESTRUCTION",
      "DISTRICT_POLICE",
      "GENDARMERIE_ROYALE",
      "PROTECTION_CIVILE",
      "DISTRICT",
      "OPERATEUR",
      "MEAD",
      "DEMANDEUR_AGREMENT",
      "AGENT_DOUANIER",
      "SUPER_INSPECTEUR_DOUANIER",
      "SUPER_INSPECTEUR_DOUANIER_TC",
      "ANRT",
      "ONCF",
      "PRENEUR",
    ];

    // Check if user has any role that is not allowed
    const isNotAllowed = notAllowedRoles.some((role) => authorities[role]);
    if (isNotAllowed) {
      return <Navigate to={FORBIDDEN_ERROR_PAGE_ROUTE} replace />;
    }

    let hasAccess = false;
    let isForbidden = false;
    let notInversedCount = 0;
    requiredRoles.forEach((role) => {
      if (role.isInversed) {
        isForbidden = isForbidden || authorities[role.key];
      } else {
        hasAccess = hasAccess || authorities[role.key];
        notInversedCount++;
      }
    });

    if ((!hasAccess && Boolean(notInversedCount)) || isForbidden) {
     //return <Navigate to={FORBIDDEN_ERROR_PAGE_ROUTE} replace />;
      return false;
    }

    return true;
  };

  return { checkAuthority };
};
