export const objectToFormDataMapper = (objectData) => {
  let data = new FormData();
  Object.keys(objectData).forEach((key) => data.append(key, objectData[key]));
  return data;
};

export const updateState = (referentiel, oldState, newState) => {
  return {
    ...oldState,
    [referentiel]: {
      ...oldState[referentiel],
      ...newState,
    },
  };
};

