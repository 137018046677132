import  instance from "../../AxiosInterceptor";
import config from "../../config";

export const getPortApi = () => {
  return instance
    .get(config.url+"/ms-bookingApi/port/searchByIdNot?id=-1")
    .then((response) => {
      return response;
    })
    .then((err) => {
      return err;
    });
};

export const getPortSearchApi = (data) => {
  return instance
    .get(config.url+"/ms-bookingApi/port/search?page="+data?.page+"&size="+data?.size+"&code="+data?.codePort+"&name="+data?.nomPort)
    .then((response) => {
      return response;
    })
    .then((err) => {
      return err;
    });
};